import React from 'react';
import {ReactComponent as IconExpand} from '../../assets/icons/Icon_Expand.svg';

const PreviewIcon = () => {
    return (
        <div className='flex items-center gap-1 text-gray-600'>
            <IconExpand className='w-4' />
            <span className='text-sm max-lg:hidden'>view</span>
        </div>
    )
}
 
export default PreviewIcon;