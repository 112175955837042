import { useState } from "react";
import {useNavigate} from "react-router";
import {createSearchParams} from "react-router-dom";

const useProductSearch = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useState({search: ''});
    function handleInput(searchTerm) {
        setSearchParams(Object.assign({...searchParams}, {search: searchTerm}));
    }
    function search() {
        navigate({pathname: "/search", search: `?${createSearchParams(Object.assign({...searchParams}, {search: searchParams.search.trim()}))}`});
    }

    return {
        searchTerm: searchParams.search,
        handleInput,
        handleSubmit: search,
    }
}
export default useProductSearch;