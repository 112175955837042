import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@headlessui/react';
import useHideChatbot from '../../hooks/useHideChatbot';

const Modal = ({isShowing, close, children}) => {

    useHideChatbot(isShowing)

    return (     
        <Dialog
            onClose={close}
            open={isShowing}
        >
            {/* The backdrop, rendered as a fixed sibling to the panel container */}
            <div className="fixed inset-0 z-[100] bg-gray-400 bg-opacity-50" aria-hidden="true" />

            {/* Full-screen scrollable container */}
            <div className="fixed inset-0 overflow-y-auto z-[110] bg-opacity-100">
                {/* Container to center the panel */}
                <div className="flex justify-center min-h-full">
                    <Dialog.Panel className="z-[120] h-fit">
                        {children}
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    )
}

Modal.propTypes = {
    isShowing: PropTypes.bool.isRequired,
    close: PropTypes.func,
}

export default Modal;