import React, { useEffect, useState } from "react";
import MarketingList from "../../components/marketing/MarketingList";
import { api } from "../../api/api";
import LoadMore from "../../components/elements/LoadMore";
import HeaderExtension from "../../components/elements/HeaderExtension";
import BackButton from "../../components/elements/BackButton"
import FilterContainer from "../../components/elements/FilterContainer";
import useSortingDirection from "../../hooks/useSortingDirection";

const MarketingPage = () => {
    const [marketing, setMarketing] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [markets, setMarkets] = useState([]);
    const [selectedMarkets, setSelectedMarkets] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const [orderDirection, ,toggleSortingDirection] = useSortingDirection()

    const getMarketingMarkets = async () => {
        try {
            const response = await api.getMarketingMarkets();
            setMarkets(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const getMarketing = async (selected_page) => {
        setLoading(true);

        console.log(selected_page)

        let params = {
            page: selected_page,
            order: orderDirection
        };

        if (selectedMarkets.length > 0) {
            params.markets = selectedMarkets.map((el) => encodeURIComponent(el.value) ).join(",");
        }

        try {

            const response = await api.getMarketingList(params);
            if (selected_page) {
                setMarketing([...marketing, ...response.data.results]);
                setPage(selected_page);
            } else {
                setMarketing(response.data.results);
                setPage(1); 
            }
            setTotalPages(response.data.total_pages);
            setTotalCount(response.data.count)
        } catch (error) {
            console.log(error);
        }

        setLoading(false);
    };

    function toggleMarketsSelection(material) {
        const index = selectedMarkets.findIndex(market => market.value === material.value)
        if(index < 0) {
            setSelectedMarkets([...selectedMarkets, material])
        }
        else {
            const copy = [...selectedMarkets]
            copy.splice(index, 1)
            setSelectedMarkets(copy)
        }
    }

    useEffect(() => {
        getMarketing();
    }, [orderDirection, selectedMarkets]);


    useEffect(() => {
        getMarketingMarkets();
    }, []);

    return (
        <div>
            <HeaderExtension>
                <BackButton />
            </HeaderExtension>
            <div className="bg-white">
                <div className="container py-6">
                    <h1 className="text-4xl font-bold">
                        Marketing Material
                    </h1>
                </div>
            </div>
            <div className="marketingContainer bg-gray-50 py-10 min-h-[55vh]">
                <div className="container flex flex-col gap-6">
                    <FilterContainer
                        filterConfig={{
                            title: 'Markets',
                            options: markets,
                            selectedOptions: selectedMarkets,
                            handleSelectionToggle: toggleMarketsSelection,
                            handleSelectionClear: () => setSelectedMarkets([]),
                        }}
                    />
                    <div className="flex gap-1">
                        <p className="font-semibold text-blue">Results:</p>
                        <p className="text-gray">{totalCount} results found</p>
                    </div>
                    <MarketingList data={marketing} toggleSortingDirection={toggleSortingDirection} />
                    {page < totalPages && (
                        <LoadMore
                            onClick={() => {
                                getMarketing(page + 1);
                            }}
                            loading={loading}
                        />
                    )}
                </div>
            </div>
        </div>     
    );
};
export default MarketingPage;
