import React, { useState } from 'react';
import { api } from '../../../../api/api';
import PrimaryButton from '../../../../components/elements/PrimaryButton';
import SecondaryButton from '../../../../components/elements/SecondaryButton';

const SubmitSafetyMessage = (props) => {

    const [requestPending, setRequestPending] = useState(false);

    function sendForm() {
        setRequestPending(true);
        const message = props.steps[props.messageStep].value
        const user = props.steps[props.userStep].value
        const product = props.steps[props.productStep].value
        const material = props.steps[props.materialStep].value
        const category = props.steps[props.categoryStep].value
        const materialString = material ? `, material ${material}`: ''

        const data = {
            recipient: props.steps[props.repStep].value,
            message: message,
            ...user,
            safety: true,
            subject: `${category} - Question on product ${product}${materialString}`,
        };
        api.sendContactForm(data)
            .then(response => {
                setRequestPending(false);
                props.triggerNextStep({trigger: props.triggerOnSuccess})
            })
            .catch(error => {
                setRequestPending(false);
                console.log(error);
                props.triggerNextStep({trigger: props.triggerOnError})
            })
    }
    function cancel() {
        props.triggerNextStep({trigger: props.triggerOnCancel})
    }
    return (
        <div className='text-blue'>
            {requestPending ?
                <span>Sending</span>
            :
                <div className='flex gap-2'>
                    <SecondaryButton onClick={cancel}>Cancel</SecondaryButton>
                    <PrimaryButton title='Send' onClick={sendForm}/>
                </div>
            }
        </div>
    )
}

export default SubmitSafetyMessage;