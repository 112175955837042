import React, { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import FAQQuestion from './FAQQuestion';

const FAQCategoryTab = ({category, data}) => {

    const [urlSearchParams] = useSearchParams()
    const focusedQuestionRef = useRef(null)

    const focusedQuestionString = urlSearchParams.get('question')

    useEffect(() => {
        if(urlSearchParams.get('question') && focusedQuestionRef.current) {
            console.log('scroll', focusedQuestionRef)
            const y = focusedQuestionRef.current?.getBoundingClientRect().top + window.pageYOffset - (window.innerHeight/2);
            window.scrollTo({top: y, behavior: 'smooth'});
        }
    }, [data]);


    return (  
        <div> 
            <div className='py-2 border-b border-b-gray-400'>
                <h2 className='text-gray'>{category.toUpperCase()}</h2>
            </div>
            {data.map(faq => {
                const isFocusedQuestion = focusedQuestionString === faq.question
                return (
                    <div key={faq.question} ref={isFocusedQuestion ? focusedQuestionRef : null}>
                        <FAQQuestion question={faq.question} answer={faq.answer} defaultOpen={isFocusedQuestion} />
                    </div>
                )
            })}
        </div>
    );
}
 
export default FAQCategoryTab;