import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const ContactDataOverview = (props) => {
    const user = useSelector((state) => state.auth.user);
    const {steps} = props;
    const userFormData = {
        customer_phone: steps[props.phoneInputStep]?.value ?? user?.telephone_number,
        customer_organization: steps[props.organizationInputStep]?.value ?? user?.organization,
        customer_job_title: steps[props.jobInputStep]?.value ?? user?.job_title,
    }
    const [userData] = useState(userFormData);
    useEffect(() => {
      setTimeout(props.triggerNextStep({value: userData, trigger: props.trigger}), 600)
    }, [])
    
    return(
        <div>
            <div className='mb-2'>
                <p>Your contact data is:</p>
                <div>
                    <p className='font-semibold'>Telephone:</p>
                    <p>{userData.customer_phone}</p>
                </div>
                <div>
                    <p className='font-semibold'>Organization:</p>
                    <p>{userData.customer_organization}</p>
                </div>
                <div>
                    <p className='font-semibold'>Job title:</p>
                    <p>{userData.customer_job_title}</p>
                </div>
            </div>
        </div>
    )
}

export default ContactDataOverview;