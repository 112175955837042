import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
const ExploreCard = ({ title, description, link, icon }) => {
  return (
    <Link
      to={link}
      className="flex items-center gap-4 p-6 bg-white shadow-card"
    >
      <div className="text-cyan">
          {icon}
      </div>
      <h5 className="text-lg font-semibold text-blue">
        {title}
      </h5>
       
    </Link>
  );
};

ExploreCard.propTypes = {
  /**
   * Exploration card in the catalog page
   */
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
};

export default ExploreCard;
