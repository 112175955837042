import { useMemo } from "react"
import { useSelector } from "react-redux"
import { basketSelectors } from "../store/slices/basket/basket"

const useTotalBasketCount = () => {
    const pdsCount = useSelector(state => basketSelectors.pds.selectTotal(state))
    const sdsCount = useSelector(state => basketSelectors.sds.selectTotal(state))
    const risCount = useSelector(state => basketSelectors.ris.selectTotal(state))
    const eriCount = useSelector(state => basketSelectors.eri.selectTotal(state))
    const marketingCount = useSelector(state => basketSelectors.marketing.selectTotal(state))
    const certificatesCount = useSelector(state => basketSelectors.certificates.selectTotal(state))

    const total = useMemo(() => (pdsCount + sdsCount + risCount + marketingCount + certificatesCount + eriCount), [certificatesCount, eriCount, marketingCount, pdsCount, risCount, sdsCount])

    return total
}

export default useTotalBasketCount