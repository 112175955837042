import axios from "axios";
import { API_HOST, LOGIN_TYPES } from "../store/types";
import store from "../store/store";
import { AuthError, PublicClientApplication } from "@azure/msal-browser";
import {
    loginADRequest,
    loginRequest as loginB2CRequest,
    msalADConfig,
    msalConfig as msalB2CConfig
} from "../authConfig";
import { set_auth_error } from "../store/actions/auth";

const instance = axios.create({
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
    }
});

const pcaB2C = new PublicClientApplication(msalB2CConfig);
const pcaAd = new PublicClientApplication(msalADConfig);

instance.interceptors.request.use(async config => {
    if (config.headers.Authorization) {
        return config;
    } else {
        const login_type = store.getState().auth.login_type;
        const pca = login_type === LOGIN_TYPES.B2C ? pcaB2C : pcaAd;
        const loginRequest =
            login_type === LOGIN_TYPES.B2C ? loginB2CRequest : loginADRequest;
        const account = pca.getAllAccounts()[0];
        try {
            const resp = await pca.acquireTokenSilent({
                ...loginRequest,
                account
            });
            config.headers.Authorization = `Bearer ${resp.accessToken}`;
        } catch (error) {
            if (error instanceof AuthError) {
                store.dispatch(set_auth_error(error.message));
            }
        }
        return config;
    }
});

export const api = {
    getUser: async function() {
        return instance.get(API_HOST() + "/users/current/");
    },

    setTermAgreement: async function(agreed) {
        const body = { accepted_terms: agreed };
        return instance.patch(API_HOST() + "/users/current/", body);
    },
    setTourViewed: async function(viewed) {
        const body = { viewed_tour: viewed };
        return instance.patch(API_HOST() + "/users/current/", body);
    },

    logoutUser: async function() {
        return instance.get(API_HOST() + "/users/logout/");
    },

    getProductList: async function(params) {
        const url = API_HOST() + "/products/";
        return instance.get(url, { params: params });
    },

    getCertificateTypes: async function(params) {
        const url = API_HOST() + "/certificates/types";
        return instance.get(url, { params: params });
    },

    getCertificateList: async function(params) {
        const url = API_HOST() + "/certificates/";
        return instance.get(url, { params: params });
    },
    getMarketingList: async function(params) {
        const url = API_HOST() + "/marketing/";
        return instance.get(url, { params: params });
    },

    getMarketingMarkets: async function(params) {
        const url = API_HOST() + "/marketing/markets/";
        return instance.get(url, { params: params });
    },

    getSuggestion: async function(searchString) {
        const params = { search: searchString };
        return instance.get(API_HOST() + "/products/suggestions/", {
            params: params
        });
    },

    getFrequentlyViewedProducts: async function() {
        return instance.get(API_HOST() + "/products/frequently-viewed/");
    },

    getProductDetails: async function(slug) {
        const url = API_HOST() + `/products/${slug}/`;
        return instance.get(url);
    },

    getDatasheets: async function(slug, docType, params = {}) {
        const url = API_HOST() + `/products/${slug}/${docType}/`;
        return instance.get(url, { params: params });
    },

    getDocuments(pds, sds, ris, eri, marketing, certificates) {
        const body = {
            pds: pds ?? [],
            sds: sds ?? [],
            ris: ris ?? [],
            eri: eri ?? [],
            marketing: marketing ?? [],
            certificates: certificates ?? []
        };
        const url = API_HOST() + `/products/download/`;
        return instance.post(url, body, { responseType: "arraybuffer" });
    },

    getDocument(url) {
        return instance.get(url, { responseType: "arraybuffer" });
    },

    shareDocuments(
        pds,
        sds,
        ris,
        eri,
        marketing,
        certificates,
        emails,
        text,
        subject,
        sendingCC,
    ) {
        const body = {
            pds: pds ?? [],
            sds: sds ?? [],
            ris: ris ?? [],
            eri: eri ?? [],
            marketing: marketing ?? [],
            certificates: certificates ?? [],
            cc: sendingCC,
        };
        body.emails = emails;
        body.text = text;
        if (subject) {
            body.subject = subject;
        }
        const url = API_HOST() + `/products/share/`;
        return instance.post(url, body);
    },

    sendContactForm(formData) {
        const url = API_HOST() + `/contact/`;
        return instance.post(url, formData);
    },

    updateUserData(user) {
        const url = API_HOST() + "/users/current/";
        return instance.patch(url, user);
    },

    deleteUser() {
        const url = API_HOST() + "/users/current/";
        return instance.delete(url);
    },

    switchCustomerView(viewEnabled, viewValue) {
        const url = API_HOST() + "/users/current/";
        const config = {preferred_view: 0}
        if(viewEnabled) {
            config.preferred_view = viewValue
        }
        return instance.patch(url, config);
    },
    getCustomerProductsData(customerId) {
        const url = API_HOST() + `/customers/download/`;
        const data = { 
            consolidated_customer_id: customerId,
            responseType: "arraybuffer",
            params: {
                customer_id: customerId
            }
        }
        return instance.get(url, data)
    },
    getProductMarketing: async function(slug) {
        const url = API_HOST() + `/products/${slug}/marketing/`;
        return instance.get(url);
    },
    getPDSLanguageOptions() {
        const url = API_HOST() + "/pds/languages/";
        return instance.get(url);
    },
    getSDSCountryOptions(languageFilter) {
        const params = languageFilter
            ? { params: { language: languageFilter } }
            : null;
        const url = API_HOST() + "/sds/countries/";
        return instance.get(url, params);
    },
    getSDSLanguageOptions(countryFilter) {
        const params = countryFilter
            ? { params: { country: countryFilter } }
            : null;
        const url = API_HOST() + "/sds/languages/";
        return instance.get(url, params);
    },
    getFAQData(searchInput, isInternal) {
        const url = API_HOST() + "/help/faqs/";
        const params = { params: { search: searchInput } };
        if (!isInternal) {
            params.params.visibility = "External";
        }
        return instance.get(url, params);
    },
    getNews(page, pageSize, type, markAsRead, searchTerm, language, country) {
        const url = API_HOST() + "/users/notifications/"
        const config = {params: {
            marked_as_read: markAsRead,
            type: type,
            page: page,
            page_size: pageSize,
            search: searchTerm && searchTerm.length > 0 ? searchTerm : null,
            language: language,
            country: country,
        }}
        return instance.get(url, config)
    }
};
