function thinContent(text) {
    return (<span className="lg:w-[25vw] max-lg:container inline-block p-2">{text}</span>)
}

//When changing the order, adding or removing steps, adjust the useEffect-hook in MobileHeader component
export const defaultSteps = [
    {
        selector: 'invalidSelector',
        content: <p className="lg:w-[50vw]">'Welcome to the Sasol product database, a self-service platform that enables you, as one of our customers, to access standard information i.e. Product Data Sheets and Safety Data Sheets for the products that you are currently buying from us. The platform is work-in-progress and some of the information that you expect to find may be missing. In order to improve, we would appreciate your feedback regarding missing information and other ideas for enhancements. Ideas for enhancements will be reviewed and, if feasible and capacity allows, these will be implemented and scheduled into a release plan. If you have any questions on this workflow, please reach out to your Sasol sales representative. '</p>,
        position: 'center',
        padding: -100,
    },
    {
        selector: '[data-tour="step-header"]',
        content: thinContent('This is the header where you can find \nhelpful links to navigate this tool.')
    },
    {
        selector: '[data-tour="step-userButton"]',
        content: 'Change your profile information, change settings for the quick links on the product page or log out here'
    },
    {
        selector: '[data-tour="step-contact"]',
        content: 'You can use the contact form ...',
        mutationObservables: ['.mobileHeaderModal'],
        highlightedSelectors: ['[data-tour="step-contact"]'],
        bypassElem: true,
    },
    {
        selector: '.rsc-float-button',
        content: '... or use our chat to get in contact with us.'
    },
    {
        selector: '[data-tour="step-faq"]',
        mutationObservables: ['.mobileHeaderModal'],
        highlightedSelectors: ['[data-tour="step-faq"]'],
        content: thinContent('Here you will be able to find answers to the frequently asked questions. \nPlease let us know if you think the answer to a relevant question is missing.')
    },
    {
        selector: '[data-tour="step-feedback"]',
        mutationObservables: ['.mobileHeaderModal'],
        highlightedSelectors: ['[data-tour="step-feedback"]'],
        content: thinContent('As this is still work in progress we would highly appreciate \nif you take a few minutes to fill out our feedback questionnaire')
    },
    {
        selector: '[data-tour="step-news"]',
        mutationObservables: ['.mobileHeaderModal'],
        highlightedSelectors: ['[data-tour="step-news"]'],
        content: thinContent('Stay up-to date with the "Whats new" section. Here you will find updated or new documents of the last 4 weeks.')
    },
    {
        content: ({setIsOpen}) => {
            setTimeout(() => setIsOpen(false), 2000);
            return 'End of tour, get going!'
        },
        position: 'center',
        padding: -100,
        disableActions: true, 
    },
]