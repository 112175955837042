import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from '../../api/api';
import NewsListEntry from './NewsListEntry';
import {ReactComponent as IconAlert} from '../../assets/icons/Icon_Alert.svg'
import { REQUEST_STATUS } from '../../store/types';
import RequestStatus from '../elements/RequestStatus';
import Pagination from '../elements/Pagination';

const NewsList = ({selectedTab = null, searchString = null, showOnlyUnread = false, markAsRead = true, listSize = null, paginationHidden = false, language = null, country = null }) => {
    const [list, setList] = useState([]);
    const [requestStatus, setRequestStatus] = useState(REQUEST_STATUS.IDLE)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const filterUnread = useCallback((listEntry) => {
      return !listEntry.read
    }, [])

    const filteredList = useMemo(() => {
      let newList = [...list]
      if(showOnlyUnread) {
        newList = newList.filter(filterUnread)
      }
      return newList
    }, [filterUnread, list, showOnlyUnread])

    const getList = useCallback((page) => {
      setRequestStatus(REQUEST_STATUS.PENDING)
      setList([])
        api.getNews(page, listSize, selectedTab, markAsRead, searchString, language, country)
          .then(res => {
            setList(res.data.results)
            setRequestStatus(REQUEST_STATUS.IDLE)
            setTotalPages(res.data.total_pages)
          })
          .catch(err => {
            console.log(err)
            setRequestStatus(REQUEST_STATUS.ERROR)
            setTimeout(() => setRequestStatus(REQUEST_STATUS.IDLE, 3000))
          })
    }, [country, language, listSize, markAsRead, searchString, selectedTab])

    useEffect(() => {
      setTotalPages(0)
      if(currentPage === 1) {
        getList(1)
      }
      setCurrentPage(1)
    }, [selectedTab, listSize, searchString, language, country]);

    useEffect(() => {
      getList(currentPage)
    }, [currentPage]);


    return (
        <div className='flex flex-col w-full gap-2'>
            {filteredList.map(news => <NewsListEntry key={news.id} newsEntry={news}></NewsListEntry>)}
            <div className='flex items-center justify-center w-full'>
              {filteredList.length === 0 && requestStatus === REQUEST_STATUS.IDLE && 
              <div className='flex items-center gap-1 my-12 text-gray'><IconAlert className='w-5' /><p>No news found. Please try another search.</p></div>
              }
              {requestStatus !== REQUEST_STATUS.IDLE && <div className='mt-12 mb-4'><RequestStatus status={requestStatus} /></div>}
            </div>
            {totalPages > 0 && !paginationHidden &&
            <div className='flex justify-center w-full max-lg:overflow-x-auto hiddenScrollbars'>
              <Pagination currentPage={currentPage} totalPages={totalPages} onPageSelect={setCurrentPage}/>
            </div>}
        </div>
    );
}
 
export default NewsList 