import React from 'react';
import {ReactComponent as IconClose} from '../../assets/icons/Icon_Close.svg'

const FilterSelectionList = ({selectedOptions, onDeselect, onClear}) => {
    return (  
        <div className='flex flex-wrap items-center gap-2'>
            {selectedOptions.map(option => 
                <div 
                    className='bg-gray-600 text-white text-sm rounded-sm px-2 py-0.5 flex items-center gap-1' 
                    key={option.value}
                >
                    <span>{option.label}</span><button onClick={() => onDeselect(option)}><IconClose className='w-3' /></button>
                </div>)}
                {selectedOptions?.length > 0 && <button onClick={onClear} className='text-xs text-gray'>Remove All</button>}
        </div>
    );
}
 
export default FilterSelectionList;