import React, { useEffect, useState } from 'react';
import {useMsal, useIsAuthenticated, UnauthenticatedTemplate, AuthenticatedTemplate} from '@azure/msal-react';
import {useLocation, useNavigate} from "react-router";
import { loginADRequest, loginRequest as loginB2CRequest } from '../../authConfig';
import { useDispatch, useSelector } from 'react-redux';
import { set_login_type } from '../../store/actions/auth';
import { LOGIN_TYPES, REQUEST_STATUS, sessionStorageRedirectKey } from '../../store/types';
import { InteractionStatus } from '@azure/msal-browser';
import PrimaryButton from '../../components/elements/PrimaryButton';
import SecondaryButton from '../../components/elements/SecondaryButton';
import RequestStatus from '../../components/elements/RequestStatus';

const LoginWithAD = () => {

    const [loggingInStatus, setLoggingInStatus] = useState(REQUEST_STATUS.IDLE);
    const {instance, inProgress} = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const login_type = useSelector((state) => state.auth.login_type);
    const dispatch = useDispatch();
    const location = useLocation()
    const redirect = location.state?.redirect ?? sessionStorage.getItem(sessionStorageRedirectKey) ?? "/"
    const navigate = useNavigate()

    useEffect(() => {
      if(loggingInStatus === REQUEST_STATUS.PENDING && inProgress === InteractionStatus.None) {
          handleLogin();
      }
    }, [instance, inProgress])

    useEffect(() => {
        if(isAuthenticated) {
            sessionStorage.removeItem(sessionStorageRedirectKey)
            navigate(redirect)
        }
    }, [isAuthenticated]);
    
    function startLoginWithAD() {
        setLoggingInStatus(REQUEST_STATUS.PENDING);
        if(login_type === LOGIN_TYPES.AD) {
            handleLogin()
        }
        else {
            dispatch(set_login_type(LOGIN_TYPES.AD));
        }
    }
    function startLoginWithB2C() {
        setLoggingInStatus(REQUEST_STATUS.PENDING);
        if(login_type === LOGIN_TYPES.B2C) {
            handleLogin()
        }
        else {
            dispatch(set_login_type(LOGIN_TYPES.B2C));
        }
    }

    function handleLogin() {
        const loginRequest = login_type === LOGIN_TYPES.B2C ? loginB2CRequest : loginADRequest;
        instance.loginRedirect(loginRequest).then(() => setLoggingInStatus(REQUEST_STATUS.IDLE)).catch(e => {console.log('Error on Login',e); setLoggingInStatus(REQUEST_STATUS.ERROR)});
    }
    function handleLogout() {
        instance.logoutRedirect({postLogoutRedirectUri: redirect}).catch(e => console.log('Error on Logout',e));
    }

    return (
        <div className='flex flex-col content-start gap-3 '>
            <RequestStatus status={loggingInStatus} />
            <UnauthenticatedTemplate>
                <div className='flex content-start '>
                <PrimaryButton 
                    className='mr-2'
                    onClick={startLoginWithB2C}
                    disabled={loggingInStatus === REQUEST_STATUS.PENDING}
                    title={loggingInStatus === REQUEST_STATUS.PENDING ? 'Redirecting' : 'Login'}
                    expanded={false}
                />
                <SecondaryButton 
                    onClick={startLoginWithAD}
                    disabled={loggingInStatus === REQUEST_STATUS.PENDING}
                    title={'Login with Sasol account'}
                    size={'md'}

                />
                </div>
                
                
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
            <button 
                className='w-full p-3 text-lg font-semibold bg-white border border-gray-600 rounded-sm'
                onClick={handleLogout}
            >
                Logout
            </button>
            </AuthenticatedTemplate>
        </div>
    )
}

export default LoginWithAD;