import React from 'react';
import {Link} from 'react-router-dom'
import {ReactComponent as IconBasket} from '../../assets/icons/Icon_Basket.svg'
import useTotalBasketCount from '../../hooks/useTotalBasketCount';

const BasketHeaderIcon = ({backlink}) => {

    const totalCount = useTotalBasketCount()

    return (  
        <Link to="/basket" state={{backLink: backlink}}>
            <div className='relative p-2 border rounded text-cyan border-gray'>
                <IconBasket className='w-6 h-6 lg:w-4 lg:h-4' />
                {totalCount > 0 &&
                    <div className='absolute top-[-25%] right-[-25%]'>
                        <p className='w-full px-[0.375rem] text-sm text-center text-white rounded bg-blue'>{totalCount}</p>
                    </div>
                }
            </div>
        </Link>
    );
}
 
export default BasketHeaderIcon;