import { useEffect } from "react"

const useHideChatbot = (hide) => {
    useEffect(() => {
        const chatElement = document.getElementsByClassName('rsc-float-button')[0]
        if(chatElement?.classList) {
            if(hide) {
                chatElement.classList.add('!z-10')
            }
            else {
                chatElement.classList.remove('!z-10')
            }
            return () => {
                chatElement.classList.remove('!z-10')
            }
        }
    }, [hide]);
}

export default useHideChatbot