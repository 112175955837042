import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {ReactComponent as IconCopy} from '../../assets/icons/Icon_Copy.svg'
import CloseButton from '../../components/elements/CloseButton';
import { stopCustomerView } from '../../store/actions/auth';
import {useNavigate} from 'react-router-dom'

const CloseCustomerViewButton = () => {

    const [isExtended, setIsExtended] = useState(true);
    const customerName = useSelector(state => state.auth.user?.consolidated_customers.find(customer => customer.value === state.auth.user.preferred_view)?.label)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const closeCustomerView = useCallback(() => {
        dispatch(stopCustomerView())
        navigate('/profile')
    }, [dispatch, navigate])

    useEffect(() => {
        if(isExtended) {
            const retractTimer = setTimeout(() => setIsExtended(false), 2000)
            return () => {clearTimeout(retractTimer)}
        }
    }, [isExtended]);

    return (
        <div className={`transition-[padding] fixed bg-white rounded-full pointer-events-auto right-3 bottom-8 lg:right-6 w-fit max-w-[95vw] flex ${isExtended ? 'p-4 gap-4 items-center shadow-card' : ''}`}>
            <button className='p-4 border-2 rounded-full text-cyan border-cyan hover:bg-cyan-50' onClick={() => setIsExtended(true)}>
                <IconCopy className='w-6 h-6' />
            </button>
            {isExtended &&
            <>
                <div>
                    <p className='font-semibold text-gray'>Customer view of {customerName ?? 'Customer'}</p>
                    <button className='text-sm underline text-cyan' onClick={closeCustomerView}>Leave customer view</button>
                </div>
                <CloseButton onClose={() => setIsExtended(false)} />
            </>
            
            }
        </div>
    )
}
 
export default CloseCustomerViewButton;