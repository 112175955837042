import React from 'react';
import ProductsList from './ProductsList';

const MobileSearchSuggestionsList = ({suggestions, backLink}) => {
    return (
        <div className='container h-full py-4 overflow-y-scroll'>
            <ProductsList list={suggestions} backLink={backLink} reduced/>
        </div>
    )
}
 
export default MobileSearchSuggestionsList;