import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {ReactComponent as IconAlert} from '../../assets/icons/Icon_Alert.svg'
import {ReactComponent as IconCopy} from '../../assets/icons/Icon_Copy.svg'
import {ReactComponent as IconDownload} from '../../assets/icons/Icon_Download.svg'
import SecondaryButton from '../../components/elements/SecondaryButton'
import usePreloadDocuments from '../../hooks/usePreloadDocuments';
import { startCustomerView, stopCustomerView } from '../../store/actions/auth';
import RequestStatus from '../../components/elements/RequestStatus'
import { REQUEST_STATUS } from '../../store/types';


const CustomerCard = ({customer}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const customerIsActiveView = useSelector(state => state.auth.user.preferred_view === customer.value)
    const [waitingForViewSwitch, setWaitingForViewSwitch] = useState(false);
    const {provideCustomerProductsDownload, downloadRequestStatus} = usePreloadDocuments()

    const startView = useCallback(() => {
        setWaitingForViewSwitch(true)
        const {value} = {...customer}
        dispatch(startCustomerView(value))
    }, [customer, dispatch])

    const dowloadCustomerProducts = useCallback(() => {
        provideCustomerProductsDownload(customer.value, customer.label)
    }, [customer.label, customer.value, provideCustomerProductsDownload])

    useEffect(() => {
        if(waitingForViewSwitch && customerIsActiveView) {
            setWaitingForViewSwitch(false)
            navigate('/')
        }
    }, [customerIsActiveView, navigate, waitingForViewSwitch]);

    const stopView = useCallback(() => dispatch(stopCustomerView()), [dispatch])

    return (
        <div className='flex items-center justify-between w-full gap-2 py-3 pl-4 pr-2 bg-white rounded'>
            <p className='font-medium'>{customer.label}</p>
            <div className='flex gap-1'>
                <SecondaryButton className='flex items-center gap-1' onClick={dowloadCustomerProducts}>
                    {downloadRequestStatus === REQUEST_STATUS.IDLE ? <IconDownload className='w-4' /> : <div className='py-1'><RequestStatus status={downloadRequestStatus} size={16} /></div>}
                    <p className='hidden lg:block'>Download Products</p>
                </SecondaryButton>
                <SecondaryButton cyan solid={customerIsActiveView} onClick={customerIsActiveView ? stopView : startView}>
                    <div className='flex items-center gap-1'>
                        <p className='hidden lg:block'>{customerIsActiveView ? 'Stop' : 'Start'} customer view</p>
                        <IconCopy className='w-4 h-4' />
                    </div>
                </SecondaryButton>
            </div>
        </div>
    )
}

const CustomerViewSection = () => {

    const customers = useSelector(state => state.auth.user.consolidated_customers)
    const {provideCustomerProductsDownload, downloadRequestStatus} = usePreloadDocuments()

    const dowloadAllCombinations = useCallback(() => {
        provideCustomerProductsDownload()
    }, [provideCustomerProductsDownload])

    if(customers?.length <= 0) {
        return (
            <div className='flex items-center justify-center w-full gap-1 mt-3 text-gray'>
                <IconAlert className='w-5' />
                <p>The Customer View is only available to those Sasol colleagues that are assigned to an account team in CRM or that were granted extended access rights.</p>
            </div>
        )
    }

    return (
        <div>
            <p className='ml-4 text-sm font-semibold text-gray'>Customers Name</p>
            <div className='flex flex-col lg:flex-row gap-4 mt-2.5'>
                <div className='flex flex-col gap-2 basis-4/5'>
                    {[...customers].sort((customer1, customer2) => customer1.label.localeCompare(customer2.label)).map(customer => <CustomerCard key={customer.value} customer={customer} />)}
                </div>
                <div className='px-4 py-2 bg-white rounded basis-1/5 h-fit'>
                    <p className='w-full py-1 mb-4 border-b text-gray border-b-gray-400'>Download</p>
                    <SecondaryButton title='Download all customer product combinations' cyan onClick={dowloadAllCombinations} />
                    <div className='flex justify-center gap-1 mt-4 text-sm text-gray items center'><RequestStatus status={downloadRequestStatus}/> {downloadRequestStatus === REQUEST_STATUS.PENDING && 'Downloading'}</div>
                </div>
            </div>

        </div>
    )
}
 
export default CustomerViewSection;