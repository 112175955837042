import React from 'react';
import { ReactComponent as IconClose } from '../../assets/icons/Icon_Close.svg';


const CloseButton = ({onClose, defaultColor = 'blue', hoverColor = 'cyan'}) => {
    return (  
        <button className={`text-${defaultColor} hover:text-${hoverColor}`} onClick={onClose} type="button">
            <IconClose className='transition-transform duration-300 ease-in-out hover:rotate-90' />
        </button>
    );
}
 
export default CloseButton;