import { useSelector } from "react-redux"
import { basketSelectors } from "../store/slices/basket/basket"

const useCompleteBasketCollection = () => {
    const pds =  useSelector(state => basketSelectors.pds.selectIds(state))
    const sds =  useSelector(state => basketSelectors.sds.selectIds(state))
    const ris =  useSelector(state => basketSelectors.ris.selectIds(state))
    const eri =  useSelector(state => basketSelectors.eri.selectIds(state))
    const marketing = useSelector(state => basketSelectors.marketing.selectIds(state))
    const certificates = useSelector(state => basketSelectors.certificates.selectIds(state))

    return {pds, sds, ris, eri, marketing, certificates}
}

export default useCompleteBasketCollection