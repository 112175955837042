import React from 'react';
import {useParams} from "react-router";
import useCheckAndTrigger from '../../hooks/useCheckAndTrigger';


const CurrentProductCheck = (props) => {
    const {slug} = useParams();
    useCheckAndTrigger(slug, props.triggerTrue, props.triggerFalse, props.triggerNextStep);
    
    return <span className='text-blue'>Checking for current product</span>
}

export default CurrentProductCheck;