import React, { useEffect, useState } from 'react';
import { api } from '../../../api/api';
import SecondaryButton from '../../elements/SecondaryButton';
import {ReactComponent as IconDownload} from '../../../assets/icons/Icon_Download.svg';
import Modal from '../../elements/Modal';
import useModal from '../../../hooks/useModal';
import DocumentPreview from '../../elements/DocumentPreview';
import usePreloadDocuments from '../../../hooks/usePreloadDocuments';
import { DocumentTypes, REQUEST_STATUS } from '../../../store/types';
import RequestStatus from '../../elements/RequestStatus';
import CollectDocumentButton from '../../elements/CollectDocumentButton';
import { useDocumentLeftBorderColor } from '../../../hooks/useDocumentBorderColor';
import { useTwScreens } from '../../../utilities/TailwindScreens';
import PreviewIcon from '../../elements/PreviewIcon';

const MarketingMaterialItem = ({material}) => {

    const {isShowing, toggle} = useModal();
    const {downloadRequestStatus, provideMarketingMaterialDownload} = usePreloadDocuments();
    const [showingPreviewButton, setShowingPreviewButton] = useState(false);
    const borderColor = useDocumentLeftBorderColor(DocumentTypes.MARKETING)
    const {min, excludeFrom} = useTwScreens()

    function preview() {
        if(min('md')) {
            toggle()
        }
    }
    function dowloadMarketingMaterial() {
        provideMarketingMaterialDownload(material.file, material.name);
    }

    return (
        <div 
            className={`shadow-card p-2 flex gap-4 lg:min-w-[35.25rem] border-l-2 ${borderColor}`}
            onMouseEnter={() => setShowingPreviewButton(true)}
            onMouseLeave={() => setShowingPreviewButton(false)}
        >
            <div className='flex items-center text-xs text-center text-gray-600 border rounded-sm cursor-pointer max-lg:hidden border-gray-50' onClick={preview}>
                <img src={material.thumbnail} alt={material.name + ' thumbnail'} className='max-w-[6.625rem]' />
            </div>
            <div className='flex flex-col justify-between flex-grow'>
                <div className='flex flex-col flex-grow cursor-pointer' onClick={preview}>
                    <div className='flex justify-between gap-2 mb-2'>
                        <p className='px-[0.375rem] py-[0.125rem] text-gray-600 text-sm bg-gray-50 rounded-sm'>{material.document_format}</p>
                        {(showingPreviewButton || (min('md') && excludeFrom('lg'))) && 
                        <PreviewIcon />
                        }
                    </div>
                    <p className='font-medium'>{material.name}</p>
                    <p className='font-normal text-gray-600'>{material.document_type}</p>
                </div>
                <div className='flex justify-end gap-2'>
                    <div className='flex-grow cursor-pointer' onClick={preview}/>
                    <CollectDocumentButton document={material} documentType={DocumentTypes.MARKETING} />
                    <div className='max-lg:hidden'>
                        <SecondaryButton cyan onClick={dowloadMarketingMaterial}>
                            {downloadRequestStatus === REQUEST_STATUS.IDLE ?
                                <div className='flex items-center'><IconDownload className='w-4' /></div>
                            :
                                <div className='px-10'>
                                    <RequestStatus status={downloadRequestStatus}/>
                                </div>
                            }
                        </SecondaryButton>
                    </div>
                </div>
            </div>
            <Modal isShowing={isShowing} close={toggle}>
                <DocumentPreview 
                    document={material} 
                    docType={DocumentTypes.MARKETING} 
                    onDownload={dowloadMarketingMaterial} 
                    downloadStatus={downloadRequestStatus} 
                    onClose={toggle} 
                />
            </Modal>
        </div>
    )
}

const MarketingMaterialList = ({slug}) => {

    const [marketingMaterials, setMarketingMaterials] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true);
      api.getProductMarketing(slug)
        .then(response => {
            setMarketingMaterials(response.data);
            setIsLoading(false);
        })
        .catch(error => {
            console.log(error)
            setIsLoading(false);
        })
    }, [slug])
    

    return (
        <div className='flex flex-col gap-6 px-1 pt-1 pb-3 lg:flex-row lg:overflow-x-scroll nowrap customScrollbars'>
            {marketingMaterials.map(material => {
                return <MarketingMaterialItem key={material.id} material={material} />
            })}
            {isLoading &&
                <div className='w-full text-center text-gray'>Loading</div>
            }
            {marketingMaterials.length <= 0 && !isLoading &&
                <p className='w-full text-center'>No marketing material found</p>
            }
        </div>
    )
}

export default MarketingMaterialList;