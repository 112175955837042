import { useState } from "react";
import { useSelector } from "react-redux";
import { api } from "../api/api";
import { REQUEST_STATUS } from "../store/types";
import GA4Helper from "../utilities/GA4Helper";

const defaultMailText = `Hello, \n\nThe attached files are being shared with you from the Sasol product database. \n\nBest regards \n`

const useDocumentSharing = (documentsToShare, onSharingSuccess, onSharingError) => {
    const userName = useSelector((state) => state.auth.user.name)
    const [mailList, setMailList] = useState([]);
    const [mailText, setMailText] = useState(defaultMailText + userName);
    const [mailSubject, setMailSubject] = useState('');
    const [sharingError, setSharingError] = useState(null)
    const [sharingSuccessMessage, setSharingSuccessMessage] = useState(null)
    const [sharingStatus, setSharingStatus] = useState(REQUEST_STATUS.IDLE)
    const [sendingCC, setSendingCC] = useState(false);

    function addToMailList(mail) {
        if(mailList.indexOf(mail) < 0) {
            setMailList([...mailList, mail]);
        }
    }
    function removeFromMailList(mail) {
        const copy = [...mailList];
        const index = copy.indexOf(mail);
        if(index >= 0) {
            copy.splice(index, 1);
            setMailList(copy);
        }
    }

    function shareDocuments() {
        setSharingStatus(REQUEST_STATUS.PENDING)
        GA4Helper.shareEvent(documentsToShare.pds?.length ?? 0, documentsToShare.sds?.length ?? 0, documentsToShare.ris?.length ?? 0, documentsToShare.eri?.length ?? 0, documentsToShare.marketing?.length ?? 0, documentsToShare.certificates?.length ?? 0,)
        if(mailList.length > 0) {
            setSharingError(null)
            const subject = mailSubject.trim().length > 0 ? mailSubject.trim() : null
            api.shareDocuments(documentsToShare.pds.map(d => d.id ?? d), documentsToShare.sds.map(d => d.id ?? d), documentsToShare.ris.map(d => d.id ?? d), documentsToShare.eri.map(d => d.id ?? d), documentsToShare.marketing.map(d => d.id ?? d), documentsToShare.certificates.map(d => d.id ?? d), mailList, mailText, subject, sendingCC)
            .then((response) => {
                setSharingStatus(REQUEST_STATUS.IDLE)
                setSharingSuccessMessage('Email was sent successfully')
                if(onSharingSuccess) {
                    onSharingSuccess(response)
                }
            })
            .catch((error) => {
                setSharingStatus(REQUEST_STATUS.ERROR)
                setSharingError(error)
                if(onSharingError) {
                    onSharingError(error)
                }
            });
        }
    }

    return {mailList, addToMailList, removeFromMailList, shareDocuments, mailText, setMailText, mailSubject, setMailSubject, sharingStatus, sharingSuccessMessage, sharingError, sendingCC, setSendingCC}
}

export default useDocumentSharing