import React from 'react';
import Footer from "../layouts/Footer";
import background from "../assets/SASOL_Header_Illustration_.jpg";
import { Link } from "react-router-dom";
import LoginWithAD from '../containers/auth/LoginWithAD';


const LoginPage = () => {
    return (
        <div className='flex flex-col justify-between min-h-screen bg-cover max-lg:bg-center loginPage text-blue' style={{backgroundImage: `url(${background})`}}>
            <div className="flex gap-6 px-3 py-4 bg-transparent lg:px-6" >
                <Link to="//www.sasol.com/index.php/who-we-are/about-us" target='_blank'>About Sasol</Link>
                {/*<Link to="/contact"> Contact</Link>*/}
            </div>
            <div className='container flex gap-12 flex-nowrap'>
                <div className='lg:max-w-[70%]'>
                    <img src='/SasolLogo.png' className="w-64" alt='Sasol Logo'/>
                    <h1 className='mb-4 text-5xl font-bold lg:mb-6 mt-7 lg:mt-10 '>
                        Welcome to our product database. 
                    </h1>
                    <p className='mb-10 text-xl font-light lg:mb-3 '>
                        Get standard documents and information relating to our products.
                    </p>
                    <LoginWithAD />
                </div>
                
            </div>
            <Footer></Footer>
        </div>
    )
}

export default LoginPage;