import { Tab } from '@headlessui/react';
import React from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { api } from '../../api/api';
import { REQUEST_STATUS } from '../../store/types';
import RequestStatus from '../elements/RequestStatus';
import VerticalTab from '../elements/VerticalTab';
import FAQCategoryTab from './FAQCategoryTab';
import {ReactComponent as IconAlert} from '../../assets/icons/Icon_Alert.svg'
import { useSelector } from 'react-redux';
import { useTwScreens } from '../../utilities/TailwindScreens';

const FAQSection = ({searchInput}) => {

    const isInternal = useSelector(state => !!state.auth.user.internal)
    const [data, setData] = useState([])
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0)
    const [requestStatus, setRequestStatus] = useState(REQUEST_STATUS.IDLE)
    const categories = useMemo(() => {
        return [...new Set(data.map(entry => entry.category))].sort()
    }, [data])
    const {min} = useTwScreens()

    const groupedData = useMemo(() => {
        const grouped = {}
        categories.forEach(category => {grouped[category] = []})
        data.forEach(faq => {
            grouped[faq.category].push(faq)
        })
        categories.forEach(category => {grouped[category].sort((faq1, faq2) => faq1.order - faq2.order)})
        return grouped
    }, [data, categories])

    useEffect(() => {
        const debouncedRequest = setTimeout(getData, 300)
        return () => clearTimeout(debouncedRequest)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInput]);

    function getData() {
        setRequestStatus(REQUEST_STATUS.PENDING)
        api.getFAQData(searchInput, isInternal)
            .then(res => {
                setRequestStatus(REQUEST_STATUS.IDLE)
                setData(res.data)
            })
            .catch(err => {
                setRequestStatus(REQUEST_STATUS.IDLE)
                console.log(err)
            })
    }

    return ( 
        <>
            <Tab.Group vertical={min('lg')} selectedIndex={selectedCategoryIndex} onChange={setSelectedCategoryIndex} as={'div'} className='flex items-stretch w-full gap-6 max-lg:flex-col lg:container'>
                <div className='py-2 bg-gray-25 basis-1/4'>
                    <Tab.List as='div' className='flex items-stretch h-full max-lg:border-b max-lg:border-b-gray-200 lg:border-r lg:flex-col lg:border-r-gray-400 max-lg:gap-6 max-lg:overflow-x-scroll hiddenScrollbars max-lg:px-3'>
                        <VerticalTab>All Categories</VerticalTab>
                        {categories.map(category => 
                            <VerticalTab key={category} >
                                {category}
                            </VerticalTab>
                        )}
                        {requestStatus === REQUEST_STATUS.PENDING && <RequestStatus status={requestStatus}/>}
                    </Tab.List>
                </div>
                <div className='container basis-3/4'>
                    <Tab.Panels>
                        <Tab.Panel className='flex flex-col gap-10'>
                            {categories.map(category => <FAQCategoryTab key={category} category={category} data={groupedData[category]} />)}
                            {categories.length ===  0 && requestStatus !== REQUEST_STATUS.PENDING &&
                            <div className='flex items-center justify-center w-full gap-1 mt-3 text-gray'><IconAlert className='w-5' /><p>Nothing found. Please try another search.</p></div>
                            }
                        </Tab.Panel>
                        {categories.map(category => <Tab.Panel key={category}><FAQCategoryTab category={category} data={groupedData[category]} /></Tab.Panel>)}
                    </Tab.Panels>
                    {requestStatus === REQUEST_STATUS.PENDING && <RequestStatus status={requestStatus}/>}
                </div>
            </Tab.Group>
        </>
    );
}
 
export default FAQSection;