import { Tab } from '@headlessui/react';
import React from 'react';

const VerticalTab = ({children}) => {
    return (  
        <Tab 
            className='flex py-2 font-semibold transition-colors duration-300 lg:px-6 ui-selected:max-lg:border-b-2 ui-selected:max-lg:border-b-cyan ui-selected:text-cyan ui-selected:lg:border-r-2 ui-selected:lg:border-r-cyan hover:text-cyan text-start whitespace-nowrap' 
        >
            {children}
        </Tab>
    );
}
 
export default VerticalTab;