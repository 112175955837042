import React, { useMemo } from 'react';
import {ReactComponent as IconChevronRight} from '../../assets/icons/Icon_Chevron-Right.svg'
import {ReactComponent as IconChevronLeft} from '../../assets/icons/Icon_Chevron-Left.svg'
import { useTwScreens } from '../../utilities/TailwindScreens';

const PaginationItem = ({isSelected, disabled, onClick, children}) => {
    return (
        <button 
            className={`rounded border font-semibold p-2 ${isSelected ? 'border-gray bg-gray text-white' : 'border-gray-400 text-gray'} ${disabled ? 'opacity-30' : ''}`} 
            disabled={disabled}
            onClick={onClick}
        >
            <div className='min-w-[1.5rem]'>
                {children}
            </div>
        </button>
    )
}

const Pagination = ({currentPage, totalPages, onPageSelect}) => {

    const {min} = useTwScreens()

    const pageIndexes = useMemo(() => {
        const indexes = currentPage > 1 && currentPage < totalPages ? [currentPage] : []
        const stopIndex = Math.min(3, Math.max(0, totalPages-2))
        for(let i = 1; (indexes.length < stopIndex) && (currentPage + i < totalPages || i < 4); i++) {
            if(currentPage - i > 1) {
                indexes.splice(0,0, currentPage - i)
            }
            if(currentPage + i < totalPages) {
                indexes.push(currentPage + i)
            }
        }
        return indexes
    }, [currentPage, totalPages]) 

    const maxPage = useMemo(() => Math.max(...pageIndexes), [pageIndexes])

    return (
        <div className='flex gap-3'>
            {min('lg') &&
            <PaginationItem 
                disabled={currentPage === 1} 
                onClick={() => onPageSelect(currentPage-1)}
            >
                <IconChevronLeft />
            </PaginationItem>}
            <PaginationItem onClick={() => onPageSelect(1)} isSelected={currentPage === 1}>1</PaginationItem>
            {(pageIndexes[0] > 2) && <PaginationItem>...</PaginationItem>}
            {pageIndexes.map(page => 
                <PaginationItem 
                    key={page}
                    isSelected={page === currentPage}
                    onClick={() => onPageSelect(page)}
                >
                    <p className='min-w-[1.5rem]'>{page}</p>
                </PaginationItem>
            )}
            {(maxPage < (totalPages - 1) && Math.abs(maxPage) !== Infinity) &&
            <PaginationItem>...</PaginationItem>
            }
            {totalPages > 1 &&
            <PaginationItem onClick={() => onPageSelect(totalPages)} isSelected={currentPage === totalPages}>
                {totalPages}
            </PaginationItem>
            }
            {min('lg') &&
            <PaginationItem 
                disabled={currentPage === totalPages}
                onClick={() => onPageSelect(currentPage+1)}
            >
                <IconChevronRight />
            </PaginationItem>}
        </div>
    )
}
 
export default Pagination