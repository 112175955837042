import React from 'react';
import {ReactComponent as IconMenu} from '../../assets/icons/Icon_User.svg'
import {ReactComponent as IconClose} from '../../assets/icons/Icon_Close.svg'

const MobileProfileButton = ({onClick, isOpen}) => {
    return (
        <button className='relative p-2 border rounded text-cyan border-gray' onClick={onClick} data-tour="step-userButton">
            {isOpen ?
            <IconClose />
            :
            <IconMenu />
            }
        </button>
    )
}
 
export default MobileProfileButton;