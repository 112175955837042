import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { ReactComponent as IconUser } from "../../assets/icons/Icon_User.svg";
import {useIsAuthenticated} from "@azure/msal-react";
import RequestStatus from '../elements/RequestStatus';
import ProfileNavigationList from './ProfileNavigationList';



const ProfileStatusButton = () => {

    const isAuthenticated = useIsAuthenticated();
    const user = useSelector((state) => state.auth.user);
    const userLoadingStatus = useSelector((state) => state.auth.load_user_status);
    const [dropdownShowing, setDropdownShowing] = useState(false);

    return (
        <div className="rounded border border-gray relative lg:min-w-[8rem]" onMouseLeave={() => setDropdownShowing(false)} data-tour="step-userButton">
            {isAuthenticated ?
                <div className='p-2 cursor-pointer lg:px-2 lg:py-1' onMouseEnter={() => setDropdownShowing(true)}>
                    <div className="flex items-center justify-center gap-1 text-cyan nowrap">
                        <IconUser className="lg:w-4 lg:h-4"/>
                        {(user !== null) ?
                            <p className='hidden text-white lg:block'>{user.first_name} {user.last_name}</p>
                        :
                            <div className='flex-grow hidden lg:block'><RequestStatus status={userLoadingStatus} /></div>
                        }
                    </div>
                </div> 
            :
                <div className='text-center p2'>
                    <Link to="/login">Log in</Link>
                </div>
            }
                {dropdownShowing &&
                <div className='absolute w-full pt-1'>
                    <ProfileNavigationList />
                </div>
                }
        </div>
    )
}

export default ProfileStatusButton;