import React from 'react';

const MobileNavListItem = ({children}) => {
    return (
        <li className='max-lg:text-white max-lg:bg-darkblue max-lg:text-xl max-lg:font-light max-lg:px-4 max-lg:py-2 max-lg:w-full max-lg:flex max-lg:flex-col max-lg:items-stretch'>
            {children}
        </li>
    )
}
 
export default MobileNavListItem;