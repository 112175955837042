import { useEffect, useState } from "react";

const useContactFormValidation = (formState, setError) => {
    const [openFields, setOpenFields] = useState(Object.keys(formState));

    useEffect(() => {
        const fields = Object.keys(formState);
        setOpenFields(fields.filter(field => {
            return (!formState[field].value || formState[field].value.trim().length === 0)
        }))
    }, [formState]);

    function validateForm() {
        for(let field of openFields) {
            if(!formState[field].value || formState[field].value.trim().length === 0) {
                setError(field, '')
            }
        }
    }

    function formDataIsValid() {
        const errors = Object.entries(formState).filter(entry => entry[1].error !== null);
        return (errors.length === 0 && openFields.length === 0); 
    }

    function isSasolMail(mail) {
        const re = /\S+@\S*sasol\.com/
        return re.test(mail)
    }

    return [validateForm, formDataIsValid, isSasolMail];
}

export default useContactFormValidation;