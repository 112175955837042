import React from 'react';
import { DocumentTypes } from '../../store/types';
import {ReactComponent as IconTrash} from '../../assets/icons/Icon_Trash.svg'
import { useMemo } from 'react';

function additionalElements(array) {
    const additionalItems = array.length -1
    return additionalItems > 0 ? `, +${additionalItems}` : ''
}

const ReducedDocumentBasketListEntry = ({document, type, onRemove}) => {

    const title = useMemo(() => {
        return type === DocumentTypes.MARKETING ? document.name : document.type 
    }, [document.name, document.type, type])

    const slot1 = useMemo(() => {
        return type === DocumentTypes.MARKETING ? '' : (document.companies ? `${document.companies[0]}${additionalElements(document.companies)}` : null) 
    }, [document.companies, type])

    const slot2 = useMemo(() => {
        return type === DocumentTypes.MARKETING ? '' : (document.sites ? `${document.sites[0]}${additionalElements(document.sites)}` : null) 
    }, [document.sites, type])

    return (
        <div className='w-full rounded shadow-card'>
            <div className={`flex justify-between bg-white p-2 items-center text-gray gap-1 ${type === DocumentTypes.MARKETING ? 'border-l-2 border-l-gray' : ''}`}>
                <div className='flex flex-col'>
                    <p className='font-medium text-blue'>{title}</p>
                    <p className='text-sm'>{slot1}</p>
                    <p className={`text-sm`}>{slot2}</p>
                </div>
                <button onClick={onRemove}><IconTrash className='w-6' /></button>
            </div>
        </div>
    )
}
 
export default ReducedDocumentBasketListEntry;