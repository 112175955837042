import React, { forwardRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReducedDatasheetBasketListEntry from '../../containers/basket/ReducedDatasheetBasketListEntry';
import useDatasheetSelector from '../../hooks/useDatasheetSelector';
import { addDocumentToSelection, removeDocumentFromSelection } from '../../store/slices/basket-selection/basketSelection';
import { removeDocumentFromBasket } from '../../store/slices/basket/basket';
import DatasheetListEntry from '../products/datasheets/DatasheetListEntry';

const DatasheetBasketListEntry = forwardRef((props, ref) => {

    const {datasheetId, type, product, reduced, onSyncedScroll, extendableColumnWidth, toggleExtendableColumn} = props

    const basketSelector = useDatasheetSelector(type)
    const selectionSelector = useDatasheetSelector(type, true)
    const datasheet = useSelector(state => basketSelector.selectById(state, datasheetId))
    const dispatch = useDispatch()

    const selected = useSelector(state => selectionSelector.selectById(state, datasheetId) !== undefined)

    const toggleSelection = useCallback(() => {
       
        if(selected) {
            dispatch(removeDocumentFromSelection(product.id, type, datasheetId))
        }
        else {
            dispatch(addDocumentToSelection(product, type, datasheet))
        }
    }, [dispatch, product, type, selected, datasheet, datasheetId])

    return (
        reduced ?
        <ReducedDatasheetBasketListEntry 
            datasheet={datasheet}
            type={type}
            onRemove={() => dispatch(removeDocumentFromBasket(product.id, type, datasheetId))}
            subListItem
        /> 
        :
        <DatasheetListEntry
            datasheet={datasheet}
            type={type}
            selected={selected}
            onSelection={toggleSelection}
            product={product}
            ref={ref}
            onSyncedScroll={onSyncedScroll}
            extendableColumnWidth={extendableColumnWidth}
            toggleExtendableColumn={toggleExtendableColumn}
        />
    );
})
 
export default DatasheetBasketListEntry;