import React, { useEffect } from 'react';

const ProductSelection = (props) => {
    const product = props.steps[props.currentProductStep].value ? props.steps[props.currentProductStep].value : props.steps[props.productInputStep].value
    useEffect(() => {setTimeout(props.triggerNextStep({trigger: props.trigger, value: product}), 600)}, [])
    return (
        <>
            Saving Product
        </>
    )
}
export default ProductSelection;