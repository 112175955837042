/*
AUTH TYPES
*/

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED_SUCCESS = "LOGGED_USER_SUCCESS";
export const USER_LOADED_FAIL = "LOGGED_USER_FAIL";
export const USER_UPDATED = "USER_UPDATED";
export const LOGIN_TYPE_CHANGED = "LOGIN_TYPE_CHANGED";
export const AUTH_ERROR_OCCURED = "AUTH_ERROR_OCCURED";
export const AUTH_ERROR_FIXED = "AUTH_ERROR_FIXED";

export const LOGIN_TYPES = {
    B2C: "B2C",
    AD: "AD",
    DEFAULT: "AD",
}

export const REQUEST_STATUS = {
    IDLE: 'Idle',
    PENDING: "Pending",
    ERROR: "Error",
}

/*
CHATBOT TYPES
*/
export const OPEN_CHATBOT = "OPEN_CHATBOT";
export const CLOSE_CHATBOT = "CLOSE_CHATBOT";
export const RESET_CHATBOT = "RESET_CHATBOT";

export const API_HOST = () => {
    return window.__RUNTIME_CONFIG__.REACT_HOST_API;
};

export const HEADERS = {
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
        Accept: "application/json",
    },
}

export const DocumentTypes = {
    PDS: 'Product Data Sheet',
    SDS: 'Safety Data Sheet',
    RIS: 'Regulatory Information Sheet',
    ERI: 'Emergency Response Information',
    MARKETING: 'Marketing Material',
    CERTIFICATES: 'Certificates'
}

export const sessionStorageRedirectKey = 'redirect_after_login'

