import React from 'react';
import PropTypes from 'prop-types';

const TextInputField = ({name, value, defaultValue, id, onChange, onKeyDown, label, error, disabled}) => {
    return (
        <div className='flex flex-col text-sm'>
            {label &&
            <label className='mb-1 font-bold' htmlFor={id}>{label}</label>}
            {error &&
            <span className='text-red-600'>{error}</span>
            }
            <input 
                name={name}
                className={`border ${error !== null ? 'border-red-600' : 'border-gray-400 hover:border-cyan'} rounded p-3 outline-none`}
                id={id} 
                type="text" 
                value={value} 
                onChange={onChange}
                onKeyDown={onKeyDown} 
                disabled={disabled} />
        </div>
        
    );
}

TextInputField.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    label: PropTypes.string,
    error: PropTypes.string,
    disabled: PropTypes.bool,
}

export default TextInputField;