import React from 'react'
import {ReactComponent as CloseIcon} from '../../assets/icons/Icon_Close.svg'
import { IoReload as ReloadIcon } from 'react-icons/io5'

const ChatbotHeader = (props) => {

    return(
        <div className='flex justify-between w-full p-4 text-white bg-cyan'>
            <span>{props.headerTitle}</span>
            <div className='flex items-center gap-4'>
                <ReloadIcon className='cursor-pointer' title='Reset chatbot' size={20} onClick={props.onReset}/>
                <CloseIcon className='cursor-pointer' title='Close' onClick={props.onClose} />
            </div>
        </div>
    )
}

export default ChatbotHeader