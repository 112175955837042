import React from 'react';
import {ReactComponent as IconSearch} from '../../assets/icons/Icon_Search.svg'

const SearchBar = ({value, onChange, onSubmit, placeholder='Input search', secondVariant = false, autoFocus = false}) => {

    function handleKeyDown(e) {
        if (e.key === "Enter" && onSubmit) {
            onSubmit()
        }
    }

    return (  
        <div className={`flex w-full border ${secondVariant ? 'border-gray-600' : 'border-gray-200'} rounded flex-nowrap`}>
            <input
                className={`flex-grow px-3 py-2 ${secondVariant ? 'bg-transparent text-gray-600' : ''}`}
                type="text" 
                value={value ?? ''}
                placeholder={placeholder}
                onChange={(e) => {onChange(e.target.value)}}
                onKeyDown={handleKeyDown}
                autoFocus={autoFocus}
            />
            <button className={`p-2 ${secondVariant ? 'bg-transparent text-cyan' : 'bg-gray-200 text-gray'}`} onClick={onSubmit}>
                <IconSearch className='w-6 lg:w-5' />
            </button>
        </div>
    );
}
 
export default SearchBar;