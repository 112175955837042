import { useTour } from '@reactour/tour';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { closeChatbot } from '../../../../store/actions/chatbot';

const StartTour = (props) => {

    const {setIsOpen, setCurrentStep, setDisabledActions} = useTour();
    const dispatch = useDispatch()

    function startTour() {
        setDisabledActions(false);
        setCurrentStep(0);
        setIsOpen(true);
    }

    useEffect(() => {startTour(); dispatch(closeChatbot())}, [])

    return (
        <span>starting tour</span>
    )
}

export default StartTour;