import React, { useEffect, useState } from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {ReactComponent as SearchIcon} from "../../assets/icons/Icon_Search.svg";
import PropTypes from 'prop-types'
import MaterialNumbersList from "../elements/MaterialNumbersList";
import useProductSearchSuggestions from "../../hooks/useProductSearchSuggestions";

const ProductSearchBar = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const value = props.searchTerm;
    const [suggestionsOpened, setSuggestionsOpened] = useState(false);
    const [inFocus, setInFocus] = useState(false);

    const {suggestions, suggestionsCount} = useProductSearchSuggestions(value, () => {if(inFocus){setSuggestionsOpened(true)}}, () => setSuggestionsOpened(false))

    function onInput(event) {
        props.onSearchInput(event.target.value);
    }
    function handleKeyDown(event) {
        if (event.key === "Enter") {
            submit();
        }
        if (event.key === "ArrowDown") {
            
        }
    };
    function submit() {
        props.onSearchSubmit();
        setSuggestionsOpened(false);
    };
    function clearInput() {
        props.onSearchInput('');
    };

    function closeSuggestions() {
        setSuggestionsOpened(false);
    }

    useEffect(() => {
        if(suggestionsOpened) {
            window.addEventListener('click', closeSuggestions);
        } 
        else {
            window.removeEventListener('click', closeSuggestions);
        }
        return () => {window.removeEventListener('click', closeSuggestions)}
    }, [suggestionsOpened])

    return (
        <div className="relative w-full searchBar">
            <div className="flex flex-nowrap">
                <div 
                    className={`inputField
                        ${props.secondaryVariant ? 'border-l border-y border-gray-600' : 'border border-gray-200'} 
                        ${suggestionsOpened ? 'rounded-tl-md border-b-white after:w-[99%] after:mx-1 after:content-[""] after:absolute after:bottom-[-1px] after:left-0 after:border-b after:border-b-gray-200' : 'rounded-l-md'} 
                        p-1 relative flex-grow bg-transparent`}
                >
                    <input 
                        className={`flex-grow w-full h-full px-1 bg-transparent outline-none ${props.secondaryVariant ? 'text-sm text-gray-600' : ''}`}
                        type="text" 
                        onChange={onInput} 
                        onKeyDown={handleKeyDown} 
                        value={value} 
                        placeholder={props.placeholder ?? 'Search by product name or material number'} 
                        onFocus={() => setInFocus(true)}
                        onBlur={() => setInFocus(false)}
                    />
                    {value &&
                    <div 
                        className="absolute top-0 flex flex-col justify-center h-full text-xs font-medium text-gray-800 cursor-pointer clearSearch right-2" 
                        onClick={clearInput}>
                            <span>Clear</span>
                    </div>
                    }
                </div>
                <div className={`searchButton ${props.secondaryVariant ? ' text-cyan border-y border-r border-gray-600' : 'bg-blue text-white'} px-3 ${suggestionsOpened ? 'rounded-tr-md' : 'rounded-r-md'} flex cursor-pointer`} onClick={submit}>
                    <div className='text-cyan'><SearchIcon className="h-full py-2"/></div>
                    {!props.secondaryVariant && <div className="h-full p-2 text-base max-lg:hidden">Search</div>}
                </div>
            </div>
            {!props.secondaryVariant &&
            <div className="py-2 text-sm text-gray">
                Use * as wildcard character for searching products by name.
            </div>
            }
            {(suggestionsOpened && suggestions.length > 0) &&
            <div className="absolute w-full px-2 py-3 bg-white border border-t-0 border-gray-200 rounded-b shadow suggestions">
                {suggestions.map(suggestion => {return (
                    <div 
                        key={suggestion.id} 
                        className="grid grid-cols-5 px-2 py-1 rounded cursor-pointer text-gray hover:bg-gray-50" 
                        onClick={() => {clearInput(); navigate("/products/" + suggestion.slug , {state: {backLink: location}})}}
                    >
                        <div className="col-span-2">{suggestion.name}</div>
                        <div className="col-span-3">
                            <MaterialNumbersList list={suggestion.materials} maxElements={4} highlighted={value} />
                        </div>
                    </div>
                )})}
                {suggestions.length > 1 &&
                <div className="px-2 py-1 text-center rounded cursor-pointer bg-cyan-50 hover:bg-cyan-100 text-cyan" onClick={submit}>
                    Show all {suggestionsCount} results
                </div>}
            </div>}
        </div>
    )
}

ProductSearchBar.propTypes = {
    searchTerm: PropTypes.string.isRequired,
    onSearchInput: PropTypes.func.isRequired,
    onSearchSubmit: PropTypes.func.isRequired,
    secondaryVariant: PropTypes.bool,
    placeholder: PropTypes.string,
}

export default ProductSearchBar;