import { useTour } from '@reactour/tour';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import useLogout from '../../hooks/useLogout';
import MobileNavListItem from '../elements/styleWrapper/MobileNavListItem';

const ProfileNavigationList = () => {

    const { setIsOpen, setCurrentStep, setDisabledActions } = useTour();
    const logout = useLogout()
    const location = useLocation()

    function startTour() {
        setDisabledActions(false);
        setCurrentStep(0);
        setIsOpen(true);
    }

    return (
        <ul className='flex flex-col px-6 py-20 max-lg:gap-6 lg:p-2 lg:text-sm lg:bg-white lg:rounded lg:text-gray lg:shadow-card'>
            <MobileNavListItem>
                <div className='cursor-pointer lg:px-2 lg:py-1 lg:rounded lg:hover:bg-gray-50 lg:hover:text-blue'>
                    <p onClick={startTour}>Start tour</p>
                </div>
            </MobileNavListItem>
            <MobileNavListItem>
                <div className='lg:px-2 lg:py-1 lg:rounded lg:hover:bg-gray-50 lg:hover:text-blue'>
                    <Link to='/profile' state={{ backLink: location }}>Your Profile</Link>
                </div>
            </MobileNavListItem>
            <MobileNavListItem>
                <div className='text-red-600 lg:px-2 lg:py-1 lg:rounded lg:hover:bg-gray-50'>
                    <button type="link" onClick={logout}>Log out</button>
                </div>
            </MobileNavListItem>
        </ul>
    )
}

export default ProfileNavigationList;