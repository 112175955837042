import { useMemo } from "react"
import { useSelector } from "react-redux"
import { basketSelectors } from "../store/slices/basket/basket"

const useGroupedBasketCollection = () => {
    const products = useSelector(state => basketSelectors.products.selectAll(state))
    const pdsEntities =  useSelector(state => basketSelectors.pds.selectEntities(state))
    const sdsEntities =  useSelector(state => basketSelectors.sds.selectEntities(state))
    const risEntities =  useSelector(state => basketSelectors.ris.selectEntities(state))
    const eriEntities =  useSelector(state => basketSelectors.eri.selectEntities(state))
    const marketing = useSelector(state => basketSelectors.marketing.selectAll(state))
    const certificates = useSelector(state => basketSelectors.certificates.selectAll(state))

    const datasheets = useMemo(() => {
        return products.length > 0 ?
        products.map(product => {
            const pds = product.pds.map(id => pdsEntities[id])
            const sds = product.sds.map(id => sdsEntities[id])
            const ris = product.ris.map(id => risEntities[id])
            const eri = product.eri.map(id => eriEntities[id])
            return {
                product: product,
                pds: pds,
                sds: sds,
                ris: ris,
                eri: eri,
            }
        })
        :
        []
    }
    , [eriEntities, pdsEntities, products, risEntities, sdsEntities])

    return {datasheets, marketing, certificates}
}

export default useGroupedBasketCollection