import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { api } from '../../../../api/api';
import RequestStatus from '../../../../components/elements/RequestStatus';
import { set_user } from '../../../../store/actions/auth';
import { REQUEST_STATUS } from '../../../../store/types';

const SaveUserDataConfirm = (props) => {
    const [user] = useState(props.steps[props.userValueStep].value)
    const [selection, setSelection] = useState(null);
    const [submitRequestStatus, setSubmitRequestStatus] = useState(REQUEST_STATUS.IDLE)
    const dispatch = useDispatch();
    function saveData() {
        setSubmitRequestStatus(REQUEST_STATUS.PENDING);
        api.updateUserData({telephone_number: user.customer_phone, organization: user.customer_organization, job_title: user.customer_job_title})
            .then(response => {
                dispatch(set_user(response.data));
                setSubmitRequestStatus(REQUEST_STATUS.IDLE);
                props.triggerNextStep({value: 'saved', trigger: props.trigger}); 
                setSelection('saved')

            })
            .catch(error => {
                setSubmitRequestStatus(REQUEST_STATUS.ERROR);
                console.log(error);
            })
    }
    return(
        <div>
            <div className='mb-2'>
                <p>You changed your contact data to:</p>
                <div>
                    <p className='font-semibold'>Telephone:</p>
                    <p>{user.customer_phone}</p>
                </div>
                <div>
                    <p className='font-semibold'>Organization:</p>
                    <p>{user.customer_organization}</p>
                </div>
                <div>
                    <p className='font-semibold'>Job title:</p>
                    <p>{user.customer_job_title}</p>
                </div>
            </div>
            <div className='flex flex-col gap-1'>
                {submitRequestStatus !== REQUEST_STATUS.IDLE &&
                    <RequestStatus status={submitRequestStatus} />
                }
                <button 
                    className={`rounded-lg border-white border py-1 font-semibold ${selection === 'saved' ? 'bg-white text-cyan' : ''}`} 
                    onClick={saveData}
                    disabled={(selection != null)}
                >
                        Save
                </button>
                <button 
                    className={`rounded-lg border-white border py-1 font-semibold ${selection === 'notSaved' ? 'bg-white text-cyan' : ''}`} 
                    onClick={() => {props.triggerNextStep({value: 'notSaved', trigger: props.trigger}); setSelection('notSaved')}}
                    disabled={(selection != null)}
                >
                        Don't save
                </button>
            </div>
        </div>
    )
}

export default SaveUserDataConfirm;