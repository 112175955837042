import React from 'react';
import { useSelector } from 'react-redux';
import EmptyBasketCard from '../../components/basket/EmptyBasketCard';
import ProductDatasheetsCollection from '../../components/basket/ProductDatasheetsCollection';
import { basketSelectors } from '../../store/slices/basket/basket';

const DatasheetsBasketList = ({reduced}) => {

    const products = useSelector(state => basketSelectors.products.selectAll(state))

    return ( 
        <div className={`flex flex-col ${reduced ? 'gap-6' : 'gap-11'} w-full`}>
            {products.map(product => <ProductDatasheetsCollection key={product.id} product={product} reduced={reduced} />)}
            {(!products || products.length === 0) &&
                <EmptyBasketCard title='There are no datasheets in your basket' oneLine/>
            }
        </div>
     );
}
 
export default DatasheetsBasketList;