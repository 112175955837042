import React from 'react';
import { useCallback } from 'react';
import SecondaryButton from './SecondaryButton';
import { ReactComponent as IconBasket } from '../../assets/icons/Icon_Basket.svg';
import { ReactComponent as IconTrash } from '../../assets/icons/Icon_Trash.svg';
import { useDispatch, useSelector } from 'react-redux';
import { addDocumentToBasket, removeDocumentFromBasket } from '../../store/slices/basket/basket';
import { removeDocumentFromSelection } from '../../store/slices/basket-selection/basketSelection';
import useDatasheetSelector from '../../hooks/useDatasheetSelector';

const CollectDocumentButton = ({document, product, documentType}) => {

    const dispatch = useDispatch()
    const datasheetSelector = useDatasheetSelector(documentType)
    const isCollected = useSelector(state => !!datasheetSelector?.selectById(state, document?.id))
    const collectDatasheet = useCallback(() => {dispatch(addDocumentToBasket(product, documentType, document))}, [dispatch, document, documentType, product])
    const removeDatasheetFromBasket = useCallback(() => {
        dispatch(removeDocumentFromBasket(product?.id, documentType, document?.id))
        dispatch(removeDocumentFromSelection(product?.id, documentType, document?.id))
    }, [dispatch, document?.id, documentType, product?.id])


    return (  
        <>
        {isCollected ? 
            <SecondaryButton onClick={removeDatasheetFromBasket}>
                <div className='flex items-center gap-1 flex-nowrap'>
                    <IconTrash className='w-4' />
                    <p className='hidden lg:block'>Remove</p>
                </div>
                
            </SecondaryButton>
            :
            <SecondaryButton cyan onClick={collectDatasheet}>
                <div className='flex items-center gap-1 flex-nowrap'>
                    <IconBasket className='w-4' />
                    <p className='hidden lg:block'>Collect</p>
                </div>
            </SecondaryButton>
        }
        </>
    );
}
 
export default CollectDocumentButton;