import { useCallback, useState } from "react";
import { api } from "../api/api";
import { DocumentTypes, REQUEST_STATUS } from "../store/types";
import GA4Helper from "../utilities/GA4Helper";

const usePreloadDocuments = () => {
    const [downloadRequestStatus, setDownloadRequestStatus] = useState(REQUEST_STATUS.IDLE);

    const provideDownloadedDocument = useCallback((documentData, documentName) => {
        const blob = new Blob([documentData]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", documentName); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
    }, [])

    function provideDocumentDownload(link, documentName) {
        setDownloadRequestStatus(REQUEST_STATUS.PENDING);
        api.getDocument(link).then(response => {
            provideDownloadedDocument(response.data, documentName)
            setDownloadRequestStatus(REQUEST_STATUS.IDLE);
        }).catch(error => {
            setDownloadRequestStatus(REQUEST_STATUS.ERROR);
            console.log(error);
        })
    }
    function provideDatasheetDownload(link, type, name, language, country) {
        const isType = (docType) => type === Object.keys(DocumentTypes).find(key => DocumentTypes[key] === docType)
        GA4Helper.downloadEvent(isType(DocumentTypes.PDS) ? 1 : 0, isType(DocumentTypes.SDS) ? 1 : 0, isType(DocumentTypes.RIS) ? 1 : 0, isType(DocumentTypes.ERI) ? 1 : 0, 0, 0)
        const documentName = `${type.toUpperCase()}_${name}_${language}_${country}.pdf`;
        provideDocumentDownload(link, documentName);
    }
    function provideMarketingMaterialDownload(link, name) {
        GA4Helper.downloadEvent(0, 0, 0, 0, 1, 0)
        const documentName = `${name}.pdf`;
        provideDocumentDownload(link, documentName, );
    }
    function provideCertificatesDownload(link, name) {
        GA4Helper.downloadEvent(0, 0, 0, 0, 0, 1)
        const documentName = `${name}.pdf`;
        provideDocumentDownload(link, documentName);
    }
    function provideCustomerProductsDownload(customerId, customerName) {
        setDownloadRequestStatus(REQUEST_STATUS.PENDING)
        const documentName = `${customerName ?? 'customers_products'}.xlsx`;
        api.getCustomerProductsData(customerId)
        .then(res => {
            provideDownloadedDocument(res.data, documentName)
            setDownloadRequestStatus(REQUEST_STATUS.IDLE);
        })
        .catch(err => {
            setDownloadRequestStatus(REQUEST_STATUS.ERROR);
            console.log(err);
        })
    }

    return {downloadRequestStatus, provideDatasheetDownload, provideMarketingMaterialDownload, provideCertificatesDownload, provideCustomerProductsDownload}
}
export default usePreloadDocuments;