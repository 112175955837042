import ContactSafetySummary from "../customMessages/contact-safety/ContactSafetySummary";
import CurrentProductCheck from "../customMessages/contact-safety/CurrentProductCheck";
import MessageHeader from "../customMessages/contact-safety/MessageHeader";
import ProductSelection from "../customMessages/contact-safety/ProductSelection";
import SubmitSafetyMessage from "../customMessages/contact-safety/SubmitSafetyMessage";
import { requiredNotBlank } from "../validators/validators";
import getSasolRepresentativeSteps from "./reusable-steps/sasol-representativ-data";
import getUserSteps from "./reusable-steps/user-data";

function getContactSafetySteps(stepAfterFinish){
    const prefix = 'cs_'
    const firstStepId = prefix + 'current_product_check';
    const userSteps = getUserSteps(prefix + 'summary', prefix);
    const repSteps = getSasolRepresentativeSteps(userSteps.firstStepId, prefix);
    const steps = [
        {
            id: firstStepId,
            component: <CurrentProductCheck triggerTrue={prefix + 'current_product_question'} triggerFalse={prefix + 'product_question'} />,
            waitAction: true,
            replace: true,
        },
        {
            id: prefix + 'current_product_question',
            message: "Is your question related to the product you're currently looking at?",
            trigger: prefix + 'current_product_options',
        },
        {
            id: prefix + 'current_product_options',
            options: [
                { value: true, label: 'Yes', trigger: prefix + 'product' },
                { value: false, label: 'No', trigger: prefix + 'product_question' },
            ],
        },
        {
            id: prefix + 'product_question',
            message: "Wich product does your question relate to?",
            trigger: prefix + 'product_input',
        },
        {
            id: prefix + 'product_input',
            user: true,
            validator: requiredNotBlank,
            trigger: prefix + 'product',
        },
        {
            id: prefix + 'product',
            component: <ProductSelection currentProductStep={firstStepId} productInputStep={prefix + 'product_input'} trigger={prefix + 'material_number_question'} />,
            replace: true,
            waitAction: true,
        },
        {
            id: prefix + 'material_number_question',
            message: "Which material number does your question relate to? If you don't want to specify it, just press enter.",
            trigger: prefix + 'material_number_input',
        },
        {
            id: prefix + 'material_number_input',
            user: true,
            trigger: prefix + 'category_question',
        },
        {
            id: prefix + 'category_question',
            message: "Which category does your question relate to the most?",
            trigger: prefix + 'category_options',
        },
        {   id: prefix + 'category_options',
            options: [
              { value: 'Toxicology', label: 'Toxicology', trigger: prefix + 'message_question' },
              { value: 'Ecotoxicology and Biodegradation', label: 'Ecotoxicology and Biodegradation', trigger: prefix + 'message_question' },
              { value: 'Hazard Communication (SDS)', label: 'Hazard Communication (SDS)', trigger: prefix + 'message_question' },
              { value: 'Regulatory Affairs', label: 'Regulatory Affairs', trigger: prefix + 'message_question' },
              { value: 'Others', label: 'Others', trigger: prefix + 'message_question' },
            ],
          },
        {
            id: prefix + 'message_question',
            component: <MessageHeader categoryValueStep={prefix + 'category_options'} productValueStep={prefix + 'product'} materialValueStep={prefix + 'material_number_input'} />,
            asMessage: true,
            trigger: prefix + 'message_input',
        },
        {
            id: prefix + 'message_input',
            user: true,
            trigger: repSteps.firstStepId,
        },
        {
            id: prefix + 'summary',
            component: <ContactSafetySummary messageStep={prefix  + 'message_input'} categoryStep={prefix + 'category_options'} productStep={prefix + 'product'} materialStep={prefix + 'material_number_input'} repStep={repSteps.dataStepId} />,
            asMessage: true,
            trigger: prefix + 'submit',
        },
        {
            id: prefix + 'submit',
            component: <SubmitSafetyMessage 
                            messageStep={prefix + 'message_input'} 
                            repStep={repSteps.dataStepId} 
                            userStep={userSteps.dataStepId} 
                            productStep={prefix + 'product'}
                            materialStep={prefix + 'material_number_input'}
                            categoryStep={prefix + 'category_options'}
                            triggerOnSuccess={prefix + 'submit_success'}
                            triggerOnError={prefix + 'submit_error'}
                            triggerOnCancel={stepAfterFinish}
                        />,
            waitAction: true,
            replace: true,
        },
        {
            id: prefix + 'submit_error',
            message: 'Message could\'t be send. Please try again',
            trigger: prefix + 'submit',
        },
        {
            id: prefix + 'submit_success',
            message: 'Your message was sent',
            trigger: stepAfterFinish,
        },
      ]
    return {
        firstStepId: firstStepId,
        steps: steps.concat(repSteps.steps).concat(userSteps.steps)
    }
}
export default getContactSafetySteps;
