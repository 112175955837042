import React from 'react';
import SecondaryButton from '../elements/SecondaryButton';
import {ReactComponent as IconTrash} from '../../assets/icons/Icon_Trash.svg'
import DatasheetDisclosure from './DatasheetDisclosure';
import { DocumentTypes } from '../../store/types';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { removeProduct } from '../../store/slices/basket/basket';
import { removeProduct as removeProductFromSelection } from '../../store/slices/basket-selection/basketSelection';
import { Link, useLocation } from 'react-router-dom'
import { useMemo } from 'react';

const ProductDatasheetsCollection = ({product, reduced = false}) => {

    const dispatch = useDispatch()
    const location  = useLocation()

    const removeProductFromBasket = useCallback(() => {
        dispatch(removeProduct(product.id))
        dispatch(removeProductFromSelection(product.id))
    }, [dispatch, product])
    const count = useMemo(() => ((product.pds?.length || 0) + (product.sds?.length || 0) + (product.ris?.length || 0) + (product.eri?.length || 0)), [product])
    
    return (  
        <div className='w-full'>
            <div className='flex items-center justify-between mb-4'>
                <div className='flex items-center gap-1'>
                    <Link to={`/products/${product.slug}`} state={{backLink: location}} className='text-lg font-semibold'>{product.name}</Link>
                    {!reduced && <p className='text-white px-[0.375rem] bg-blue rounded text-sm h-fit'>{count}</p>}
                </div>
                {!reduced &&
                <SecondaryButton onClick={removeProductFromBasket}>
                    <div className='flex items-center gap-1'>
                        <IconTrash className='w-4' />
                        <span className='whitespace-nowrap max-lg:hidden'>Remove Product</span>
                    </div>
                </SecondaryButton>}
            </div>
            <div className={reduced ? 'bg-gray-200 flex flex-col gap-px relative' : 'relative'}>
            {product.pds && product.pds.length > 0 && 
                <div className='z-[3] relative'><DatasheetDisclosure datasheetIds={product.pds} product={product} reduced={reduced} datasheetType={DocumentTypes.PDS} /></div>
            }
            {product.sds && product.sds.length > 0 &&
                <div className='z-[2] relative'><DatasheetDisclosure datasheetIds={product.sds} product={product} reduced={reduced} datasheetType={DocumentTypes.SDS} /></div>
            }
            {product.ris && product.ris.length > 0 &&
                <div className='z-[1] relative'><DatasheetDisclosure datasheetIds={product.ris} product={product} reduced={reduced} datasheetType={DocumentTypes.RIS} /></div>
            }
            {product.eri && product.eri.length > 0 &&
                <div className='z-[1] relative'><DatasheetDisclosure datasheetIds={product.eri} product={product} reduced={reduced} datasheetType={DocumentTypes.ERI} /></div>
            }
            </div>
        </div>
    );
}
 
export default ProductDatasheetsCollection;