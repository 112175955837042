import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const UserDataConfirm = (props) => {
    const user = useSelector((state) => state.auth.user);
    const {steps} = props;
    const userFormData = {
        customer_name: steps[props.nameInputStep]?.value ?? user?.name,
        customer_email: steps[props.mailInputStep]?.value ?? user?.email,
        customer_phone: steps[props.phoneInputStep]?.value ?? user?.telephone_number,
        customer_organization: steps[props.organizationInputStep]?.value ?? user?.organization,
        customer_job_title: steps[props.jobInputStep]?.value ?? user?.job_title,
    }
    const [userData] = useState(userFormData);
    const [selection, setSelection] = useState(null);
    function userDataHasChanged() {
        let hasChanged = false;
        if(user){
            if(steps[props.phoneInputStep]?.value && user.telephone_number !== steps[props.phoneInputStep]?.value) {
                hasChanged = true;
            }
            else if(steps[props.organizationInputStep]?.value && user.organization !== steps[props.organizationInputStep]?.value) {
                hasChanged = true;
            }
            else if(steps[props.jobInputStep]?.value && user.job_title !== steps[props.jobInputStep]?.value) {
                hasChanged = true;
            }
        }
        return hasChanged;
    }
    return(
        <div>
            <div className='mb-2'>
                <p>Your user data is:</p>
                <div>
                    <p className='font-semibold'>Full name:</p>
                    <p>{userData.customer_name}</p>
                </div>
                <div>
                    <p className='font-semibold'>Email:</p>
                    <p>{userData.customer_email}</p>
                </div>
                <div>
                    <p className='font-semibold'>Telephone:</p>
                    <p>{userData.customer_phone}</p>
                </div>
                <div>
                    <p className='font-semibold'>Organization:</p>
                    <p>{userData.customer_organization}</p>
                </div>
                <div>
                    <p className='font-semibold'>Job title:</p>
                    <p>{userData.customer_job_title}</p>
                </div>
            </div>
            <div className='flex flex-col gap-1'>
                <button 
                    className={`rounded-lg border-white border py-1 font-semibold ${selection === 'correct' ? 'bg-white text-cyan' : ''}`} 
                    onClick={() => {props.triggerNextStep({value: userData, trigger: userDataHasChanged() ? props.triggerOnDataChanged : props.trigger}); setSelection('correct')}}
                    disabled={(selection != null)}
                >
                        Correct
                </button>
                <button 
                    className={`rounded-lg border-white border py-1 font-semibold ${selection === 'not_correct' ? 'bg-white text-cyan' : ''}`} 
                    onClick={() => {props.triggerNextStep({value: userData, trigger: props.triggerOnCancel}); setSelection('not_correct')}}
                    disabled={(selection != null)}
                >
                        Not correct
                </button>
            </div>
        </div>
    )
}

export default UserDataConfirm;