import { Listbox } from '@headlessui/react'
import React, { useMemo } from 'react'
import RequestStatus from '../../components/elements/RequestStatus'
import { REQUEST_STATUS } from '../../store/types'
import {ReactComponent as IconChevronDown} from '../../assets/icons/Icon_Chevron-Down.svg'
import { sasolDefaultSelectionValue } from '../../types' 

const defaultSelection = sasolDefaultSelectionValue

const SelectPdsPreferences = ({selection, selectionInput, onSelectionInput, selectionStatus, options, optionsAreLoading}) => {

    const mappedSelectionValue = useMemo(() => {
        if(selection){
            const index = options.findIndex(entry => entry.value === selection)
            if(index >= 0){
                return options[index]
            }    
        }
        else {
           return defaultSelection
        }
    }, [options, selection])

    const displayedSelection = useMemo(() => {
        return selectionInput ? selectionInput : mappedSelectionValue
    }, [mappedSelectionValue, selectionInput])

    return (
        <div className='flex flex-col gap-4 lg:flex-row'>
            <div className='relative mb-2 lg:basis-1/2 max-lg:flex-grow'>
                <Listbox value={displayedSelection} onChange={onSelectionInput} disabled={optionsAreLoading || selectionStatus !== REQUEST_STATUS.IDLE}>
                    <Listbox.Label className='mb-1 text-sm font-semibold'>Language for Product Data Sheet</Listbox.Label>
                    <Listbox.Button className='flex justify-between w-full p-2 border border-gray-400 rounded text-start hover:border-cyan'>
                        <span>{displayedSelection?.label ?? 'Selection not found'}</span>
                        {optionsAreLoading ? 
                            <RequestStatus status={REQUEST_STATUS.PENDING}/>
                        :
                            <IconChevronDown className='transition-transform ui-open:rotate-180' />
                        }
                    </Listbox.Button>
                    <Listbox.Options className='absolute z-10 w-full py-2 mt-1 overflow-auto bg-white rounded max-h-40 shadow-card customScrollbarsLight'>
                        <Listbox.Option 
                            key={'no_selection'} 
                            value={defaultSelection} 
                            className='px-2 py-1 cursor-pointer text-gray hover:bg-gray-50'
                        >
                            Use Sasol default
                        </Listbox.Option>
                        {options.map(option => (
                            <Listbox.Option key={option.value} value={option} className='px-2 py-1 cursor-pointer text-gray hover:bg-gray-50'>
                                {option.label}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </Listbox>
            </div>
            <div className='lg:basis-1/2' />
        </div>
    )
}

export default SelectPdsPreferences