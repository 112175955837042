import React, { useState } from 'react';
import PrimaryButton from '../../elements/PrimaryButton';
import {ReactComponent as IconClose} from "../../../assets/icons/Icon_Close.svg"
import TextInputField from '../../elements/TextInputField';
import RequestStatus from '../../elements/RequestStatus';
import { REQUEST_STATUS } from '../../../store/types';
import {BsCheck2Circle as CheckIcon} from 'react-icons/bs'
import CloseButton from '../../elements/CloseButton';
import SharedDocumentsList from './SharedDocumentsList';
import useDocumentSharing from '../../../hooks/useDocumentSharing';
import { useMemo } from 'react';
import Checkbox from '../../elements/Checkbox';

const ShareForm = (
    {
        datasheets = [], 
        removePds, removeSds, removeRis, removeEri,
        marketing = [], removeMarketing,
        certificates = [], removeCertificate,
        onClose
    }
) => {

    const documentsToShare = useMemo(() => {
        const pds = []
        const sds = []
        const ris = []
        const eri = []
        datasheets.forEach(product => {
            pds.push(...product.pds)
            sds.push(...product.sds)
            ris.push(...product.ris)
            eri.push(...product.eri)
        })
        return {pds: pds, sds: sds, ris: ris, eri: eri, marketing: marketing ?? [], certificates: certificates ?? []}
    }, [datasheets, marketing, certificates])

    function closeAfterSharing() {
        setTimeout(onClose, 1200)
    }

    const {
        mailList,
        addToMailList, 
        removeFromMailList, 
        shareDocuments, 
        mailText,
        setMailText, 
        mailSubject,
        setMailSubject, 
        sharingStatus, 
        sharingSuccessMessage, 
        sharingError,
        sendingCC,
        setSendingCC,
    } = useDocumentSharing(documentsToShare, closeAfterSharing, null)

    const [mailInput, setMailInput] = useState('');
    function addMail() {
        if(mailInput.length > 0)
        addToMailList(mailInput);
        setMailInput('');
    }
    return (
        <div className='relative bg-white m-auto lg:w-[60vw] w-[95vw] p-6 pr-0 py- rounded mt-5 shadow-card'>
            <div className='flex items-center justify-between gap-4 pr-6 mb-3 text-blue'>
                <h2 className='text-2xl font-semibold'>Share these Documents via Email</h2>
                <CloseButton onClose={onClose} />
            </div>
            <div className='flex flex-col gap-2 pr-4 overflow-y-scroll bg-white rounded text-blue max-h-[80vh] lg:max-h-[60vh] customScrollbarsLight pb-6'>
                <div>
                    <div className='flex flex-col mb-2 text-sm'>
                        <TextInputField label='Email*' id='emailInput' value={mailInput} onChange={(e) => setMailInput(e.target.value)} onKeyDown={(e) => {if(e.key === 'Enter'){addMail()}}} error={null} />
                    </div>
                    <p className='text-sm text-gray'>Insert an email address and press enter </p>
                    {mailList.length > 0 &&
                        <div className='flex gap-1 py-2'>
                            {mailList.map(mail => 
                            <div key={mail} className="bg-gray-600 text-white flex items-center gap-[0.125rem] px-[0.375rem] rounded-sm">
                                <p className='text-sm' >{mail}</p>
                                <IconClose className="w-3 cursor-pointer" onClick={() => {removeFromMailList(mail)}}/>
                            </div>
                            )}   
                        </div>
                    }
                    {sharingError &&
                    <div className='text-red-600 text-sm font-bold py-4 mx-auto w-[90%] text-center'>Something went wrong! Please check the email addresses</div>
                    }
                </div>
                <div className='flex items-center gap-2'>
                    <Checkbox topMargin='top-[6px]' checked={sendingCC} onChange={() => setSendingCC(!sendingCC)} />
                    <label className='text-sm font-semibold'>Send a copy to myself in cc</label>
                </div>
                <TextInputField label='Subject' id='subject' value={mailSubject} onChange={(e) => setMailSubject(e.target.value)} error={null} />
                <div className='flex flex-col'>
                    <label className='mb-1 text-sm font-semibold ' htmlFor="mailText">Message*</label>
                    <textarea 
                        id="mailText" 
                        className='p-3 border border-gray-400 rounded customScrollbars'
                        cols="30" rows="5" 
                        value={mailText} 
                        onChange={(e) => {setMailText(e.target.value)}} />
                </div>
                <div className='max-lg:hidden'>
                    <SharedDocumentsList 
                        datasheets={datasheets} 
                        removePds={removePds} 
                        removeSds={removeSds} 
                        removeRis={removeRis}
                        removeEri={removeEri} 
                        marketing={marketing} 
                        removeMarketing={removeMarketing}
                        certificates={certificates}
                        removeCertificate={removeCertificate} 
                    />                    
                </div>
            </div>
            <div className='relative pt-3 pr-6'>
                <div  className='absolute w-full h-6 pr-6 bg-transparent max-lg:hidden -top-6 bg-gradient-to-b from-transparent to-white bg-clip-content' />
                <PrimaryButton color={mailList.length ? 'cyan' : 'cyan-100'} onClick={shareDocuments} title={sharingStatus !== REQUEST_STATUS.PENDING ? 'Share' : null} disabled={!mailList.length}>
                    <RequestStatus status={sharingStatus !== REQUEST_STATUS.ERROR ? sharingStatus : REQUEST_STATUS.IDLE} />
                </PrimaryButton>
            </div>
            {sharingSuccessMessage &&
            <div className='absolute top-0 right-0 flex flex-col items-center justify-center w-full h-full gap-4 transition-opacity ease-in-out border rounded text-blue bg-cyan-100 border-cyan'>
                <CheckIcon size={60}/>
                <p className='text-xl'>
                    {sharingSuccessMessage}
                </p>
            </div>
            }
        </div>
        
    );
}

export default ShareForm;