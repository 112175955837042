import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { logout as logoutRedux, stopCustomerView } from "../store/actions/auth";
import useCustomerViewActive from "./useCustomerViewActive";

const useLogout = () => {

    const customerViewActive = useCustomerViewActive()
    const [isAwaitingLogout, setIsAwaitingLogout] = useState(false);

    const dispatch = useDispatch()
    const {instance} = useMsal()


    const logout = () => {
        setIsAwaitingLogout(true)
        dispatch(logoutRedux())
        if(customerViewActive) {
            dispatch(stopCustomerView())
        }
        //TODO Remove user data from redux
    }

    useEffect(() => {
        if(isAwaitingLogout && !customerViewActive) {
            setIsAwaitingLogout(false)
            instance.logoutRedirect();
        }
    }, [customerViewActive, isAwaitingLogout]);

    return logout
}

export default useLogout