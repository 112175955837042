import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../api/api';
import { set_user } from '../../store/actions/auth';
import SelectPdsPreferences from './SelectPdsPreferences';
import SelectSdsPreferences from './SelectSdsPreferences';
import { REQUEST_STATUS } from '../../store/types';
import PrimaryButton from '../../components/elements/PrimaryButton'
import SecondaryButton from '../../components/elements/SecondaryButton'
import RequestStatus from '../../components/elements/RequestStatus';
import { sasolDefaultSelectionValue } from '../../types';

const DefaultDocumentsSettings = () => {
    const user = useSelector((state) => state.auth.user);
    const dispatchRedux = useDispatch();    
    const [selectionStatus, setSelectionStatus] = useState(REQUEST_STATUS.IDLE)

    const [pdsLangOptions, setPdsLangOptions] = useState([]);
    const [pdsLangOptionsAreLoading, setPdsLangOptionsAreLoading] = useState(false);

    const [pds1LangInput, setPds1LangInput] = useState(null);

    const [sdsLangOptions, setSdsLangOptions] = useState([]);
    const [sdsCountryOptions, setSdsCountryOptions] = useState([]);
    const [sdsOptionsAreLoading, setSdsOptionsAreLoading] = useState(false);

    const [sds1LangInput, setSds1LangInput] = useState(null);
    const [sds1CountryInput, setSds1CountryInput] = useState(null);
    const [isShowingSDS1Disclaimer, setIsShowingSDS1Disclaimer] = useState(false);
    const sds1IsValidInput = useMemo(() => isValidLangCountryCombination(user.preferred_sds_language_1, sds1LangInput, user.preferred_sds_country_1, sds1CountryInput), [sds1CountryInput, sds1LangInput, user.preferred_sds_country_1, user.preferred_sds_language_1])

    const [sds2LangInput, setSds2LangInput] = useState(null);
    const [sds2CountryInput, setSds2CountryInput] = useState(null);
    const [isShowingSDS2Disclaimer, setIsShowingSDS2Disclaimer] = useState(false);
    const sds2IsValidInput = useMemo(() => isValidLangCountryCombination(user.preferred_sds_language_2, sds2LangInput, user.preferred_sds_country_2, sds2CountryInput), [sds2CountryInput, sds2LangInput, user.preferred_sds_country_2, user.preferred_sds_language_2])

    const hasUnsavedChanges = useMemo(() => 
        (pds1LangInput || sds1LangInput || sds1CountryInput || sds2CountryInput || sds2LangInput), 
        [pds1LangInput, sds1CountryInput, sds1LangInput, sds2CountryInput, sds2LangInput]
    )

    function isValidLangCountryCombination(storedLangValue, langInput, storedCountryValue, countryInput) {
        const langValue = langInput ? langInput.value : storedLangValue
        const countryValue = countryInput ? countryInput.value : storedCountryValue
        return (langValue && countryValue) || (!langValue && !countryValue)
    }

    function getPDSLangOptions() {
        setPdsLangOptionsAreLoading(true)
        api.getPDSLanguageOptions()
        .then(response => {
            setPdsLangOptions(response.data)
            setPdsLangOptionsAreLoading(false)
        })
        .catch(error => {
            console.log(error)
            setPdsLangOptionsAreLoading(false)
        })
    }

    function getSDSLangOptions() {
        api.getSDSLanguageOptions()
        .then(response => {
            setSdsLangOptions(response.data)
        })
        .catch(error => {
            console.log(error)
        })
    }

    function getSDSCountryOptions() {
        api.getSDSCountryOptions()
        .then(response => {
            setSdsCountryOptions(response.data)
        })
        .catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        getPDSLangOptions()
        setSdsOptionsAreLoading(true)
        getSDSLangOptions()
        getSDSCountryOptions()
    }, []);

    useEffect(() => {
        if(sdsOptionsAreLoading) {
            if(sdsLangOptions.length > 0 && sdsCountryOptions.length > 0) {
                setSdsOptionsAreLoading(false)
            }
        }
    }, [sdsCountryOptions.length, sdsLangOptions.length, sdsOptionsAreLoading]);

    const resetInput = useCallback(() => {
        setPds1LangInput(null)
        setSds1LangInput(null)
        setSds1CountryInput(null)
        setIsShowingSDS1Disclaimer(false)
        setSds2LangInput(null)
        setSds2CountryInput(null)
        setIsShowingSDS2Disclaimer(false)
    }, [])

    const savePreferences = useCallback((useResetValues = false) => () => {
        setIsShowingSDS1Disclaimer(false)
        setIsShowingSDS2Disclaimer(false)
        if((sds1IsValidInput && sds2IsValidInput) || useResetValues) {
            setSelectionStatus(REQUEST_STATUS.PENDING)
            const data = {
                preferred_pds_language_1: useResetValues ? null : pds1LangInput?.value,
                preferred_sds_country_1: useResetValues ? null : sds1CountryInput?.value,
                preferred_sds_language_1: useResetValues ? null : sds1LangInput?.value,
                preferred_sds_country_2: useResetValues ? null : sds2CountryInput?.value,
                preferred_sds_language_2: useResetValues ? null : sds2LangInput?.value,
            }
            api.updateUserData(data)
            .then(res => {
                dispatchRedux(set_user(res.data))
                setSelectionStatus(REQUEST_STATUS.IDLE)
                resetInput()
            })
            .catch(err => {
                console.log(err)
                setSelectionStatus(REQUEST_STATUS.ERROR)
                setTimeout(() => {selectionStatus(REQUEST_STATUS.IDLE); resetInput()}, 2000)
            })
        }
        else {
            if(!sds1IsValidInput) {
                setIsShowingSDS1Disclaimer(true)
            }
            if(!sds2IsValidInput) {
                setIsShowingSDS2Disclaimer(true)
            }
        }
    }, [dispatchRedux, pds1LangInput?.value, resetInput, sds1CountryInput?.value, sds1IsValidInput, sds1LangInput?.value, sds2CountryInput?.value, sds2IsValidInput, sds2LangInput?.value, selectionStatus])

    const handleReset = useCallback(() => {
        savePreferences(true)()
    }, [savePreferences])

    const isShowingSecondPref = useMemo(() => {
        // true if: ((both sds1Input === null) && (both user.sds1 !== null)) || 
        return ((sds1LangInput === null && sds1CountryInput === null) && (user.preferred_sds_language_1 !== null && user.preferred_sds_country_1 !== null)) || (sds1CountryInput !== null && sds1LangInput !== null && sds1CountryInput !== sasolDefaultSelectionValue && sds1LangInput !== sasolDefaultSelectionValue)
    }, [sds1CountryInput, sds1LangInput, user.preferred_sds_country_1, user.preferred_sds_language_1])


    return (
        <div className='relative flex flex-col w-full gap-2 p-6 bg-white rounded'>
            <h2 className='w-full pb-1 mb-2 uppercase border-b border-gray-400 text-gray'>Default settings for product-detailpages</h2> 
            <h3 className='text-gray'>1. Preferrence</h3>
            <SelectPdsPreferences 
                selection={user.preferred_pds_language_1}
                selectionInput={pds1LangInput}
                onSelectionInput={setPds1LangInput}
                selectionStatus={selectionStatus} 
                options={pdsLangOptions}
                optionsAreLoading={pdsLangOptionsAreLoading}
            />            
            <SelectSdsPreferences 
                langSelection={user.preferred_sds_language_1}
                langSelectionInput={sds1LangInput}
                onLangSelectionInput={setSds1LangInput} 
                countrySelection={user.preferred_sds_country_1} 
                countrySelectionInput={sds1CountryInput}
                onCountrySelectionInput={setSds1CountryInput}
                selectionStatus={selectionStatus} 
                isShowingDisclaimer={isShowingSDS1Disclaimer}
                langOptions={sdsLangOptions}
                countryOptions={sdsCountryOptions}
                optionsAreLoading={sdsOptionsAreLoading}
            />
            {isShowingSecondPref &&
            <>
                <h3 className='text-gray'>2. Preferrence</h3>          
                <SelectSdsPreferences 
                    langSelection={user.preferred_sds_language_2}
                    langSelectionInput={sds2LangInput}
                    onLangSelectionInput={setSds2LangInput} 
                    countrySelection={user.preferred_sds_country_2} 
                    countrySelectionInput={sds2CountryInput}
                    onCountrySelectionInput={setSds2CountryInput}
                    selectionStatus={selectionStatus} 
                    isShowingDisclaimer={isShowingSDS2Disclaimer}
                    langOptions={sdsLangOptions}
                    countryOptions={sdsCountryOptions}
                    optionsAreLoading={sdsOptionsAreLoading}
                    defaultSelection={{label: 'No selection made', value: null}}
                />
            </>
            }
            <p className='mb-4 text-sm text-gray'>
                * The documents that can be found in the top section of a product page (quick links) are set to the language "English", by default. For Safety Data Sheets the default is also limited to the country "other regions/world".
            </p>
            <div className='flex gap-2'>
                <div><PrimaryButton title='' disabled={!hasUnsavedChanges} onClick={savePreferences(false)}>Save All</PrimaryButton></div>
                <SecondaryButton onClick={handleReset}>Reset All</SecondaryButton>
            </div>
            {selectionStatus === REQUEST_STATUS.PENDING &&
            <>
                <div className='absolute inset-0 w-full h-full p-12 bg-cyan-200 opacity-10' />
                <div className='absolute inset-0 flex flex-col items-center justify-center w-full h-full gap-2 opacity-100 text-cyan'>
                    <RequestStatus status={selectionStatus} size={64} />
                </div>
            </>
            }
        </div>
    )
}

export default DefaultDocumentsSettings