import React, { useState } from 'react';
import PrimaryButton from '../../components/elements/PrimaryButton';
import background from "../../assets/SASOL_Header_Illustration_.jpg";
import { api } from '../../api/api';
import HeaderExtension from '../../components/elements/HeaderExtension';
import { ReactComponent as IconArrowLeft} from "../../assets/icons/Icon_Arrow-Left.svg";
import { useNavigate, useLocation } from "react-router-dom";
import ProfileInfoForm from '../../components/profile/ProfileInfoForm';
import useFormData from '../../hooks/useFormData';
import useContactFormValidation from '../../hooks/useContactFormValidation';
import { useSelector } from 'react-redux';

const ContactPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector((state) => state.auth.user);

    const initialState = {
        recipient: {value: user?.sasol_sm_email ?? '', error: null},
        customer_name: {value: user?.name ?? '', error: null},
        customer_email: {value: user?.email ?? '', error: null},
        customer_phone: {value: user?.telephone_number ?? '', error: null},
        customer_organization: {value: user?.organization ?? '', error: null},
        customer_job_title: {value: user?.job_title ?? '', error: null},
        message: {value: '', error: null},
    }

    const {state, onChange, setError } = useFormData(initialState); 
    const {recipient, customer_name, customer_email, customer_phone, customer_organization, customer_job_title, message} = state;
    const [messageStatus, setMessageStatus] = useState(null);
    const [validateForm, formDataIsValid, isSasolMail] = useContactFormValidation(state, setError);

    function onSubmit() {
        validateForm();
        if(formDataIsValid()) {
            const formData = Object.fromEntries(Object.keys(state).map(field => [field, state[field].value]));
            setMessageStatus({message:'Sending message', status: 'pending'}); 
            api.sendContactForm(formData).then(res => {
                setMessageStatus({message:'Message sent', status: 'success'});
                if(location.state?.backLink) {
                    navigate(location.state.backLink);
                }
                else {
                    setTimeout(setMessageStatus, 3000, null);
                }
            })
            .catch(error => {
                setMessageStatus({message: 'Something went wrong trying to send your message', status: 'error'});
                console.log(error);
            });
        }
    }

    function checkSasolMail () {
        if(recipient.value.length > 0 && !isSasolMail(recipient.value)) {
            setError('recipient', 'Please enter a Sasol email address')
        }
    }

    return (
        <div className='contactPage bg-cover min-h-[85vh] grow' style={{backgroundImage: `url(${background})`}}>
            <HeaderExtension>
                <div 
                    className="flex items-center px-2 py-1 text-sm bg-gray-100 rounded-lg cursor-pointer hover:underline text-gray w-fit" 
                    onClick={() => {navigate(location.state?.backLink ?? "/")}}
                >
                    <IconArrowLeft />
                    <p>Back</p>
                </div>
            </HeaderExtension>
            <div className='container pt-12'>
                <div className='bg-white border rounded px-4 py-3 min-w-[50vw] shadow-card w-full'>
                    <h2 className='mb-4 text-xl font-bold text-center'>
                        Contact Form
                    </h2>
                    <ProfileInfoForm
                        representativeMail={{fieldName: 'recipient',...recipient}}
                        name={{fieldName: 'customer_name', ...customer_name}}
                        email={{fieldName: 'customer_email', ...customer_email}}
                        phone={{fieldName: 'customer_phone', ...customer_phone}}
                        organization={{fieldName: 'customer_organization', ...customer_organization}}
                        jobTitle={{fieldName: 'customer_job_title', ...customer_job_title}}
                        onChange={onChange}
                        checkSasolMail={checkSasolMail}
                    />
                    <div>
                        <h3 className='mb-2 text-lg text-gray'>Message</h3>
                        <div className='flex flex-col gap-4 lg:flex-row'>
                            <textarea
                                id="message_field" 
                                className={`grow border ${message.error !== null ? 'border-red-600' : 'border-gray-400'} p-3 rounded outline-none`}
                                name='message'
                                onChange={onChange}
                                value={message.value}
                                cols="30" 
                                rows="10" />
                            <div className='flex items-end'>
                                <PrimaryButton title='Submit' onClick={onSubmit} disabled={messageStatus?.status === 'pending'} />
                            </div>
                        </div>
                    </div>
                    {messageStatus &&
                    <div className={`text-center ${messageStatus.status === 'error' ?'text-red-600' : 'text-gray'} mt-2`}>{messageStatus.message}</div>
                    }
                </div>
            </div>
        </div>
    );
}

export default ContactPage;