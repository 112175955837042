import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const RepresentativeConfirm = (props) => {
    const representativeMail = useSelector((state) => state.auth.user?.sasol_sm_email);
    const [sasolSMEmail] = useState(props.steps[props.repInputStep]?.value ? props.steps[props.repInputStep]?.value : representativeMail);
    const [selection, setSelection] = useState(null);
    return(
        <div>
            <div className='mb-2'>Your Sasol sales representative's email is: <p className='break-words my-2 font-semibold'>{sasolSMEmail}</p> <p>Correct?</p></div>
            <div className='flex flex-col gap-1'>
                <button 
                    className={`rounded-lg border-white border py-1 font-semibold ${selection === 'correct' ? 'bg-white text-cyan' : ''}`} 
                    onClick={() => {props.triggerNextStep({value: sasolSMEmail, trigger: props.trigger}); setSelection('correct')}}
                    disabled={(selection != null)}
                >
                        Correct
                </button>
                <button 
                    className={`rounded-lg border-white border py-1 font-semibold ${selection === 'not_correct' ? 'bg-white text-cyan' : ''}`} 
                    onClick={() => {props.triggerNextStep({value: sasolSMEmail, trigger: props.triggerInput}); setSelection('not_correct')}}
                    disabled={(selection != null)}
                >
                        Not correct
                </button>
            </div>
        </div>
    )
}

export default RepresentativeConfirm;