import React, { useEffect, useState } from 'react';
import NewsFilterTabs from './NewsFilterTabs';
import NewsList from './NewsList';
import { ReactComponent as IconFilter } from '../../assets/icons/Icon_Filter.svg'
import SearchBar from '../elements/SearchBar';
import { api } from '../../api/api';
import SingleSelectionDropdown from '../elements/SingleSelectionDropdown';

const sortByLabel = (a, b) => a.label.localeCompare(b.label)

const NewsSection = () => {

    const [selectedTab, setSelectedTab] = useState(null);
    const [showingOnlyUread, setShowingOnlyUnread] = useState(false);
    const [searchInput, setSearchInput] = useState('')
    const [langOptions, setLangOptions] = useState([])
    const [countryOptions, setCountryOptions] = useState([])
    const [languageSelection, setLanguageSelection] = useState(null);
    const [countrySelection, setCountrySelection] = useState(null);

    useEffect(() => {
        api.getSDSLanguageOptions()
            .then(res => setLangOptions(res.data.sort(sortByLabel)))
            .catch(err => console.log(err))
        api.getSDSCountryOptions()
            .then(res => setCountryOptions(res.data.sort(sortByLabel)))
            .catch(err => console.log(err))
    }, [])

    return (
        <div>
           <div className='container flex justify-between'>
                <div className='basis-full lg:basis-2/5'>
                    <SearchBar value={searchInput} onChange={setSearchInput} placeholder='Search by product or marketing material ...'/>
                </div>
                 {/*<button 
                    className={`border rounded px-2 py-1 flex items-center text-sm hover:bg-gray-50 ${showingOnlyUread ? 'border-cyan text-cyan' : 'text-gray'}`}
                    onClick={() => setShowingOnlyUnread(!showingOnlyUread)}
                >
                    <IconFilter className='w-4 h-4' />
                    show unread only
                </button>*/}
            </div>
            <div className='container mt-2'>
                <div className='flex gap-2 py-2 max-lg:flex-col'>
                    <SingleSelectionDropdown 
                        label='Country*'
                        options={countryOptions}
                        selection={countrySelection}
                        onSelectionInput={setCountrySelection}
                    />
                    <SingleSelectionDropdown 
                        label='Language'
                        options={langOptions}
                        selection={languageSelection}
                        onSelectionInput={setLanguageSelection}
                    />
                </div>
                <p className='text-sm text-gray'>* Country only applies to SDS documents</p>
            </div>
            <div className='flex flex-col gap-6 lg:items-stretch lg:flex-row mt-7 lg:container'>
                <div className='lg:basis-1/4'><NewsFilterTabs selectedTab={selectedTab} onChange={setSelectedTab} /></div>
                <div className='max-lg:container lg:basis-3/4'>
                    <NewsList 
                        selectedTab={selectedTab} 
                        showOnlyUnread={showingOnlyUread} 
                        searchString={searchInput.toLowerCase()} 
                        language={languageSelection?.value} 
                        country={countrySelection?.value} 
                    />
                </div>
            </div>
        </div>
    );
}

export default NewsSection;