import PropTypes from "prop-types";
import React, { forwardRef, useMemo, useState } from "react";
import { ReactComponent as IconDownload } from "../../assets/icons/Icon_Download.svg";
import { ReactComponent as IconExpand } from '../../assets/icons/Icon_Expand.svg';
import useModal from "../../hooks/useModal";
import usePreloadDocuments from "../../hooks/usePreloadDocuments";
import { DocumentTypes, REQUEST_STATUS } from "../../store/types";
import DocumentPreview from "../elements/DocumentPreview";
import Modal from "../elements/Modal";
import RequestStatus from "../elements/RequestStatus";
import SecondaryButton from "../elements/SecondaryButton";
import Checkbox from "../elements/Checkbox";
import CollectDocumentButton from "../elements/CollectDocumentButton";
import useSyncedScrollableContainer from "../../hooks/useSyncedScrollableContainer";
import { useTwScreens } from "../../utilities/TailwindScreens";
import ExtendbaleColumnContainer from "../elements/ExtendbaleColumnContainer";
import PreviewIcon from "../elements/PreviewIcon";

const CertificatesListEntry = forwardRef((props, ref) => {
    const {
        downloadRequestStatus,
        provideCertificatesDownload
    } = usePreloadDocuments();
    const [sitesToggled, setSitesToggled] = useState(false);
    const [showingPreviewButton, setShowingPreviewButton] = useState(false);

    const {min} = useTwScreens()
    const scrollContainerRef = useSyncedScrollableContainer(ref)

    const { isShowing, toggle } = useModal();

    function preview() {
        toggle()
    }
    function downloadDatasheet() {
        provideCertificatesDownload(
            props.certificate.file,
            props.certificate.name
        );
    }

    const certificateTitle = useMemo(() => props.certificate.type, [props.certificate.type])
    const certificateDescription = useMemo(() => props.certificate.description, [props.certificate.description])
    const secondColumn = useMemo(() => props.certificate.companies?.map((company, index) => {
        return (
            <div key={index} className="mr-2">
                {company}
            </div>
        );
    }), [props.certificate.companies])


    const SitesCell = () => {
        if (props.certificate.sites.length > 3 && !sitesToggled) {
            return (
                <div>
                    {props.certificate.sites.slice(0, 3).map((site, index) => (
                        <div key={index} className="mr-2">
                            {site}
                        </div>
                    ))}
                    <div className="mr-2">
                        <button
                            onClick={(e) => {e.preventDefault(); setSitesToggled(true) }}
                            className="cursor-pointer text-cyan"
                        >
                            Show all ({props.certificate.sites.length})
                        </button>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    {props.certificate.sites.map((site, index) => (
                        <div key={index} className="mr-2">
                            {site}
                        </div>
                    ))}
                </div>
            );
        }
    };

    return (
        <div
            className={`certificateListEntry ${min('lg') ? 'certificatesGridContainer' : ''} border-b border-grey-500 text-gray text-sm bg-white`}
            onMouseEnter={() => setShowingPreviewButton(true)}
            onMouseLeave={() => setShowingPreviewButton(false)}
        >
            {min('lg') ? 
            <>
                <div className="flex start-1">
                    <div className="px-4 py-3 mr-[1px] flex items-center">
                        <Checkbox
                            checked={props.selected}
                            onChange={() => {
                                props.onSelection();
                            }}
                            args={{ id: "select" + props.certificate.id }}
                            topMargin="top-[6px]"
                        />
                    </div>
                    <div className="py-3 px-4 mr-[1px] background-gradient flex-grow flex flex-col justify-center">
                        <div>
                            <div className="flex justify-between">
                                <p className="font-bold text-[15px]">
                                    {certificateTitle}
                                </p>
                                {showingPreviewButton &&
                                <button onClick={preview}>
                                    <PreviewIcon />
                                </button>
                                }
                            </div>
                            <div className="">{certificateDescription}</div>
                        </div>
                    </div>
                </div>

                <div className="flex items-center px-4 py-3 start-2 background-gradient">
                    {secondColumn}
                </div>
                <div className="px-4 py-3 start-3 background-gradient">
                    {SitesCell()}
                </div>
                <div className="flex flex-wrap items-center justify-end gap-2 p-2 border-l start-4 border-gray-10">
                    {downloadRequestStatus !== REQUEST_STATUS.IDLE && (
                        <RequestStatus status={downloadRequestStatus} />
                    )}
                    <CollectDocumentButton document={props.certificate} documentType={DocumentTypes.CERTIFICATES} />
                    <SecondaryButton onClick={downloadDatasheet} cyan={true}>
                        <span className="flex items-center">
                            <IconDownload className="w-4" />
                        </span>
                    </SecondaryButton>
                </div>
            </>
            :
            <div className="flex items-stretch w-full text-base">
                <div className="p-2">
                    <CollectDocumentButton document={props.certificate} documentType={DocumentTypes.CERTIFICATES} />
                </div>
                <ExtendbaleColumnContainer width={props.extendableColumnWidth} onClick={props.toggleExtendableColumn}>
                        {certificateTitle}
                </ExtendbaleColumnContainer>
                <div ref={scrollContainerRef} className='w-full overflow-x-scroll hiddenScrollbars' onScroll={props.onSyncedScroll} >
                    <div className="grid grid-cols-2 w-sideScrollable">
                        <div className="px-4 py-3 background-gradient">
                            {secondColumn}
                        </div>
                        <div className="px-4 py-3 background-gradient">
                            {SitesCell()}
                        </div>
                    </div>
                </div>
            </div>
            }
            <Modal isShowing={isShowing} close={toggle}>
                <DocumentPreview 
                    document={props.certificate} 
                    product={null} 
                    docType={DocumentTypes.CERTIFICATES} 
                    onDownload={downloadDatasheet} 
                    downloadStatus={downloadRequestStatus} 
                    onClose={toggle} 
                />
            </Modal>
        </div>
    );
})

CertificatesListEntry.propTypes = {
    /**
     * Datasheet object for the entry to display
     */
    certificate: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    onSelection: PropTypes.func,
    productName: PropTypes.string
};

export default CertificatesListEntry;
