import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MenuButton from '../../components/elements/MenuButton';
import { ReactComponent as Logo } from "../../assets/logos/Primary_horizontal_logo_one_colour_white.svg";
import BasketHeaderIcon from '../basket/BasketHeaderIcon';
import MobileProfileButton from '../../components/profile/MobileProfileButton';
import useHideChatbot from '../../hooks/useHideChatbot';
import HeaderNavigationLinks from './HeaderNavigationLinks';
import ProfileNavigationList from '../../components/profile/ProfileNavigationList';
import { Link, useLocation } from 'react-router-dom';
import MobileSearchButton from '../../components/search/MobileSearchButton';
import useProductSearchSuggestions from '../../hooks/useProductSearchSuggestions';
import useProductSearch from '../../hooks/useProductSearch';
import SearchBar from '../../components/elements/SearchBar';
import CloseButton from '../../components/elements/CloseButton';
import MobileSearchSuggestionsList from '../../components/search/MobileSearchSuggestionsList';
import { useTour } from '@reactour/tour';

const MODAL_VALUES = {
    main: 'MAIN_MENU',
    profile: 'PROFILE_MENU',
    search: 'PRODUCT_SEARCH',
}

const MobileHeader = ({isSearchbarLocation}) => {

    const location = useLocation()
    const [modalValue, setModalValue] = useState(null);
    const modalIsOpen = useMemo(() => modalValue !== null, [modalValue])
    useHideChatbot(modalIsOpen)

    const {searchTerm, handleInput, handleSubmit} = useProductSearch()
    const {suggestions} = useProductSearchSuggestions(searchTerm)

    const {currentStep} = useTour()

    useEffect(() => {
        if(currentStep === 1) {
            closeModal()
        }
        else if(currentStep === 3) {
            setModalValue(MODAL_VALUES.main)
        }
        else if(currentStep === 4) {
            closeModal()
        }
        else if(currentStep === 5) {
            setModalValue(MODAL_VALUES.main)
        }
    }, [currentStep]);

    const modalChildren = useMemo(() => {
        switch (modalValue) {
            case MODAL_VALUES.main:
                return <HeaderNavigationLinks />
            case MODAL_VALUES.profile:
                return <ProfileNavigationList />
            case MODAL_VALUES.search:
                return <MobileSearchSuggestionsList suggestions={suggestions} backLink={location} />
            default:
                return null;
        }
    }, [location, modalValue, suggestions])

    const clearSearch = useCallback(() => {
        handleInput('')
    }, [handleInput])

    useEffect(() => {
        closeModal()
        clearSearch()
    }, [location]);

    function closeModal() {
        setModalValue(null)
        clearSearch()
    }

    const toggleMenu = menuValue => () => {
        if(modalValue === menuValue) {
            closeModal()
        }
        else {
            setModalValue(menuValue)
        }
    }

    return (
        <>
            <header className={`top-0 z-30 w-full px-3 py-4 text-white h-header sticky bg-blue`} data-tour="step-header">
                {modalValue === MODAL_VALUES.search ? 
                <div className='flex items-center gap-4'>
                    <SearchBar secondVariant value={searchTerm} onChange={handleInput} onSubmit={handleSubmit} autoFocus/>
                    <CloseButton defaultColor='cyan' onClose={toggleMenu(MODAL_VALUES.search)} />
                </div>
                :
                <div className='flex items-center justify-between'>
                    <div className='flex items-center gap-4'>
                        <MenuButton onClick={toggleMenu(MODAL_VALUES.main)} isOpen={modalValue === MODAL_VALUES.main} />
                        <Link to='/'><Logo className='w-28' /></Link>
                    </div>
                    <div className='flex items-center justify-end gap-4'>
                        {(isSearchbarLocation || modalIsOpen) && <MobileSearchButton onClick={toggleMenu(MODAL_VALUES.search)} />}
                        <BasketHeaderIcon backlink={location} />
                        <MobileProfileButton onClick={toggleMenu(MODAL_VALUES.profile)} isOpen={modalValue === MODAL_VALUES.profile} />
                    </div>
                </div>}
            </header>
            {modalIsOpen &&
            <div className={`fixed z-[29] w-screen h-screen ${modalValue === MODAL_VALUES.search ? 'bg-gray-50' : 'bg-blue'} pt-header mobileHeaderModal`}>
                <div className='w-full h-full'>
                    {modalChildren}
                </div>
            </div>
            }
        </>
    );
}
 
export default MobileHeader;