import React, { forwardRef, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as IconDownload } from '../../../assets/icons/Icon_Download.svg';
import SecondaryButton from '../../elements/SecondaryButton';
import Checkbox from '../../elements/Checkbox';
import Modal from '../../elements/Modal';
import useModal from '../../../hooks/useModal';
import DocumentPreview from '../../elements/DocumentPreview';
import RequestStatus from '../../elements/RequestStatus'
import { DocumentTypes, REQUEST_STATUS } from '../../../store/types';
import usePreloadDocuments from '../../../hooks/usePreloadDocuments';
import MaterialNumbersList from '../../elements/MaterialNumbersList';
import { useCallback } from 'react';
import { useDocumentLeftBorderColor } from '../../../hooks/useDocumentBorderColor';
import CollectDocumentButton from '../../elements/CollectDocumentButton';
import PreviewIcon from '../../elements/PreviewIcon';
import { useTwScreens } from '../../../utilities/TailwindScreens';
import useSyncedScrollableContainer from '../../../hooks/useSyncedScrollableContainer';
import ExtendbaleColumnContainer from '../../elements/ExtendbaleColumnContainer';

const MobileDatasheetListEntry = forwardRef((props, ref) => {
    const scrollContainer = useSyncedScrollableContainer(ref)

    return(
        <div className='flex items-stretch w-full text-base'>
            <div className='p-2'>
                <CollectDocumentButton document={props.datasheet} product={props.product} documentType={props.type} />
            </div>
            <ExtendbaleColumnContainer width={props.extendableColumnWidth} onClick={props.toggleExtendableColumn}>
                {props.firstColumn}
            </ExtendbaleColumnContainer>
            <div ref={scrollContainer} className='overflow-x-scroll hiddenScrollbars' onScroll={props.onSyncedScroll}>
                <div className='grid items-stretch h-full grid-cols-3 w-sideScrollable'>
                    <div className='px-4 py-3 border-r background-gradient border-r-gray-100'>{props.secondColumn}</div>
                    <div className='px-4 py-3 border-r background-gradient border-r-gray-100'>{props.thirdColumn}</div>
                    <div className='px-4 py-3 border-r background-gradient border-r-gray-100'>{props.fourthColumn}</div>
                </div>
            </div>
        </div>
    )
})

const DatasheetListEntry = forwardRef((props, ref) => {

    const {min} = useTwScreens()

    const {downloadRequestStatus, provideDatasheetDownload} = usePreloadDocuments();    
    const [showingPreviewButton, setShowingPreviewButton] = useState(false);

    const {isShowing, toggle} = useModal();

    const borderColorClass = useDocumentLeftBorderColor(props.type)

    const updateDateString = useMemo(() => new Date(props.datasheet.created_date).toISOString().split('T')[0], [props.datasheet.created_date])

    const firstColumn = useMemo(() => {
        const datasheet = props.datasheet
        switch (props.type) {
            case DocumentTypes.PDS:
                return datasheet.source
            case DocumentTypes.SDS:
            case DocumentTypes.ERI:
                return datasheet.country
            case DocumentTypes.RIS:
                return 'All'
            default:
                return null
        }
    }, [props.datasheet, props.type])

    const secondColumn = useMemo(() => {
        return props.datasheet.language
    }, [props.datasheet.language])

    const thirdColumn = useMemo(() => {
        return <MaterialNumbersList list={props.datasheet.materials || []} maxElements={5} />
    }, [props.datasheet.materials])

    const fourthColumn = useMemo(() => {
        return updateDateString
    }, [updateDateString])

    const preview = useCallback(() => {
        toggle()
    }, [toggle])

    function downloadDatasheet() {
        provideDatasheetDownload(props.datasheet.file, Object.keys(DocumentTypes).find(key => DocumentTypes[key] === props.type), props.product.name, props.datasheet.language, (props.type === DocumentTypes.RIS ? 'All' : props.datasheet.country ?? props.datasheet.source))
    }

    return (
        <div 
            className={`datasheetListEntry ${min('lg') ? 'datasheetTableGrid' : ''} mb-px text-gray text-sm border-l-2 ${borderColorClass} bg-white hover:bg-gray-50 border-t border-t-gray-100`}
            onMouseEnter={() => setShowingPreviewButton(true)}
            onMouseLeave={() => setShowingPreviewButton(false)}
        >
            {min('lg') ?
            <>
                <div className='flex start-1'>
                    <div className='flex items-center px-4 py-3'>
                        <Checkbox checked={props.selected} onChange={() => {props.onSelection()}} args={{id: 'select'+props.datasheet.id}} topMargin="top-[6px]" />
                    </div>
                    <div className='flex items-center justify-between flex-grow px-4 py-3 mr-px border-l background-gradient border-l-gray-100'>
                        <div>
                            {firstColumn}
                        </div>
                        {showingPreviewButton &&
                        <button onClick={preview}>
                            <PreviewIcon />
                        </button>
                        }
                    </div>
                </div>
                <div className='start-2 py-3 px-4 mr-[1px] background-gradient flex items-center border-l border-l-gray-100' >
                    {secondColumn}
                </div>
                <div className='px-4 py-3 border-l start-3 background-gradient border-l-gray-100'>
                    {thirdColumn}
                </div>
                <div className='flex items-center px-4 py-3 border-l start-4 background-gradient border-l-gray-100'>
                    {fourthColumn}
                </div>
                <div className='flex flex-wrap items-center justify-end gap-2 p-2 start-5'>
                    {downloadRequestStatus !== REQUEST_STATUS.IDLE &&
                        <RequestStatus status={downloadRequestStatus} />
                    }
                    <CollectDocumentButton document={props.datasheet} product={props.product} documentType={props.type} />
                    <SecondaryButton 
                        onClick={downloadDatasheet} 
                        cyan={true} 
                    >
                        <IconDownload className='w-4' />  
                    </SecondaryButton>                
                </div>
            </>
        :
            <MobileDatasheetListEntry 
                ref={ref}
                datasheet={props.datasheet}
                product={props.product}
                type={props.type}
                firstColumn={firstColumn}
                secondColumn={secondColumn}
                thirdColumn={thirdColumn}
                fourthColumn={fourthColumn}
                onSyncedScroll={props.onSyncedScroll}
                extendableColumnWidth={props.extendableColumnWidth}
                toggleExtendableColumn={props.toggleExtendableColumn}
            />
        }
            <Modal isShowing={isShowing} close={toggle}>
                <DocumentPreview 
                    document={props.datasheet} 
                    product={props.product} 
                    docType={props.type} 
                    onDownload={downloadDatasheet} 
                    downloadStatus={downloadRequestStatus} 
                    onClose={toggle} 
                />
            </Modal>
        </div>
    )
})

DatasheetListEntry.propTypes = {
    /**
     * Datasheet object for the entry to display
     */
    datasheet: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    onSelection: PropTypes.func,
    productName: PropTypes.string,
    type: PropTypes.oneOf(Object.values(DocumentTypes)),
    product: PropTypes.object,
}

export default DatasheetListEntry;