import React from 'react';
import {ReactComponent as IconSearch} from '../../assets/icons/Icon_Search.svg'

const MobileSearchButton = ({onClick}) => {
    return (
        <button 
            onClick={onClick} 
            className='p-2 border border-gray-600 rounded'
        >
            <IconSearch className='w-6 h-6 text-cyan' />
        </button>
    )
}
 
export default MobileSearchButton;