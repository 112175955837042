import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as IconChevronDown} from "../../../assets/icons/Icon_Chevron-Down.svg";
import {ReactComponent as IconDownload} from "../../../assets/icons/Icon_Download.svg";
import "./DefaultDocumentTypeEntry.scss";
import usePreloadDocuments from '../../../hooks/usePreloadDocuments';
import { REQUEST_STATUS } from '../../../store/types';
import RequestStatus from '../../elements/RequestStatus';

const DefaultDocumentTypeEntry = (props) => {
    const {downloadRequestStatus, provideDatasheetDownload} = usePreloadDocuments();

    const size = props.documents?.length;
    const [hideEntries, setHideEntries] = useState(true);
    let borderColorClass = 'gray';
    let typeShort = 'PDS';
    switch (props.type) {
        case "Product Data Sheet":
            borderColorClass = "border-l-product";
            typeShort = 'PDS';
            break;
        case "Safety Data Sheet":
            borderColorClass = "border-l-safety";
            typeShort = 'SDS';
            break;
        case "Regulatory Information Sheet":
            borderColorClass = "border-l-regulatory";
            typeShort = 'RIS';
            break;
    
        default:
            break;
    }
    
    if(size > 1) {              //multiple docs
        const list = props.documents.map((document) => {
            return (
                <li key={document.id} className="py-2 border-l border-gray-400 subListItem">
                    <div className='flex items-center justify-between gap-2'>
                        <div className='w-3 border-b border-gray-400'></div>
                        <div className='flex flex-col flex-grow text-sm text-gray'>
                            <div className='flex gap-2'>
                                <div className='flex items-center text-xs'>{(props.type === "Product Data Sheet") ? "Sasol Source: " : "Country: "}</div>
                                <div className='flex items-center text-xs text-gray'>
                                    {(props.type === "Product Data Sheet") && document.source}
                                    {(props.type === "Safety Data Sheet") && document.country}
                                    {(props.type === "Regulatory Information Sheet") && "All"}
                                </div>
                            </div>
                            <div className='flex flex-col text-xs text-gray'>
                                    {document.language_code && <div>Language: {document.language_code}</div>}
                                    {document.specification && <div>Specification: {document.specification}</div>}
                            </div>
                        </div>
                        <button className='text-gray-600' onClick={() => provideDatasheetDownload(document.file, typeShort, props.productName, document.language, (typeShort === 'RIS' ? 'All' : document.country ?? document.source))}>
                            <IconDownload className='cursor-pointer'/>
                        </button>
                    </div>
                                            
                </li>
            );
        });
        return (
            <div 
                className={`defaultDocTypeEntry py-2 px-4 rounded-sm border-l-2 ${borderColorClass} bg-white mb-[1px]`}
            >
                <div 
                    onClick={() => {setHideEntries(!hideEntries)}}
                    className="flex justify-between font-medium entryGroupHead">
                    <div>
                        {props.type}
                    </div>
                    <div className='flex'>
                        {downloadRequestStatus === REQUEST_STATUS.PENDING &&
                            <RequestStatus status={downloadRequestStatus} />
                        }
                        <div className={`text-gray-600 ${hideEntries ? '' : 'rotate-180'}`}>
                            <IconChevronDown className='cursor-pointer'/>
                        </div>
                    </div>
                </div>
                {!hideEntries && <ul className='mt-2 ml-4'>{list}</ul>}
            </div>
        );
    } 
    else {         //single doc
        return (
            <div className={`defaultDocTypeEntry py-2 px-4 rounded-sm border-l-2 ${borderColorClass} bg-white mb-[1px] flex justify-between items-center`}>
                <div>
                    <p className='font-medium'>{props.type}</p>
                    {size > 0 &&
                    <div className='flex gap-3 text-xs text-gray'>
                        <p>Language: {props.documents[0].language_code}</p>
                        {(props.type === "Safety Data Sheet") && <p>Country: {props.documents[0].country}</p>}
                        {(props.type === "Regulatory Information Sheet") && <p>Country: All</p>}
                    </div>
                    }
                    {size === 0 &&
                    <p className='text-sm text-gray'>No document available, explore other documents via "View All"</p>
                    }
                </div>
                {size > 0 &&
                <button 
                    className='text-gray-600' 
                    onClick={() => provideDatasheetDownload(props.documents[0].file, typeShort, props.productName, props.documents[0].language, (typeShort === 'RIS' ? 'All' : props.documents[0].country ?? props.documents[0].source))} 
                    disabled={downloadRequestStatus === REQUEST_STATUS.PENDING}
                >
                    {downloadRequestStatus === REQUEST_STATUS.IDLE ? 
                        <IconDownload className='cursor-pointer'/>
                    :
                        <RequestStatus status={downloadRequestStatus} />
                    }
                </button>
                }
                
            </div>
        );
    }
}

DefaultDocumentTypeEntry.propTypes = {
    /**
     * Array of Objects of one type
     */
    documents: PropTypes.array,
    /**
     * Type of the displayed Documents
     */
    type: PropTypes.oneOf(['Product Data Sheet', 'Safety Data Sheet', 'Regulatory Information Sheet']),

    productName: PropTypes.string

}

export default DefaultDocumentTypeEntry;