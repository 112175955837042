import { useCallback, useMemo } from "react"
import { DocumentTypes } from "../store/types";

export const useDocumentColor = () => {
    const getDocumentColor = useCallback((type) => {
        switch (type) {
            case DocumentTypes.PDS:
                return 'product'
            case DocumentTypes.SDS:
                return 'safety'
            case DocumentTypes.RIS:
                return 'regulatory'  
            case DocumentTypes.ERI:
                    return 'yellow-500'        
            default:
                return 'gray'
        }
    }, [])

    return getDocumentColor
}

export const useDocumentLeftBorderColor = (documentType) => {
    const borderColorClass = useMemo(() => {
        switch (documentType) {
            case DocumentTypes.PDS:
                return 'border-l-product'   
            case DocumentTypes.SDS:
                return 'border-l-safety'  
            case DocumentTypes.RIS:
                return 'border-l-regulatory'
            case DocumentTypes.ERI:
                return 'border-l-yellow-500'
            case DocumentTypes.MARKETING:
                return 'border-l-gray'
            default:
                return 'border-l-transparent'
        }
    }, [documentType])
    return borderColorClass
}