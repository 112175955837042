import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { agree_terms } from '../../store/actions/auth';
import { useNavigate } from "react-router-dom";
import {useLocation} from "react-router";
import PrimaryButton from '../../components/elements/PrimaryButton';
import ReactMarkdown from 'react-markdown'
import PrivacyPolicy from '../../PrivacyPolicy.md'
import Disclaimer from '../../DISCLAIMER.md'

const AgreementForm = ({redirectAfterAgreed}) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const redirect = useLocation().state?.redirect;
    const navigate = useNavigate();

    const [privacyPolicyText, setPrivacyPolicyText] = useState('');
    const [disclaimerText, setDisclaimerText] = useState('');

    function onAgree() {
        dispatch(agree_terms(user));
    }

    useEffect(() => {
      if(user.accepted_terms && redirectAfterAgreed){
          navigate(redirect ?? '/');
      }
    }, [user.accepted_terms])
    
    useEffect(() => {
        fetch(PrivacyPolicy).then(res => res.text()).then(text => setPrivacyPolicyText(text))
        fetch(Disclaimer).then(res => res.text()).then(text => setDisclaimerText(text))
    }, [])

    return (
        <div className='container AgreementsForm'>
            <div className='w-full mt-3 shadow-card rounded py-4 px-3 bg-gray-100 flex flex-col gap-2 items-center lg:max-w-[70vw] lg:mx-auto overflow-hidden'>
                <h2 className='w-full text-xl font-bold text-center'>Confidentiality Statement & Privacy Policy</h2>
                <div className='p-2 overflow-y-scroll bg-white rounded max-h-[60vh] lg:max-h-[50vh] w-full customScrollbars'>
                    <ReactMarkdown 
                        children={disclaimerText} className='w-full font-sans prose-sm break-words' 
                        components={{
                            h1: 'h2',
                            h2: 'h3',
                            h3: 'h4',
                            h4: 'h5',
                            ul: (props) => {return <ul className='list-disc'>{props.children}</ul>}
                        }}
                    />
                    <ReactMarkdown 
                        children={privacyPolicyText} className='w-full mt-4 font-sans prose-sm break-words' 
                        components={{
                            h1: 'h2',
                            h2: 'h3',
                            h3: 'h4',
                            h4: 'h5',
                            ul: (props) => {return <ul className='list-disc'>{props.children}</ul>}
                        }}
                    />
                    <div className='flex flex-col items-center w-full mt-4'>
                        <PrimaryButton title="Accept" color={user.accepted_terms ? "gray-100" : "cyan"} onClick={onAgree} disabled={user.accepted_terms} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AgreementForm;