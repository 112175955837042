import { DocumentTypes } from "../types";

export function getProductCollectionByType(type, product) {
    switch (type) {
        case DocumentTypes.PDS:
            return product.pds
        case DocumentTypes.SDS:
            return product.sds
        case DocumentTypes.RIS:
            return product.ris
        case DocumentTypes.ERI:
            return product.eri
        default:
            return null;
    }
}

export function getBasketCollectionByType(type, state) {
    switch (type) {
        case DocumentTypes.PDS:
            return state.pds
        case DocumentTypes.SDS:
            return state.sds
        case DocumentTypes.RIS:
            return state.ris
        case DocumentTypes.ERI:
            return state.eri
        case DocumentTypes.MARKETING:
            return state.marketingMaterials
        case DocumentTypes.CERTIFICATES:
            return state.certificates
        default:
            return null;
    }
}

export function removeDatasheetIdFromProduct(id, type, product, state, productsAdapter) {
    //remove id from correct collection in product
    const collection = getProductCollectionByType(type, product)
    if(collection) {
        const index = collection?.indexOf(id)
        if(index >= 0) {
            collection.splice(index, 1)
        }
    }
    //if all collections empty -> remove product
    if((product.sds.length + product.pds.length + product.ris.length + product.eri.length) <= 0) {
        productsAdapter.removeOne(state.products, product.id)
    }
}

export function addDatasheetIdToProduct(id, type, product, state, productsAdapter) {
    const productInBasket = state.products.entities[product.id]
    if(productInBasket) {
        const collection = getProductCollectionByType(type, productInBasket)
        if(collection) {
            if(!collection.includes(id)) {
                collection.push(id)
            }
        }
    }
    else {
        const productEntry = {
            ...product,
            sds: type === DocumentTypes.SDS ? [id] : [],
            ris: type === DocumentTypes.RIS ? [id] : [],
            pds: type === DocumentTypes.PDS ? [id] : [],
            eri: type === DocumentTypes.ERI ? [id] : [],
        }
        productsAdapter.addOne(state.products, productEntry)   
    }
}

export const createAddDocumentsPayload = (product, pds, sds, ris, eri, marketing, certificates) => {
    return {payload: 
        {
            product: product, 
            pds: pds ?? [], 
            sds: sds ?? [], 
            ris: ris ?? [],
            eri: eri ?? [],
            marketing: marketing ?? [],
            certificates: certificates ?? [],
        }}
}

export const createRemoveDocumentPayload = (productId, type, id) => {
    return {payload: {productId: productId, type: type, id: id}}
}

export const createAddDocumentPayload = (product, type, document) => {
    return {payload: {product: product, type: type, data: document}}
}

export const createRemoveDocumentsPayload = (productCollections, marketing, certificates) => {
    return {payload: 
        {
            productCollections: productCollections ?? [], 
            marketing: marketing ?? [],
            certificates: certificates ?? [],
        }}
}

