import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Chatbot from "react-simple-chatbot";
import {ThemeProvider} from "styled-components";
import NamedGreeting from './customMessages/NamedGreeting';
import { toggleChatbot } from '../../store/actions/chatbot';
import getContactFormSteps from './steps/contact-form';
import getContactSafetySteps from './steps/contact-safety';
import StartTour from './customMessages/tour/StartTour';
import getUpdateUserSteps from './steps/update-user';
import ChatbotHeader from './ChatbotHeader';
import { resetChatbot } from '../../store/actions/chatbot'
import useCustomerViewActive from '../../hooks/useCustomerViewActive';

const ContactChatbot = () => {

  const chatbotState = useSelector((state) => state.chatbot);
  const isHidden = useCustomerViewActive() //hide if CustomerView is active
  const dispatch = useDispatch();

  function toggleChatbotFloating({opened}) {
    dispatch(toggleChatbot(!opened))
  }

  function resetChatBot() {
    dispatch(resetChatbot())
  }

  const theme = {
      background: '#F5F7F9',
      fontFamily: 'Poppins, sans-serif',
      headerBgColor: '#00AEEF',
      headerFontColor: '#fff',
      headerFontSize: '15px',
      botBubbleColor: '#00AEEF',
      botFontColor: '#fff',
      userBubbleColor: '#E2ECF2',
      userFontColor: '#5A7C8D',
    };

  const config = {
      floating: true,
      headerComponent: <ChatbotHeader headerTitle='Sasol help' onClose={toggleChatbotFloating} onReset={resetChatBot} />,
      hideUserAvatar: true,
      hideBotAvatar: true,
      userDelay: 700,
      botDelay: 700,
      opened: chatbotState.isOpen,
      toggleFloating: toggleChatbotFloating,
      enableSmoothScroll: true,
      bubbleStyle: {
        wordWrap: "anywhere",
        maxWidth: "80%",
      },      
  }
  const contactFormSteps = getContactFormSteps('cb_restart');
  const contactSafetySteps = getContactSafetySteps('cb_restart');
  const updateUserSteps = getUpdateUserSteps('cb_restart');
  const introSteps = [
    {
      id: 'cb_greeting',
      component: <NamedGreeting />,
      asMessage: true,
      trigger: 'cb_options',
    },
    {
      id: 'cb_restart',
      message: 'Is there anything else I can help you with?',
      trigger: 'cb_options',
    },
    {
      id: 'cb_options',
      options: [
        { value: 1, label: 'I want to write a message to my Sasol sales representative', trigger: contactFormSteps.firstStepId },
        { value: 2, label: ' I have a Product Safety (Toxicology/Regulatory) related question', trigger: contactSafetySteps.firstStepId },
        { value: 3, label: 'I need some help using this tool', trigger: 'cb_start_tour' },
        { value: 4, label: 'I want to update my contact information', trigger: updateUserSteps.firstStepId },
      ],
    },
    {
      id: 'cb_start_tour',
      component: <StartTour />,
      replace: true,
      trigger: 'cb_restart'
    }
  ];
  let steps = introSteps.concat(contactFormSteps.steps).concat(contactSafetySteps.steps).concat(updateUserSteps.steps);

  return (
    <div className={isHidden ? 'hidden' : 'customScrollbars'}> {/*Hiding via z-index prevents braking BackButton*/}
      <ThemeProvider theme={theme}>
          <Chatbot key={chatbotState.key} steps={steps} {...config}/>
      </ThemeProvider>
    </div>
  )
}

export default ContactChatbot;