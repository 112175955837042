import { useMemo } from "react"
import { basketSelectionSelectors } from "../store/slices/basket-selection/basketSelection"
import { basketSelectors } from "../store/slices/basket/basket"
import { DocumentTypes } from "../store/types"

const useDatasheetSelector = (type, basketSelection = false) => {
    const datasheetSelector = useMemo(() => {
        switch (type) {
            case DocumentTypes.PDS:
                return basketSelection ? basketSelectionSelectors.pds : basketSelectors.pds
            case DocumentTypes.SDS:
                return basketSelection ? basketSelectionSelectors.sds : basketSelectors.sds
            case DocumentTypes.RIS:
                return basketSelection ? basketSelectionSelectors.ris : basketSelectors.ris
            case DocumentTypes.ERI:
                return basketSelection ? basketSelectionSelectors.eri : basketSelectors.eri
            case DocumentTypes.MARKETING:
                return basketSelection ? basketSelectionSelectors.marketing : basketSelectors.marketing
            case DocumentTypes.CERTIFICATES:
                return basketSelection ? basketSelectionSelectors.certificates : basketSelectors.certificates
            default:
                return null;
        }
    }, [type, basketSelection])
    return datasheetSelector
}

export default useDatasheetSelector