import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useProductSearch from '../../hooks/useProductSearch';
import ProductSearchBar from './ProductSearchBar';

const HeaderSearchBar = () => {
    const { searchTerm, handleInput, handleSubmit } = useProductSearch();

    const location = useLocation()

    useEffect(() => {
        handleInput('')
    }, [location]);

    return (
        <ProductSearchBar 
            searchTerm={searchTerm}
            onSearchInput={handleInput}
            onSearchSubmit={handleSubmit} 
            secondaryVariant
        />
    );
}
 
export default HeaderSearchBar;