import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { msalADConfig, msalConfig } from '../../authConfig';
import { LOGIN_TYPES } from '../../store/types';

const msalADInstance = new PublicClientApplication(msalADConfig);
const msalB2CInstance = new PublicClientApplication(msalConfig);

export const MsalPropviderWrapper = ({children}) => {
    const loginType = useSelector((state) => state.auth.login_type);
    const [msalInstance, setMsalInstance] = useState(loginType === LOGIN_TYPES.B2C ? msalB2CInstance : msalADInstance);

    useEffect(() => {
        if(loginType === LOGIN_TYPES.B2C){
          setMsalInstance(msalB2CInstance);
        }
        else if(loginType === LOGIN_TYPES.AD) {
          setMsalInstance(msalADInstance);
        }
      }, [loginType])

    return (
        <MsalProvider instance={msalInstance}>
            {children}
        </MsalProvider>
    )
}

export default MsalPropviderWrapper;