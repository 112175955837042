import { Listbox } from '@headlessui/react';
import React from 'react';
import RequestStatus from './RequestStatus';
import { REQUEST_STATUS } from '../../store/types';
import {ReactComponent as IconChevronDown} from '../../assets/icons/Icon_Chevron-Down.svg'

const SingleSelectionDropdown = ({selection, options, onSelectionInput, optionsAreLoading, label, resetSelectionLabel = 'Reset Selection', noSelectionLabel = 'No selection made'}) => {
    return (
        <Listbox value={selection} onChange={onSelectionInput} disabled={optionsAreLoading} as='div' className='relative w-full'>
            <Listbox.Label className='mb-1 text-sm font-semibold'>{label}</Listbox.Label>
            <Listbox.Button className='flex justify-between w-full p-2 border border-gray-400 rounded text-start hover:border-cyan'>
                <span>{selection?.label ?? noSelectionLabel}</span>
                {optionsAreLoading ? 
                    <RequestStatus status={REQUEST_STATUS.PENDING}/>
                :
                    <IconChevronDown className='transition-transform ui-open:rotate-180' />
                }
            </Listbox.Button>
            <Listbox.Options className='absolute z-10 w-full py-2 mt-1 overflow-auto bg-white rounded max-h-40 shadow-card customScrollbarsLight'>
                <Listbox.Option 
                    key={'no_selection'} 
                    value={null} 
                    className='px-2 py-1 cursor-pointer text-gray hover:bg-gray-50'
                >
                    {resetSelectionLabel}
                </Listbox.Option>
                {options.map(option => (
                    <Listbox.Option key={option.value} value={option} className='px-2 py-1 cursor-pointer text-gray hover:bg-gray-50'>
                        {option.label}
                    </Listbox.Option>
                ))}
            </Listbox.Options>
        </Listbox>
    )
}
 
export default SingleSelectionDropdown;