import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router'

export default function useBacklink(productIndex) {
    const location = useLocation()
    const navigate = useNavigate()

    const navigateBack = useCallback(() => {navigate(location.state?.backLink ?? "/", productIndex ? {state: {productIndex: productIndex}} : {})}, [location.state?.backLink, navigate])
    
    return navigateBack
}