import { useCallback, useMemo, useState } from "react";

const useExtendableColumn = (defaultState = false) => {
    const [isExtended, setIsExtended] = useState(defaultState);

    const toggleExtension = useCallback(() => {
        setIsExtended(!isExtended)
    }, [isExtended])

    const columnWidthClass = useMemo(() => isExtended ? `w-[200%]` : `w-[50%]`, [isExtended])

    return [columnWidthClass, toggleExtension]
}

export default useExtendableColumn