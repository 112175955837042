import { Tab } from '@headlessui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import BackButton from '../../components/elements/BackButton';
import HeaderExtension from '../../components/elements/HeaderExtension';
import HorizontalTab from '../../components/elements/HorizontalTab';
import PrimaryButton from '../../components/elements/PrimaryButton';
import ProfileDetailsSection from './ProfileDetailsSection';
import DefaultDocumentsSettings from './DefaultDocumentsSettings'
import {ReactComponent as IconLogout} from '../../assets/icons/Icon_Logout.svg'
import {ReactComponent as IconAlert} from '../../assets/icons/Icon_Alert.svg'
import CustomerViewSection from './CustomerViewSection';
import useCustomerViewActive from '../../hooks/useCustomerViewActive';
import useLogout from '../../hooks/useLogout';
import ProfileDeletionSection from './ProfileDeletionSection';

const ProfilePage = ({completeDataPrompt}) => {

    const isInternal = useSelector(state => state.auth.user.internal)
    const customerViewActive = useCustomerViewActive()

    const logout = useLogout()

    return (
        <div className='flex flex-col min-h-[85vh] grow'>
            <HeaderExtension>
                <BackButton />
                <div className='container flex justify-between mb-6 mt-7'>
                    <div>
                        <h1 className='text-4xl font-bold'>Your Profile</h1>
                        {customerViewActive && <p className='flex items-center gap-1 mt-2 text-cyan text-semibold'><IconAlert />Customer view doesn't apply to this page.</p>}
                    </div>
                    <div>
                        <PrimaryButton title='' onClick={logout}>
                            <div className='flex items-center gap-1 flex-nowrap'>
                                <IconLogout className='mr-1' />
                                <p className='text-base '>Logout</p>
                            </div>
                        </PrimaryButton>
                    </div>
                </div>
            </HeaderExtension>
            <div className='py-4 bg-gray-50 grow'>
                <div>
                    <Tab.Group>
                        <Tab.List className='flex gap-6 border-b lg:gap-8 max-lg:pl-3 max-lg:overflow-x-scroll hiddenScrollbars border-b-gray-100 lg:container'>
                            <HorizontalTab>Profile Details</HorizontalTab>
                            {!completeDataPrompt && <HorizontalTab>Settings</HorizontalTab>}
                            {!completeDataPrompt && isInternal && <HorizontalTab>Customer View</HorizontalTab>}
                        </Tab.List>
                        <Tab.Panels className='container py-12'>
                            <Tab.Panel className='flex flex-col gap-6'>
                                <ProfileDetailsSection completeDataPrompt={completeDataPrompt} />
                                {!completeDataPrompt && !isInternal && <ProfileDeletionSection />}
                            </Tab.Panel>
                            <Tab.Panel><DefaultDocumentsSettings /></Tab.Panel>
                            <Tab.Panel><CustomerViewSection /></Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            </div>
        </div>
    )
}

export default ProfilePage;