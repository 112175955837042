import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import MobileNavListItem from '../../components/elements/styleWrapper/MobileNavListItem';
import useCustomerViewActive from '../../hooks/useCustomerViewActive';

const HeaderNavigationLinks = () => {

    const location = useLocation();
    const customerViewActive = useCustomerViewActive()

    return (
        <ul className='flex items-center justify-end gap-6 max-lg:px-6 max-lg:flex-col max-lg:py-20 navLinks'>
            {!customerViewActive && <MobileNavListItem><Link to="/news" state={{backLink: location}} data-tour="step-news">What's new</Link></MobileNavListItem>}
            <MobileNavListItem>
                <Link 
                    to="//forms.office.com/pages/responsepage.aspx?id=xIksPWJHQUSBhVBb7tr3rxyuiDHn4IhMi9qI2prNlrZUNElNVURESkdWNUdQMUw3VlRHNTRNQ0xVNC4u" 
                    target='_blank'
                    data-tour="step-feedback"
                >
                    Feedback
                </Link>
            </MobileNavListItem>
            <MobileNavListItem><Link to="/help" state={{backLink: location}} data-tour="step-faq">FAQ</Link></MobileNavListItem>
            <MobileNavListItem><Link to="//www.sasol.com/index.php/who-we-are/about-us" target='_blank'>About Sasol</Link></MobileNavListItem>
            <MobileNavListItem><Link to="/contact" state={{backLink: location}} data-tour="step-contact"> Contact</Link></MobileNavListItem>
        </ul>
    )
}
 
export default HeaderNavigationLinks;