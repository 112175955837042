import { useState } from 'react';

const useDatasheetFilterSelection = (initialState = []) => {
    const [selectedOptions, setSelectedOptions] = useState(initialState);

    function selectOption(option) {
        const index = selectedOptions.indexOf(option);
        if(index < 0) {
            setSelectedOptions([...selectedOptions, option]);
        }
        else {
            const copy = [...selectedOptions];
            copy.splice(index, 1);
            setSelectedOptions(copy);
        }
    }

    function clearSelection() {
        setSelectedOptions([]);
    }

    return [
        selectedOptions,
        selectOption,
        clearSelection,
    ]
};

export default useDatasheetFilterSelection;