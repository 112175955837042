import React, { useState, useEffect } from 'react';
import {ReactComponent as IconChevronDown} from "../../../assets/icons/Icon_Chevron-Down.svg";
import Checkbox from '../../elements/Checkbox';
import PropTypes from 'prop-types';
import {useTwScreens} from '../../../utilities/TailwindScreens'
import SecondaryButton from '../../elements/SecondaryButton';


const DatasheetFilterDropdown = (props) => {
    const {min} = useTwScreens()
    const [isOpen, setIsOpen] = useState(false); 
    const hasValidHighlights = (props.highlightedOptions?.length < props.options?.length) 
    function close() {
        setTimeout(setIsOpen, 100, false);
    }

    useEffect(() => {
        if(isOpen) {
            setTimeout(window.addEventListener,100, 'click', close);
            //window.addEventListener('click', close);
        } 
        else {
            window.removeEventListener('click', close);
        }
        return () => {
            window.removeEventListener('click', close)
        }
    }, [isOpen])



    return (
        <div className={`datasheetFilterDropdown ${isOpen ? 'text-blue' : 'text-gray'} text-sm font-semibold z-10 -m-2`}>
            <div className='flex items-center'>
                <label 
                    onClick={() => {setIsOpen(!isOpen)}}
                    className={`hover:bg-white rounded hover:border px-2 py-[2px] cursor-pointer flex items-center
                        ${isOpen ? 'border-blue bg-white border' : 'hover:border-gray-600'} gap-1`}
                >
                    {props.title} {props.selectedOptions.length > 0 ? `(${props.selectedOptions.length})` : ''}
                    <IconChevronDown className={`w-3 ${isOpen ? 'rotate-180' : ''} flex-shrink-0`}/>
                </label>
                {(props.selectedOptions.length > 0 && min('lg')) &&
                    <span className='ml-1 font-normal cursor-pointer clearButton hover:underline' onClick={props.onSelectionClear}>clear</span>
                }
            </div>
            {isOpen &&
            <div className='dropdownOptions z-20 bg-white max-lg:flex max-lg:flex-col max-lg:justify-between fixed max-lg:top-0 max-lg:right-0 max-lg:pt-6 max-lg:h-full max-lg:z-[9999] lg:absolute w-fit rounded border border-gray-200 p-2 lg:columns-3 lg:mt-[2px] shadow-card font-normal'>
                <ul className='max-lg:overflow-y-scroll'>
                {props.options?.map((option) => {
                    return (
                        <li className={`filterOption 
                                flex items-center px-2 py-2 lg:py-1 
                                hover:bg-gray-50 
                                `} 
                            key={option}
                            onClick={(e) => {e.stopPropagation()}}>
                            <span className="mr-1">
                                <Checkbox 
                                    checked={props.selectedOptions.includes(option)} 
                                    onChange={() => {props.onSelection(option)}}
                                    args={{id: option}}
                                    topMargin='top-px'
                                />
                            </span>

                            <label htmlFor={option}
                                className={`
                                    ${hasValidHighlights && props.highlightedOptions.includes(option) ? 'text-blue font-bold' : ''} 
                                    ${hasValidHighlights && !props.highlightedOptions.includes(option) ? 'text-gray-600' : ''} cursor-pointer
                                    `}
                            >
                                {option}
                            </label>
                        </li>
                    )
                })}
                </ul>
                <div className='flex gap-2 flex-nowrap lg:hidden min-w-[50vw]'>
                    <SecondaryButton title='Clear' onClick={props.onSelectionClear} className='justify-center basis-1/2' cyan solid />
                    <SecondaryButton title='Close' onClick={close} className='justify-center basis-1/2' />
                </div>
            </div>}
        </div>
        
    )
}

DatasheetFilterDropdown.propTypes = {
    title: PropTypes.string,
    options: PropTypes.array,
    highlightedOptions: PropTypes.array,
    selectedOptions: PropTypes.array,
    onSelection: PropTypes.func,
    onSelectionClear: PropTypes.func,
}

export default DatasheetFilterDropdown;