import React from 'react';
import CheckFilter from './CheckFilter';
import FilterSelectionList from './FilterSelectionList';

const FilterContainer = ({filterConfig}) => {
    return (  
        <div className='w-full flex flex-col gap-0.5 mb-5'>
            <div className='w-full px-4 py-2 bg-gray-100 rounded-t-md'>
                <p className='text-xs text-gray mb-1.5'>FILTER BY:</p>
                <CheckFilter
                    options={filterConfig.options}
                    selectedOptions={filterConfig.selectedOptions}
                    title={filterConfig.title}
                    onSelection={filterConfig.handleSelectionToggle}
                    onSelectionClear={filterConfig.handleSelectionClear}
                />
            </div>
            <div className='w-full bg-gray-100 px-4 pt-2.5 pb-4 rounded-b-md'>
                <FilterSelectionList selectedOptions={filterConfig.selectedOptions} onClear={filterConfig.handleSelectionClear} onDeselect={filterConfig.handleSelectionToggle} />
            </div>
        </div>
    );
}
 
export default FilterContainer;