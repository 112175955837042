import React from 'react';
import {ReactComponent as IconMenu} from '../../assets/icons/Icon_Menu.svg'
import {ReactComponent as IconClose} from '../../assets/icons/Icon_Close.svg'

const MenuButton = ({isOpen, onClick}) => {
    return (
        <button 
            className='relative p-2 border rounded text-cyan border-gray'
            onClick={onClick}
        >
            {isOpen ?
            <IconClose />
            :
            <IconMenu />
            }
        </button>
    )
}
 
export default MenuButton;