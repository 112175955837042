import PropType from "prop-types";
import React, { useMemo, useState } from "react";
import "./MarketingList.scss";
import MarketingListEntry from "./MarketingListEntry";
import { BsArrowDownUp } from "react-icons/bs";
import DocumentSelectionModal from "../products/document-selection/DocumentSelectionModal";
import Checkbox from "../elements/Checkbox";
import { useDocumentLeftBorderColor } from "../../hooks/useDocumentBorderColor";
import { DocumentTypes } from "../../store/types";
import useScrollSync from "../../hooks/useScrollSync";
import useExtendableColumn from "../../hooks/useExtendableColumn";
import { useTwScreens } from "../../utilities/TailwindScreens";
import MobileTableHeader from "../elements/MobileTableHeader";

const MarketingList = (props) => {
    const {data} = props 
    const {min} = useTwScreens()
    const [selectedMarketing, setSelectedMarketing] = useState([])
    const borderColorClass = useDocumentLeftBorderColor(DocumentTypes.MARKETING)
    const {handleScroll, addElement} = useScrollSync()
    const [extendableColumnWidth, ] = useExtendableColumn(true)

    const toggleSelection = (selection) => {
        const index = selectedMarketing.findIndex(
            (material) => material.id === selection.id
        );
        if (index < 0) {
            setSelectedMarketing([
                ...selectedMarketing,
                selection,
            ]);
        } else {
            const copy = [...selectedMarketing];
            copy.splice(index, 1);
            setSelectedMarketing(copy);
        }
    };

    function toggleSelectAll() {
        if(selectedMarketing.length === props.data.length) {
            setSelectedMarketing([])
        }
        else {
            setSelectedMarketing([...props.data])
        }
    }

    const firstHeaderCol = useMemo(() => {
        return (
            <label className="flex items-center h-6">
                Name
                <button
                    className="ml-2"
                    onClick={props.toggleSortingDirection}
                >
                    <BsArrowDownUp />
                </button>
            </label>
        )
    }, [props.toggleSortingDirection])

    return (
        <>
            <div className="w-full marketingList">
                {min('lg') ?
                <div className="text-sm font-semibold marketingListHeader marketingGridContainer text-gray">
                    <div className="flex items-center start-1">
                        <div className={`${borderColorClass} bg-gray-100 px-4 py-3 rounded-tl border-l-2`}>
                            <Checkbox checked={(data.length === selectedMarketing.length) && data.length > 0} onChange={toggleSelectAll} topMargin='top-px' />
                        </div>
                        <div className="px-4 py-3">
                            {firstHeaderCol}
                        </div>
                    </div>
                    <div className="flex items-center px-4 start-2">
                        <label className="flex items-center h-6">Market</label>
                    </div>
                    <div className="flex items-center px-4 start-3"></div>
                </div>
                :
                <MobileTableHeader
                    documentType={DocumentTypes.CERTIFICATES}
                    ref={addElement(0)}
                    extendableColumnWidth={extendableColumnWidth}
                    onSyncedScroll={handleScroll}
                    firstLabel={firstHeaderCol}
                    secondLabel='Market'
                />
                }
                <div className="rounded-bl datasheetListBody customScrollbars">
                    {data.length > 0 ? (
                        data.map((marketing, index) => {
                            return (
                                <MarketingListEntry
                                    key={marketing.id}
                                    marketing={marketing}
                                    onSelection={() => {
                                        toggleSelection(marketing)
                                    }}
                                    productName={props.productName}
                                    selected={selectedMarketing.some((entry) => entry.id === marketing.id)}
                                    ref={addElement(index + 1)}
                                    onSyncedScroll={handleScroll}
                                    extendableColumnWidth={extendableColumnWidth}
                                    toggleExtendableColumn={null}
                                />
                            );
                        })
                    ) : (
                        <div
                            className={`bg-white border-l-2 rounded-b border-l-gray px-4 py-3 flex gap-1 items-center text-sm`}
                        >
                            No Marketing Material found. Please change filter
                            settings.
                        </div>
                    )}
                </div>
            </div>
            {selectedMarketing.length > 0 &&
                <DocumentSelectionModal marketing={selectedMarketing} count={selectedMarketing.length} removeMarketing={toggleSelection} onClose={() => setSelectedMarketing([])} />
            }
        </>
    );
};

MarketingList.propTypes = {
    data: PropType.array,
    toggleSortingDirection: PropType.func,
}

export default MarketingList;
