import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { api } from '../../../../api/api'
import { set_user } from '../../../../store/actions/auth'

const SaveContactData = (props) => {

    const [phone] = useState(props.steps[props.telephoneStep]?.value ?? null)
    const [job] = useState(props.steps[props.jobStep]?.value ?? null)
    const [organization] = useState(props.steps[props.organizationStep]?.value ?? null)
    const dispatch = useDispatch()


    function saveData() {
        const data = {}
        if(phone) {
            data.telephone_number = phone
        }
        if(job) {
            data.job_title = job
        }
        if(organization) {
            data.organization = organization
        }
        console.log(phone, job, organization)
        api.updateUserData(data)
            .then(response => {
                dispatch(set_user(response.data));
                props.triggerNextStep({value: 'saved', trigger: props.trigger}); 

            })
            .catch(error => {
                console.log(error);
                props.triggerNextStep({value: 'saved', trigger: props.triggerOnError}); 
            })
    }

    useEffect(() => {
      saveData()
    }, [])
    

    return (<>Saving data in profile</>)
}

export default SaveContactData