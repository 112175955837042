import PropType from "prop-types";
import React, { useMemo } from "react";
import { BsArrowDownUp } from "react-icons/bs";
import "./CertificatesList.scss";
import CertificatesListEntry from "./CertificatesListEntry";
import Checkbox from "../elements/Checkbox";
import { useState } from "react";
import DocumentSelectionModal from "../products/document-selection/DocumentSelectionModal";
import useScrollSync from "../../hooks/useScrollSync";
import useExtendableColumn from "../../hooks/useExtendableColumn";
import { useTwScreens } from "../../utilities/TailwindScreens";
import MobileTableHeader from "../elements/MobileTableHeader";
import { DocumentTypes } from "../../store/types";


const CertificatesList = (props) => {

    const {min} = useTwScreens()
    const {data} = props
    const [selectedCertificates, setSelectedCertificates] = useState([]);

    const {handleScroll, addElement} = useScrollSync()
    const [extendableColumnWidth, toggleExtendableColumn] = useExtendableColumn()

    const toggleSelection = (selection) => {
        const index = selectedCertificates.findIndex(
            (ds) => ds.id === selection.id
        );
        if (index < 0) {
            setSelectedCertificates([
                ...selectedCertificates,
                selection,
            ]);
        } else {
            const copy = [...selectedCertificates];
            copy.splice(index, 1);
            setSelectedCertificates(copy);
        }
    };

    const selectAll = () => {
        const selection = [...data]
        setSelectedCertificates(selection);
        
    }


    const allSelected = (data.length === selectedCertificates.length) && data.length > 0;

    const toggleSelectAll = () => {
        if(allSelected) {
            setSelectedCertificates([])
        }
        else {
            selectAll();
        }
    }

    const firstHeaderColLabel = useMemo(() => {
        return (
            <label className="flex items-center w-full h-6 font-bold">
                <p>Type</p>
                <button
                    className="ml-2"
                    onClick={props.toggleSortingDirection}
                >
                    <BsArrowDownUp />
                </button>
            </label>
        )
    }, [props.toggleSortingDirection])


    return (
        <>
            <div className="w-full certificateList">
                {min('lg') ?
                <div className="text-sm font-semibold certificateListHeader certificatesGridContainer text-gray">
                    <div className="flex items-center start-1">
                        <div className={`bg-gray-100 px-4 py-3 rounded-tl`}>
                            <Checkbox checked={allSelected} onChange={toggleSelectAll} args={{id: "selectAll"+props.type}} topMargin='top-[2px]' />
                        </div>
                        <div className="px-4 py-3">
                            {firstHeaderColLabel}
                        </div>
                    </div>
                    
                    
                    <div className="flex items-center px-4 start-2">
                        <label className="flex items-center h-6 font-bold">Company</label>
                    </div>
                    <div className="flex items-center px-4 start-3">
                        <label className="flex items-center h-6 font-bold">Site</label>
                    </div>

                    <div className="flex items-center px-4 start-4">
                    </div>
                </div>
                :
                <MobileTableHeader
                    documentType={DocumentTypes.CERTIFICATES}
                    ref={addElement(0)}
                    extendableColumnWidth={extendableColumnWidth}
                    onSyncedScroll={handleScroll}
                    firstLabel={firstHeaderColLabel}
                    secondLabel='Company'
                    thirdLabel='Site'
                />
                }
                <div className="rounded-bl datasheetListBody customScrollbars">
                    {data.length > 0 ? (
                        data.map((certificate, index) => {
                            return (
                                <CertificatesListEntry
                                    key={certificate.id}
                                    certificate={certificate}
                                    onSelection={() => {
                                        toggleSelection(certificate);
                                    }}
                                    selected={selectedCertificates.some(cert => cert.id === certificate.id)} 
                                    ref={addElement(index + 1)}
                                    onSyncedScroll={handleScroll}
                                    extendableColumnWidth={extendableColumnWidth}
                                    toggleExtendableColumn={toggleExtendableColumn}
                                />
                            );
                        })
                    ) : (
                        <div
                            className={`bg-white border-l-2 rounded-b border-l-gray px-4 py-3 flex gap-1 items-center text-sm`}
                        >
                            No Certificates found. Please change filter settings.
                        </div>
                    )}
                </div>
            </div>
            {selectedCertificates.length > 0 &&
                <DocumentSelectionModal certificates={selectedCertificates} count={selectedCertificates.length} removeCertificate={toggleSelection} onClose={() => setSelectedCertificates([])} />
            }
        </>
    );
};

CertificatesList.propTypes = {
    data: PropType.array,
    toggleSortingDirection: PropType.func,
};

export default CertificatesList;
