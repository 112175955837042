import React, { useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import {useNavigate} from "react-router-dom";
import {ReactComponent as IconChevronRight} from "../../assets/icons/Icon_Chevron-Right.svg";
import SecondaryButton from "../elements/SecondaryButton";

const ProductsList = (props) => {
    const navigate = useNavigate();
    const focusedProductRef = useRef(null);

    useEffect(() => {
      if(props.focusedProductIndex) {
          const y = focusedProductRef.current?.getBoundingClientRect().top + window.pageYOffset - (window.innerHeight/2);
          window.scrollTo({top: y, behavior: 'smooth'});
        }
    }, [props.focusedProductIndex, props.list])
    

    const listItems = props.list.map((product, index) => {
        return (
            <div key={product.id} className="flex items-center justify-between gap-4 px-4 py-3 mb-2 bg-white rounded-lg shadow-card" ref={props.focusedProductIndex === index ? focusedProductRef : null}>
                <div className="h-fit">{(product.name && product.name !== '') ? product.name : 'Missing product name'}</div>
                <div>
                    <SecondaryButton cyan={true} onClick={() => {navigate("/products/"+product.slug, {state: {backLink: props.backLink, productIndex: index}})}}>
                        <p className="max-lg:hidden">View Product</p>
                        <IconChevronRight className='w-4 lg:w-6' />
                    </SecondaryButton>
                </div>
            </div>
        );
    });

    function handleLoadMore() {
        props.onLoadMore(Number(props.page) + 1);
    }

    return (
        <div className="productsList">
            <div className="w-full">
                {!props.reduced &&
                <div className="mb-2 text-sm font-semibold text-gray-800">
                    <div>
                        <div className="text-start">Product Name</div>
                    </div>
                </div>}
                <div>
                    {listItems.length ? listItems : props.reduced ? '' : "No Products found"}
                </div>
            </div>
            {props.more &&
            <div className="flex justify-center mt-3">
                <button 
                    className="px-6 py-2 text-center text-white rounded-lg bg-blue w-fit" 
                    onClick={handleLoadMore}
                >
                    <span type="button">Load more</span>
                </button>
            </div>
            }
        </div>
    );
}

ProductsList.propTypes = {
    /**
     * Link or location of searchpage
     */
    backLink: PropTypes.object,
    list: PropTypes.array,
    more: PropTypes.bool,
    focusedProductIndex: PropTypes.number,
    reduced: PropTypes.bool,
}

export default ProductsList;