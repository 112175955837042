import React, { Fragment } from 'react';
import {Dialog, Transition} from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux';
import { closeSidebar } from '../../store/slices/basket/basket';
import CloseButton from '../../components/elements/CloseButton';
import DocumentTypeTabs, {DocumentTypeTabOptions} from './DocumentTypeTabs';
import { useState } from 'react';
import DatasheetsBasketList from './DatasheetsBasketList';
import DocumentBasketList from './DocumentBasketList';
import PrimaryButton from '../../components/elements/PrimaryButton';
import { DocumentTypes } from '../../store/types';
import { useLocation, useNavigate } from 'react-router-dom';
import useHideChatbot from '../../hooks/useHideChatbot';

const BasketSidebar = () => {

    const isOpen = useSelector(state => state.basket.sidebarIsOpen)
    const dispatch = useDispatch()
    const [selectedTab, setSelectedTab] = useState(Object.keys(DocumentTypeTabOptions)[0]);
    const navigate = useNavigate()
    const location = useLocation()
    useHideChatbot(isOpen)

    function close() {
        //set datahseets as active tab so on next open it defaultly shows
        setSelectedTab(Object.keys(DocumentTypeTabOptions).find(key => DocumentTypeTabOptions[key] === DocumentTypeTabOptions.datasheets))
        dispatch(closeSidebar())
    }

    return (  
    <Transition appear show={isOpen} as={Fragment} >
        <Dialog onClose={close}>
            <Transition.Child
                as={Fragment}
            >
                {/* The backdrop, rendered as a fixed sibling to the panel container */}
                <div className="fixed inset-0 z-[100] bg-[#00000080] bg-opacity-50 w-full ha-full" aria-hidden="true" />
            </Transition.Child>
            {/* Full-screen scrollable container */}
            <div className="fixed inset-0 overflow-y-auto z-[110] bg-opacity-100">
                {/* Container to center the panel */}
                <div className="flex justify-end h-full overflow-hidden">
                    <Transition.Child
                        as={Fragment}
                        enterFrom='translate-x-2'
                        enter='transition-transform duration-75 ease-linear'
                        leave='transition-transform duration-400 ease-linear'
                        leaveTo='translate-x-full'
                    >
                        <Dialog.Panel as='div' className="z-[120] h-screen w-[28rem] text-blue bg-gray-50 flex flex-col">
                            <div className='flex items-center justify-between gap-4 px-6 py-4 bg-blue'>
                                <h2 className='font-semibold text-gray'>Basket</h2>
                                <CloseButton onClose={close} defaultColor='cyan'/>
                            </div>
                            <div className='w-full pl-6 mt-4 overflow-x-auto hiddenScrollbars'>
                                <DocumentTypeTabs selectedType={selectedTab} onSelection={setSelectedTab} small/>
                            </div>
                            <div className='flex-grow p-6 overflow-y-auto customScrollbars'>
                                {selectedTab === Object.keys(DocumentTypeTabOptions).find(key => DocumentTypeTabOptions[key] === DocumentTypeTabOptions.datasheets) &&
                                <DatasheetsBasketList reduced/>
                                }
                                {selectedTab === Object.keys(DocumentTypeTabOptions).find(key => DocumentTypeTabOptions[key] === DocumentTypeTabOptions.marketing) &&
                                <DocumentBasketList documentType={DocumentTypes.MARKETING} reduced/>
                                }
                                {selectedTab === Object.keys(DocumentTypeTabOptions).find(key => DocumentTypeTabOptions[key] === DocumentTypeTabOptions.certificates) &&
                                <DocumentBasketList documentType={DocumentTypes.CERTIFICATES} reduced/>
                                }
                            </div>
                            <div className='px-6 py-2'>
                                <PrimaryButton title='Show Basket' onClick={() => {close(); navigate('/basket', {state: {backLink: location}})}} />
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog> 
    </Transition>    
    )
}
 
export default BasketSidebar;