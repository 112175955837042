import { Tab } from '@headlessui/react';
import React from 'react';

const HorizontalTab = ({children}) => {
    return (
        <Tab className='relative py-4 transition-colors duration-300 hover:text-cyan text-blue ui-selected:text-cyan whitespace-nowrap'>
            <div className='flex items-center gap-1 text-xl'>
                {children}
            </div>
            <div className='ui-not-selected:hidden border-b-2 border-cyan rounded absolute bottom-[-1px] w-full' />
        </Tab>
    )
}
 
export default HorizontalTab;