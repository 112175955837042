import { useCallback, useState } from "react";
import { api } from "../api/api";
import GA4Helper from "../utilities/GA4Helper";

const useDownloadDocuments = () => {
    const [loadingDocuments, setLoadingDocuments] = useState(false)

    const downloadDocuments = useCallback((documents, productName) => {
        setLoadingDocuments(true);
        GA4Helper.downloadEvent(documents.pds?.length ?? [], documents.sds?.length ?? [], documents.ris?.length ?? [], documents.eri?.length ?? [], documents.marketing?.length ?? [], documents.certificates?.length ?? [])
        api.getDocuments(documents.pds, documents.sds, documents.ris, documents.eri, documents.marketing, documents.certificates)
        .then((response) => {
            const blob = new Blob([response.data], {type: "application/zip"});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "documents_" + (!productName ? 'Sasol' : productName) + ".zip"); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
            setLoadingDocuments(false);
        })
        .catch((error) => {
            console.log(error);
            setLoadingDocuments(false);
        })
    }, [])

    return {loadingDocuments, downloadDocuments}
}

export default useDownloadDocuments