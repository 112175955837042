import React from 'react';
import {ReactComponent as IconCheck} from "../../assets/icons/Icon_Check.svg";


const Checkbox = ({checked, onChange, topMargin='top-[-1px]' , args}) => {

    return (
        <label className='text-white relative cursor-pointer'>
            <input 
                type="checkbox" 
                className='appearance-none h-4 w-4 bg-white border mt-1 border-gray-400 rounded checked:border-cyan checked:bg-cyan bg-no-repeat bg-center bg-contain'
                checked={checked} 
                onChange={onChange}
                {...args}
            />
            <IconCheck className={`absolute ${topMargin} left-[0.125rem] h-3 w-3`} />
        </label>
    )
}

export default Checkbox;