import React, { forwardRef, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '../../components/elements/Checkbox';
import ExtendbaleColumnContainer from '../../components/elements/ExtendbaleColumnContainer';
import MobileTableHeader from '../../components/elements/MobileTableHeader';
import useDatasheetSelector from '../../hooks/useDatasheetSelector';
import { useDocumentLeftBorderColor } from '../../hooks/useDocumentBorderColor';
import useSyncedScrollableContainer from '../../hooks/useSyncedScrollableContainer';
import { addDocumentsToSelection, removeDocumentsFromSelection } from '../../store/slices/basket-selection/basketSelection';
import { DocumentTypes } from '../../store/types';
import { useTwScreens } from '../../utilities/TailwindScreens';

const BasketTableHeader = forwardRef((props, ref) => {

    const {documentIds, docType, product} = props
    const borderColor = useDocumentLeftBorderColor(docType)
    const {min} = useTwScreens()
    const dispatch = useDispatch()
    const documentSelector = useDatasheetSelector(docType)
    const documentEntities = useSelector(state => documentSelector.selectEntities(state))
    const documentSelectionSelector = useDatasheetSelector(docType, true)
    const selectedDocumentsIds = useSelector(state => documentSelectionSelector.selectIds(state))
    const allSelected = useMemo(() => {
        const selectedDocumentsCount = selectedDocumentsIds.filter(selectionId => !!documentEntities[selectionId]).length
        return (documentIds.length === selectedDocumentsCount && documentIds.length > 0)
    }, [documentEntities, documentIds.length, selectedDocumentsIds])

    const addAllDocsToSelection = useCallback(() => {
        function isType(type){
            return type === docType
        }
        const documents = documentIds.map(id => documentEntities[id])
        dispatch(addDocumentsToSelection(product, isType(DocumentTypes.PDS) ? documents : [], isType(DocumentTypes.SDS) ? documents : [], isType(DocumentTypes.RIS) ? documents : [], isType(DocumentTypes.ERI) ? documents : [], isType(DocumentTypes.MARKETING) ? documents : [], isType(DocumentTypes.CERTIFICATES) ? documents : []))
    }, [docType, dispatch, documentIds, product, documentEntities])

    const toggleSelectAll = useCallback(() => {
        if(allSelected) {
            const documents = documentIds.map(id => documentEntities[id])
            const productCollection = [DocumentTypes.PDS, DocumentTypes.SDS, DocumentTypes.RIS, DocumentTypes.ERI].includes(docType) ? [
                {
                    product: product, 
                    pds: docType === DocumentTypes.PDS ? documents : [],
                    sds: docType === DocumentTypes.SDS ? documents : [],
                    ris: docType === DocumentTypes.RIS ? documents : [],
                    eri: docType === DocumentTypes.ERI ? documents : [],
                }
            ]
            : 
            []
            dispatch(removeDocumentsFromSelection(productCollection, docType === DocumentTypes.MARKETING ? documents : [], docType === DocumentTypes.CERTIFICATES ? documents : []))
        }
        else {
            addAllDocsToSelection()
        }
    }, [addAllDocsToSelection, allSelected, dispatch, docType, documentEntities, documentIds, product])

    const firstLabel = useMemo(() => {
        switch (docType) {
            case DocumentTypes.PDS:
                return 'Sasol Source'
            case DocumentTypes.SDS:
            case DocumentTypes.RIS:
            case DocumentTypes.ERI:
                return 'Country'
            case DocumentTypes.MARKETING:
                return 'Name'
            case DocumentTypes.CERTIFICATES:
                return 'Type'
            default:
                return 'Label'
        }
    }, [docType])
    const secondLabel = useMemo(() => {
        switch (docType) {
            case DocumentTypes.PDS:
            case DocumentTypes.SDS:
            case DocumentTypes.RIS:
            case DocumentTypes.ERI:
                return 'Language'
            case DocumentTypes.MARKETING:
                return 'Market'
            case DocumentTypes.CERTIFICATES:
                return 'Company'
            default:
                return 'Label'
        }
    }, [docType])
    const thirdLabel = useMemo(() => {
        switch (docType) {
            case DocumentTypes.PDS:
            case DocumentTypes.SDS:
            case DocumentTypes.RIS:
            case DocumentTypes.ERI:
                return 'Material Numbers'
            case DocumentTypes.MARKETING:
                return ''
            case DocumentTypes.CERTIFICATES:
                return 'Site'
            default:
                return 'Label'
        }
    }, [docType])

    const fourthLabel = useMemo(() => {
        switch (docType) {
            case DocumentTypes.PDS:
            case DocumentTypes.SDS:
            case DocumentTypes.RIS:
            case DocumentTypes.ERI:
                return 'Date of upload'
            case DocumentTypes.MARKETING:
            case DocumentTypes.CERTIFICATES:
            default:
                return ''
        }
    }, [docType])

    const gridClass = useMemo(() => {
        switch (docType) {
            case DocumentTypes.PDS:
            case DocumentTypes.SDS:
            case DocumentTypes.RIS:
            case DocumentTypes.ERI:
                return 'datasheetTableGrid'
            case DocumentTypes.CERTIFICATES:
                return 'certificatesTableGrid'
            default:
                return 'marketingTableGrid'
        }
    }, [docType])

    if(min('lg')) {
        return (
            <div className={`text-sm font-bold ${gridClass} text-gray`}>
                <div className="flex items-center start-1">
                    <div className={`${borderColor} bg-gray-100 px-4 py-3 border-l-2`}>
                        <Checkbox checked={allSelected} onChange={toggleSelectAll} topMargin='top-px' />
                    </div>
                    <div className="px-4">
                        <label className="flex items-center">
                            {firstLabel}
                        </label>
                    </div>
                </div>
                <div className='h-full start-2'>
                    <label className='flex items-center h-full px-4'>
                        {secondLabel}
                    </label>
                </div>
                <div className='h-full start-3'>
                    <label className='flex items-center h-full px-4'>
                        {thirdLabel}
                    </label>
                </div>
                <div className='h-full start-4'>
                    <label className='flex items-center h-full px-4'>
                        {fourthLabel}
                    </label>
                </div>
            </div>
        )
    }
    else {
        return (
            <MobileTableHeader
                ref={ref}
                documentType={docType}
                extendableColumnWidth={props.extendableColumnWidth}
                onSyncedScroll={props.onSyncedScroll}
                firstLabel={firstLabel}
                secondLabel={secondLabel}
                thirdLabel={thirdLabel}
                fourthLabel={fourthLabel}
            />
        )
    }
    
})
 
export default BasketTableHeader;