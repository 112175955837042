
import { Navigate} from "react-router-dom";
import {Outlet} from "react-router-dom";

const ProtectCompleteProfileRoute = ({user}) => {

  function userProfileIsComplete() {
    if(user && !user.internal ) {
      return user.completed_profile;
    }
    else {
      return true;
    }
  }
  if(!userProfileIsComplete()) {
    return <Navigate to="/completeProfile"/>;
  }  
  return <Outlet />;
};

export default ProtectCompleteProfileRoute;