import React, { useEffect, useState } from "react";
import CertificatesList from "../../components/certificates/CertificatesList";
import HeaderExtension from "../../components/elements/HeaderExtension";
import { api } from "../../api/api";
import LoadMore from "../../components/elements/LoadMore";
import BackButton from "../../components/elements/BackButton";
import FilterContainer from "../../components/elements/FilterContainer";
import useSortingDirection from "../../hooks/useSortingDirection";

const CertificatesPage = () => {
    const [certificates, setCertificates] = useState([]);
    
    const [types, setTypes] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const [orderDirection,, toggleOrderDirection] = useSortingDirection()

    const getCertificateTypes = async () => {
        try {
            const response = await api.getCertificateTypes();
            setTypes(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const getCertificates = async selected_page => {
        setLoading(true);

        let params = {
            page: selected_page,
            order: orderDirection,
        };

        if (selectedTypes.length > 0) {
            params.type = selectedTypes.map((el) => encodeURIComponent(el.value) ).join(","); 
        }

        try {
            const response = await api.getCertificateList(params);
            if (selected_page) {
                setCertificates([...certificates, ...response.data.results]);
                setPage(selected_page);
            } else {
                setCertificates(response.data.results);
                setPage(1);
            }
            setTotalCount(response.data.count)
            setTotalPages(response.data.total_pages);
        } catch (error) {
            console.log(error);
        }

        setLoading(false);
    };

    function toggleTypeSelection(material) {
        const index = selectedTypes.indexOf(material);
        if (index < 0) {
            setSelectedTypes([...selectedTypes, material]);
        } else {
            const copy = [...selectedTypes];
            copy.splice(index, 1);
            setSelectedTypes(copy);
        }
    }

    useEffect(() => {
        getCertificates();
    }, [orderDirection, selectedTypes]);

    useEffect(() => {
        getCertificateTypes();
    }, []);

    return (
        <div className="certiticatesPage">
            <HeaderExtension>
                <BackButton />
            </HeaderExtension>
            <div className="certificatesContainer">
                <div className="container py-6">
                    <h1 className="text-4xl font-bold">Certificates</h1>
                </div>
            </div>
            <div className="certificatesContainer bg-gray-50 py-10 min-h-[55vh]">
                <div className="container flex flex-col gap-6">
                    
                    <FilterContainer
                        filterConfig={{
                            title: 'Types',
                            options: types,
                            selectedOptions: selectedTypes,
                            handleSelectionToggle: toggleTypeSelection,
                            handleSelectionClear: () => setSelectedTypes([]),
                        }}
                    />
                    <div className="flex gap-1">
                        <p className="font-semibold text-blue">Results:</p>
                        <p className="text-gray">{totalCount} results found</p>
                    </div>
                    <CertificatesList
                        data={certificates}
                        toggleSortingDirection={toggleOrderDirection}
                    />
                    {page < totalPages && (
                        <LoadMore
                            onClick={() => getCertificates(page + 1)}
                            loading={loading}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
export default CertificatesPage;
