import React, { useCallback, useMemo, useState } from 'react';
import { useDocumentColor } from '../../hooks/useDocumentBorderColor'
import useModal from '../../hooks/useModal';
import usePreloadDocuments from '../../hooks/usePreloadDocuments';
import { DocumentTypes } from '../../store/types';
import DocumentPreview from '../elements/DocumentPreview';
import Modal from '../elements/Modal';
import PreviewIcon from '../elements/PreviewIcon';
import {Link , useLocation} from 'react-router-dom'
import { useTwScreens } from '../../utilities/TailwindScreens';
const NewsListEntry = ({ newsEntry }) => {

    const location = useLocation()
    const {min, excludeFrom} = useTwScreens()

    const [showingPreviewButton, setShowingPreviewButton] = useState(false);
    const {isShowing, toggle} = useModal();
    const {downloadRequestStatus, provideDatasheetDownload, provideMarketingMaterialDownload} = usePreloadDocuments();
    const openPreview = useCallback(() => {
        if(min('md') && !isShowing) {
            toggle()
        }
    }, [isShowing, min, toggle])

    const color = useDocumentColor()
    const readableTimeDiffString = useMemo(() => {
        const currentDate = new Date()
        const updateDate = new Date(newsEntry.created_at)
        const diffTime =  Math.abs(currentDate - updateDate)
        const diffDays = (diffTime / (1000 * 60 * 60 * 24))
        return diffDays > 1 ? Math.floor(diffDays) + ' days ago' : Math.floor(diffTime / (60*60*1000)) + ' hours ago'
    }, [newsEntry.created_at])

    const isDatasheet = useMemo(() => {
        return (newsEntry.type === DocumentTypes.PDS || newsEntry.type === DocumentTypes.SDS || newsEntry.type === DocumentTypes.RIS) 
    }, [newsEntry.type])

    const downloadDocument = useCallback(() => {
        if(isDatasheet) {
            const country = newsEntry.type === DocumentTypes.RIS ? 'All' : newsEntry.document?.country ?? newsEntry.document?.source
            const shortType = Object.keys(DocumentTypes).find(key => DocumentTypes[key] === newsEntry.type)
            provideDatasheetDownload(newsEntry.document?.file, shortType, newsEntry.product?.name, newsEntry.document?.language, country)
        }
        else {
            provideMarketingMaterialDownload(newsEntry.document.file, newsEntry.document.file)
        }
        
    }, [isDatasheet, newsEntry.document?.country, newsEntry.document?.file, newsEntry.document?.language, newsEntry.document?.source, newsEntry.product?.name, newsEntry.type, provideDatasheetDownload, provideMarketingMaterialDownload])

    return (
        <>
            <div
                className={`w-full py-3 pr-7 rounded pl-7 ${newsEntry.read ? 'bg-white border-gray-50' : 'bg-cyan-150 border-cyan-150'} hover:bg-gray-25 md:cursor-pointer border hover:border-gray-25`}
                onMouseEnter={() => {if(min('md')){setShowingPreviewButton(true)}}}
                onMouseLeave={() => {if(min('md')){setShowingPreviewButton(false)}}}
            >
                <div className='flex justify-between gap-2' onClick={openPreview}>
                    <p className='text-sm font-semibold text-cyan'>{isDatasheet ? 'Datasheet' : 'Marketing Material'}</p>
                    <p className='text-sm text-gray'>{readableTimeDiffString}</p>
                </div>
                <div className='flex items-start justify-between gap-2 mt-2'>
                    <div className='flex flex-wrap items-center gap-3'>
                        <p className={`text-${color(newsEntry.type)} font-semibold text-sm border-l-2 border-l-${color(newsEntry.type)} px-1 `} onClick={openPreview}>{Object.keys(DocumentTypes).find(elem => DocumentTypes[elem] === newsEntry.type)}</p>
                        <p className='text-lg font-semibold text-blue'>
                            {isDatasheet ? 
                                <Link to={'/products/'+newsEntry.product?.slug} state={{backLink: location}}>{newsEntry.product?.name}</Link> 
                            : 
                                newsEntry.document?.name
                            }
                        </p>
                    </div>
                    <div className={!(showingPreviewButton || (min('md') && excludeFrom('lg'))) ? 'invisible' : ''} onClick={openPreview}>
                        <PreviewIcon onClick={openPreview}/>
                    </div>
                </div>
                <div className='mt-1 text-sm text-gray' onClick={openPreview}>
                    {isDatasheet &&
                        <p>
                            Language: {newsEntry.document?.language} | {newsEntry.type === DocumentTypes.PDS ? 'Sasol Source' : 'Country'}: {newsEntry.type === DocumentTypes.PDS ? newsEntry.document?.source : newsEntry.document?.country}
                        </p>
                    }
                    {newsEntry.type === DocumentTypes.MARKETING &&    
                        <p>{newsEntry.document?.document_format}</p>  
                    }
                </div>
            </div>
            {isShowing &&
            <Modal isShowing={isShowing} close={toggle}>
                <DocumentPreview 
                    document={newsEntry.document} 
                    product={newsEntry.product} 
                    docType={newsEntry.type} 
                    onDownload={downloadDocument} 
                    downloadStatus={downloadRequestStatus} 
                    onClose={toggle} 
                />
            </Modal>}
        </>
    );
}

export default NewsListEntry;