import useBacklink from "../../hooks/useBacklink";
import { ReactComponent as IconArrowLeft} from "../../assets/icons/Icon_Arrow-Left.svg";

const BackButton = ({productIndex}) => {

    const navigateBack = useBacklink(productIndex)

    return ( 
        <div 
            className="flex items-center gap-1 px-2 py-1 bg-gray-100 rounded-lg cursor-pointer hover:underline text-gray w-fit" 
            onClick={navigateBack}
        >
            <IconArrowLeft />
            <p>Back</p>
        </div>
     );
}
 
export default BackButton;