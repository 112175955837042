import React, { useEffect, useState } from "react";
import { api } from "../../api/api";
import ExploreCard from "../../components/search/ExploreCard";
import ProductsList from "../../components/search/ProductsList";
import ProductSearchBar from "../../components/search/ProductSearchBar";
import useProductSearch from "../../hooks/useProductSearch";
import {ReactComponent as IconDocument} from '../../assets/icons/Icon_Document.svg'
import {ReactComponent as IconCheckedList} from '../../assets/icons/Icon_Checked-List.svg'
import {Link} from 'react-router-dom'
import NewsList from "../../components/news/NewsList";
import useCustomerViewActive from "../../hooks/useCustomerViewActive";

const SearchPage = () => {
    const [frequentlyViewed, setfrequentlyViewed] = useState([]);
    const { searchTerm, handleInput, handleSubmit } = useProductSearch();

    const hideSections = useCustomerViewActive()

    useEffect(() => {
        const response = api.getFrequentlyViewedProducts();
        response
            .then((response) => {
                setfrequentlyViewed(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <div className="searchPage">
            <div className="searchContainer">
                <div className="container">
                    <div className="py-8 lg:py-16 lg:w-2/3 ">
                        <h1 className="mb-8 text-5xl font-bold">
                            Product Search
                        </h1>
                        <ProductSearchBar
                            onSearchInput={handleInput}
                            onSearchSubmit={handleSubmit}
                            searchTerm={searchTerm}
                        />
                        <div className="mt-14 lg:mt-16">
                            <p className="mb-2 text-sm text-gray">More? Explore our certificates or marketing materials:</p>
                            <div className="grid gap-2 lg:grid-cols-2">
                                <ExploreCard
                                    title="Certificates"
                                    link="/certificates"
                                    icon={<IconCheckedList className="w-9 h-9" />}
                                />

                                <ExploreCard
                                    title="Marketing Material"
                                    link="/marketing"
                                    icon={
                                        <IconDocument className="w-9 h-9" />
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {!hideSections &&
            <>
                <div className="frequentlyViewedContainer bg-gray-50 py-10 min-h-[55vh]">
                    <div className="container">
                        <h4 className="mb-8 text-2xl font-bold text-center">
                            Your most viewed products
                        </h4>
                        <div className="lg:mx-auto lg:max-w-[70vw] xl:max-w-[60vw]"> 
                            <ProductsList list={frequentlyViewed} />
                        </div>
                    </div>
                </div>
                <div className="bg-white py-14">
                    <div className="container flex flex-col gap-6 lg:items-center lg:flex-row">
                        <div className="lg:basis-2/5">
                            <h4 className="mb-2 text-2xl font-bold">What's new</h4>
                            <p className="mb-6">
                                Take a look at recent updates.
                            </p>
                            <Link to='/news' className="px-6 py-2 font-semibold text-white rounded-md bg-blue max-lg:hidden" >All Updates</Link>
                        </div>
                        <div className="flex-grow">
                            <NewsList listSize={5} markAsRead={false} paginationHidden/>
                        </div>
                        <div className="flex justify-center lg:hidden">
                            <Link to='/news' className="px-6 py-2 font-semibold text-white rounded-md bg-blue" >All Updates</Link>
                        </div>
                    </div>
                </div>
            </>
            }
        </div>
    );
};
export default SearchPage;
