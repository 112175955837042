import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useNavigate} from 'react-router'
import { api } from '../../api/api';
import PrimaryButton from '../../components/elements/PrimaryButton';
import SecondaryButton from '../../components/elements/SecondaryButton';
import ProfileInfoForm from '../../components/profile/ProfileInfoForm';
import useContactFormValidation from '../../hooks/useContactFormValidation';
import useFormData from '../../hooks/useFormData';
import { set_user } from '../../store/actions/auth';

const ProfileDetailsSection = ({completeDataPrompt}) => {

    const user = useSelector((state) => state.auth.user);
    const dispatchRedux = useDispatch();
    const navigate = useNavigate();

    const initialState = {
        representative_mail: {value: user?.sasol_sm_email ?? '', error: null},
        customer_phone: {value: user?.telephone_number ?? '', error: null},
        customer_organization: {value: user?.organization ?? '', error: null},
        customer_job_title: {value: user?.job_title ?? '', error: null},
    }

    const customer_name = user.name ? user.name : (`${user.first_name} ${user.last_name}`);

    const {state, dispatch, onChange, setError } = useFormData(initialState); 
    const {representative_mail, customer_phone, customer_organization, customer_job_title} = state;
    const [validateForm, formDataIsValid, isSasolMail] = useContactFormValidation(state, setError);
    const [errorPromt, setErrorPrompt] = useState(null);
    const [profileUpdatePending, setProfileUpdatePending] = useState(false);

    function insertUserData() {
        for(let field of Object.keys(initialState)) {
            dispatch({field: field, value: initialState[field]})
        } 
    }

    useEffect(() => {    
      insertUserData();
    }, [user])
    

    function checkSasolMail () {
        if(representative_mail.value.length > 0 && !isSasolMail(representative_mail.value)) {
            setError('representative_mail', 'Please enter a Sasol email address')
        }
    }

    function createNewUserObject() {
        return {
            sasol_sm_email: representative_mail.value,
            telephone_number: customer_phone.value,
            organization: customer_organization.value,
            job_title: customer_job_title.value,
            name: customer_name,
        };
    }

    function save() {
        validateForm();
        if(formDataIsValid()) {
            setProfileUpdatePending(true);
            api.updateUserData(createNewUserObject())
            .then((response) => {
                dispatchRedux(set_user(response.data));
                setProfileUpdatePending(false);
                if(completeDataPrompt){
                    navigate('/')
                }
            })
            .catch((error) => {
                console.log(error);
                setProfileUpdatePending(false);
                setErrorPrompt('An error occured. Please try again or contact us via the contact form.')
            })
        }
    }

    function reset() {
        insertUserData();
    }

    return (
        <div className='w-full p-6 bg-white rounded'>
                    {completeDataPrompt &&
                        <p className='py-2 text-sm font-semibold text-center text-gray'>Please complete your profile information. All fields are required.</p>
                    }
                    <h3 className='w-full pb-1 mb-4 uppercase border-b border-gray-400 text-gray'>Your personal details</h3>
                    {errorPromt &&
                        <p className='text-sm font-bold text-red-600'>{errorPromt}</p>
                    }
                    <div className='grid grid-cols-1 gap-3 pt-3 mb-3 lg:grid-cols-2'>
                        <div className='py-2'>
                            <p className='mb-1 text-sm font-bold'>Full name</p>
                            <p>{customer_name}</p>
                        </div>
                        <div className='py-2'>
                            <p className='mb-1 text-sm font-bold'>Email</p>
                            <p>{user?.email}</p>
                        </div>
                    </div>
                    <ProfileInfoForm
                        representativeMail={{fieldName: 'representative_mail',...representative_mail}}
                        phone={{fieldName: 'customer_phone', ...customer_phone}}
                        organization={{fieldName: 'customer_organization', ...customer_organization}}
                        jobTitle={{fieldName: 'customer_job_title', ...customer_job_title}}
                        onChange={onChange}
                        checkSasolMail={checkSasolMail}
                        readOnly={profileUpdatePending}
                    />
                    <div className='flex justify-center gap-2 mt-8 lg:justify-start'>
                        <SecondaryButton 
                            cyan solid
                            onClick={save} 
                            disabled={profileUpdatePending} 
                        >
                            <span className='px-2 py-1 font-semibold'>{profileUpdatePending ? 'Sending' : 'Save Changes'}</span>
                        </SecondaryButton>
                        <SecondaryButton 
                            color='gray-600'
                            onClick={reset} 
                            disabled={profileUpdatePending} 
                        >
                            <span className='px-2 py-1 font-semibold'>Reset All</span>
                        </SecondaryButton>
                    </div>
                </div>
    );
}
 
export default ProfileDetailsSection;