import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { ReactComponent as IconArrowLeft } from '../../assets/icons/Icon_Chevron-Left.svg';
import { ReactComponent as IconArrowRight } from '../../assets/icons/Icon_Chevron-Right.svg';
import { ReactComponent as IconDownload } from '../../assets/icons/Icon_Download.svg';
import { ReactComponent as IconZoomIn } from '../../assets/icons/Icon_Zoom-in.svg';
import { ReactComponent as IconZoomOut } from '../../assets/icons/Icon_Zoom-out.svg';
import { REQUEST_STATUS } from '../../store/types';
import RequestStatus from './RequestStatus';
import SecondaryButton from './SecondaryButton';
import CloseButton from './CloseButton'
import CollectDocumentButton from './CollectDocumentButton'
import useAccessToken from '../../hooks/useAccessToken';
import useDocumentName from '../../hooks/useDocumentName';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.2.16.105.js`;

const DocumentPreview = ({document, product, docType, onDownload, downloadStatus, onClose}) => {
    const [fileConfig, setFileConfig] = useState(null);
    const getAccessToken = useAccessToken();
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const canvasHeight = useMemo(() => Math.floor(window.innerHeight * 0.83), [window.innerHeight]);
    const canvasWidth = useMemo(() => Math.floor(window.innerWidth * 0.70), [window.innerWidth]);
    const [documentWidth, setDocumentWidth] = useState(0);
    const [documentHeight, setDocumentHeight] = useState(0);
    const computedWidth = useMemo(() => {
        if(documentHeight > 0 && documentWidth > 0) {
            const scaleFactor = Math.max(documentHeight/canvasHeight, documentWidth/canvasWidth)
            return documentWidth / scaleFactor
        }
        else {
            return canvasWidth
        }
        
    }, [canvasHeight, canvasWidth, documentHeight, documentWidth])
    const [scale, setScale] = useState(100);
    const upperScaleLimit = 251;
    const lowerScaleLimit = 0;
    const disableScaleUp = (scale >= upperScaleLimit) || pageCount === 0;
    const disableScaleDown = scale <= lowerScaleLimit || pageCount === 0;
    const documentNameCreator = useDocumentName()

    useEffect(() => {
        getAccessToken()
            .then(value => {
                setFileConfig({
                    url: document.file,
                    httpHeaders: {
                        Authorization: 'Bearer '+ value.accessToken,
                    }
                })
            })
            .catch(error => {console.log(error)})
    }, [document.file])

    function onDocumentLoadSuccess({ numPages }) {
        setPageCount(numPages);
      }

    function nextPage() {
        if(currentPage+1 <= pageCount) {
            setCurrentPage(currentPage+1);
        }
    }
    function prevPage() {
        if(currentPage-1 > 0) {
            setCurrentPage(currentPage-1);
        }
    } 

    function scaleUp() {
        if(scale < upperScaleLimit) {
            setScale(scale + 20)
        }
    }
    function scaleDown() {
        if(scale > lowerScaleLimit) {
            setScale(scale - 20)
        }
    }

    function manuallyAdjustPage(e) {
        const inputValue = parseInt(e.target.value)
        if(inputValue) {
            if(inputValue > pageCount) {
                setCurrentPage(pageCount)
            }
            else if(inputValue < 0) {
                setCurrentPage(0)
            }
            else {
                setCurrentPage(inputValue)
            }
        }
    }

    function onScaleInput(e) {
        const inputValue = parseFloat(e.target.value)
        if(inputValue)
        {
            if(inputValue > upperScaleLimit) {
                setScale(upperScaleLimit)
            }
            else if(inputValue < lowerScaleLimit) {
                setScale(lowerScaleLimit)
            }
            else {
                setScale(inputValue)
            }
        }
    }

    const setDocumentDimensions = useCallback(({originalHeight, originalWidth}) => {
        setDocumentHeight(originalHeight)
        setDocumentWidth(originalWidth)
    }, [])
    

    return (
        <div className='w-screen h-screen text-blue'>
            <div className='flex items-stretch gap-px bg-gray-100 rounded h-fit'>
                <p className='flex-grow px-4 py-3 bg-white rounded-l'>{documentNameCreator(docType, product?.name, document)}</p>
                <div className='flex items-center gap-2 px-6 py-2 bg-white text-gray'>
                    <button 
                        className={`p-2 border border-transparent rounded-md ${(currentPage-1 > 0) ? 'hover:bg-gray-50 hover:border-gray-400' : ''}`} 
                        onClick={prevPage}
                        disabled={currentPage === 1}
                    >
                        <IconArrowLeft className='w-4 h-4' />
                    </button>
                    <div className='flex items-center gap-2'>
                        <input 
                            className='px-2 py-1 border border-gray-400 w-[5ch] rounded-md appearance-none resize-x bg-gray-50 text-center' 
                            type="number" 
                            value={currentPage} 
                            onChange={manuallyAdjustPage} 
                        />
                        <p className=''>
                            | {pageCount}
                        </p>
                    </div>
                    <button 
                        className={`p-2 border border-transparent rounded-md ${(currentPage+1 <= pageCount) ? 'hover:bg-gray-50 hover:border-gray-400' : ''}`} 
                        onClick={nextPage}
                        disabled={currentPage === pageCount}
                    >
                        <IconArrowRight className='w-4 h-4' />
                    </button>
                </div>
                <div className='flex items-center justify-center gap-2 px-6 py-2 bg-white text-gray'>
                    <button 
                        className={`${disableScaleDown ? '' : 'hover:bg-gray-50 hover:border-gray-400'} p-2 border border-transparent rounded-md`} 
                        onClick={scaleDown} 
                        disabled={disableScaleDown} 
                    >
                        <IconZoomOut className='w-4 h-4' />
                    </button>
                    <div className='relative flex items-center gap-1'>
                        <input 
                            className='pl-2 pr-4 py-1 border border-gray-400 w-[8ch] rounded-md appearance-none resize-x bg-gray-50 text-center' 
                            type="number" 
                            value={scale} 
                            onChange={onScaleInput} 
                        />
                        <p className='absolute pointer-events-none right-2'>%</p>  
                    </div>
                    <button 
                        className={`${disableScaleUp ? '' : 'hover:bg-gray-50 hover:border-gray-400'} p-2 border border-transparent rounded-md`} 
                        onClick={scaleUp} 
                        disabled={disableScaleUp}
                    >
                       <IconZoomIn className='w-4 h-4' />
                    </button>
                </div>
                <div className='flex items-center px-4 py-3 bg-white rounded-r'>
                    <CloseButton onClose={onClose}/>
                </div>
            </div>
            <div className='flex items-center justify-between w-full gap-2 mt-4 px-14 h-[83vh]'>
                <div onClick={(prevPage)} 
                    className={`${(currentPage-1 > 0) ? 'bg-white text-gray cursor-pointer' : 'bg-white text-gray-200'} rounded-md p-2 shadow-card`}
                >
                    <IconArrowLeft className='w-4 h-4' />
                </div>
                <Document 
                    className='flex items-center h-full'
                    file={fileConfig}
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading={<div className='flex items-center px-6 py-2 bg-white rounded shadow text-gray'>Loading PDF...</div>}
                >
                    <Page 
                        onLoadSuccess={setDocumentDimensions} 
                        pageNumber={currentPage} 
                        className='documentPreview customScrollbars max-h-full overflow-auto max-w-[70vw]' 
                        scale={scale / 100} 
                        width={computedWidth} 
                    />
                </Document>
                <div onClick={nextPage} 
                    className={`${(currentPage+1 <= pageCount) ? 'bg-white text-gray cursor-pointer' : 'bg-white text-gray-200'} rounded-md p-2 shadow-card`}
                >
                    <IconArrowRight className='w-4 h-4' />
                </div>
            </div>
            <div className='flex justify-end mt-4 mx-14'>                
                <div className='flex items-center gap-2 p-2 bg-white rounded'>
                    <CollectDocumentButton document={document} product={product} documentType={docType} />
                    <SecondaryButton onClick={onDownload} cyan>
                        {downloadStatus === REQUEST_STATUS.IDLE ?
                            <IconDownload /> :
                            <RequestStatus status={downloadStatus}/>
                        }
                    </SecondaryButton>
                </div>
            </div>
            
        </div>
    )
}

export default DocumentPreview;