import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentSelectionModal from '../../components/products/document-selection/DocumentSelectionModal';
import useTotalBasketSelectionCount from '../../hooks/useTotalBasketSelectionCount';
import { basketSelectionSelectors, clear, removeDocumentFromSelection } from '../../store/slices/basket-selection/basketSelection';
import { removeDocumentsFromBasket } from '../../store/slices/basket/basket';
import { DocumentTypes } from '../../store/types';

const BasketSelectionModal = () => {

    const selectedPDS = useSelector(state => basketSelectionSelectors.pds.selectEntities(state))
    const selectedSDS = useSelector(state => basketSelectionSelectors.sds.selectEntities(state))
    const selectedRIS = useSelector(state => basketSelectionSelectors.ris.selectEntities(state))
    const selectedERI = useSelector(state => basketSelectionSelectors.eri.selectEntities(state))
    const selectedProducts = useSelector(state => basketSelectionSelectors.products.selectAll(state))
    const selectedMarketing = useSelector(state => basketSelectionSelectors.marketing.selectAll(state))
    const selectedCertificates = useSelector(state => basketSelectionSelectors.certificates.selectAll(state))
    const dispatch = useDispatch()

    const selectionCount = useTotalBasketSelectionCount()

    const datasheetSelection = useMemo(() => {
        return selectedProducts.length > 0 ?
        selectedProducts.map(product => {
            const pds = product.pds.map(id => selectedPDS[id])
            const sds = product.sds.map(id => selectedSDS[id])
            const ris = product.ris.map(id => selectedRIS[id])
            const eri = product.eri.map(id => selectedERI[id])
            return {
                product: product,
                pds: pds,
                sds: sds,
                ris: ris,
                eri: eri,
            }
        })
        :
        []
    }
    , [selectedERI, selectedPDS, selectedProducts, selectedRIS, selectedSDS])

    const close = useCallback(() => {
        dispatch(clear())
    }, [dispatch])

    const removeProductsFromBasket = useCallback(() => {
        dispatch(clear())
        dispatch(removeDocumentsFromBasket([...datasheetSelection], [...selectedMarketing], [...selectedCertificates]))
    }, [datasheetSelection, dispatch, selectedCertificates, selectedMarketing])

    const onRemoveDocumentFromSelection = useCallback((productId, documentType, documentId) => {
        dispatch(removeDocumentFromSelection(productId, documentType, documentId))
    }, [dispatch])

    return ( 
        selectionCount > 0 && 
        <DocumentSelectionModal 
            datasheets={datasheetSelection} 
            count={selectionCount} 
            removePds={(doc, productId) => onRemoveDocumentFromSelection(productId, DocumentTypes.PDS, doc.id)}
            removeSds={(doc, productId) => onRemoveDocumentFromSelection(productId, DocumentTypes.SDS, doc.id)}
            removeRis={(doc, productId) => onRemoveDocumentFromSelection(productId, DocumentTypes.RIS, doc.id)}
            removeEri={(doc, productId) => onRemoveDocumentFromSelection(productId, DocumentTypes.ERI, doc.id)}
            marketing={selectedMarketing}
            removeMarketing={(doc) => onRemoveDocumentFromSelection(null, DocumentTypes.MARKETING, doc.id)}
            certificates={selectedCertificates}
            removeCertificate={(doc) => onRemoveDocumentFromSelection(null, DocumentTypes.CERTIFICATES, doc.id)}
            onClose={close} 
            onRemoveFromBasket={removeProductsFromBasket} 
        />
     );
}
 
export default BasketSelectionModal;