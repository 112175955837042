import React from 'react';
import { useSelector } from 'react-redux';
import useCheckAndTrigger from '../../hooks/useCheckAndTrigger';

const UserDataCheck = (props) => {
    const user = useSelector((state) => state.auth.user);
    useCheckAndTrigger(user, props.triggerTrue, props.triggerFalse, props.triggerNextStep);
    
    return <span className='text-blue'>Checking for user profile</span>
}

export default UserDataCheck;