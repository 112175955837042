import {
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL,
  USER_UPDATED,
  LOGIN_TYPE_CHANGED,
  AUTH_ERROR_OCCURED,
  AUTH_ERROR_FIXED,
  USER_LOADING,
  LOGIN_TYPES
} from "./../types";

import { api } from "../../api/api";

export const load_user = () => async (dispatch) => {
  try {
    dispatch({type: USER_LOADING})
    const res = await api.getUser();
    dispatch({
      type: USER_LOADED_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: USER_LOADED_FAIL,
    });
  }
};

export const set_user = (user) => dispatch => {
  dispatch({
    type: USER_LOADED_SUCCESS,
    payload: user,
  });
}

export const agree_terms = () => async dispatch => {
  try {
    const res = await api.setTermAgreement(true);
    dispatch({
      type:USER_UPDATED,
      payload: res.data,
    });
  }
  catch (err) {
    console.log(err)
  }
}

export const startCustomerView = (customerValue) => async dispatch => {
  try {
    const res = await api.switchCustomerView(true, customerValue);
    dispatch({
      type: USER_UPDATED,
      payload: res.data,
    });
  }
  catch (err) {
    console.log(err)
  }
}

export const stopCustomerView = () => async dispatch => {
  try {
    const res = await api.switchCustomerView(false);
    dispatch({
      type: USER_UPDATED,
      payload: res.data,
    });
  }
  catch (err) {
    console.log(err)
  }
}

export const viewed_tour = () => async dispatch => {
  try {
    const res = await api.setTourViewed(true);
    dispatch({
      type:USER_UPDATED,
      payload: res.data,
    });
  }
  catch (err) {
    console.log(err)
  }
}

export const set_login_type = (login_type) => dispatch => {
  localStorage.setItem('login_type', login_type);
  dispatch({
    type: LOGIN_TYPE_CHANGED,
    payload: login_type,
  });
}

export const set_auth_error = (error) => dispatch => {
  localStorage.removeItem('login_type')
  dispatch({
    type: AUTH_ERROR_OCCURED,
    payload: error,
  });
}

export const remove_auth_error = () => dispatch => {
  dispatch({
    type: AUTH_ERROR_FIXED,
  });
}

export const logout = () => dispatch => {
  localStorage.removeItem('login_type')
  dispatch({
    type: AUTH_ERROR_OCCURED,
    payload: LOGIN_TYPES.DEFAULT,
  });
}
