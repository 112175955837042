import React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { basketSelectors } from '../../store/slices/basket/basket';

export const DocumentTypeTabOptions = {
    datasheets: 'Datasheets',
    marketing: 'Marketing Materials',
    certificates: 'Certificates'
}

const DocumentSelectionTab = ({optionKey, isActive, onSelection, count, small}) => {
    const color = isActive ? 'text-cyan' : 'text-blue'
    return (
        <button className={`${small ? 'py-3' : 'py-4'} hover:text-cyan ${color} transition relative whitespace-nowrap`} onClick={() => onSelection(optionKey)}>
            <div className={`flex gap-1 items-center ${small ? 'text-sm' : 'text-xl'}`}>
                <p>{DocumentTypeTabOptions[optionKey]}</p>
                <p className={`text-sm font-medium px-1.5 rounded h-fit ${isActive ? 'bg-cyan text-white' : 'bg-gray-100 text-gray'}`}>{count}</p>
            </div>
            <div className={`${isActive && 'border-b-2 border-cyan rounded absolute bottom-[-1px] w-full'}`} />
        </button>
    )
}

const DocumentTypeTabs = ({selectedType, onSelection, small = false}) => {

    const pdsCount = useSelector(state => basketSelectors.pds.selectTotal(state))
    const sdsCount = useSelector(state => basketSelectors.sds.selectTotal(state))
    const risCount = useSelector(state => basketSelectors.ris.selectTotal(state))
    const eriCount = useSelector(state => basketSelectors.eri.selectTotal(state))
    const marketingCount = useSelector(state => basketSelectors.marketing.selectTotal(state))
    const certificatesCount = useSelector(state => basketSelectors.certificates.selectTotal(state))
    const totalCounts = useMemo(() => {
        return {
            datasheets: pdsCount + sdsCount + risCount + eriCount,
            marketing: marketingCount,
            certificates: certificatesCount,
        }
    }, [certificatesCount, eriCount, marketingCount, pdsCount, risCount, sdsCount])

    return ( 
        <div className={`flex ${small ? 'gap-4' : 'gap-6 lg:gap-8'} border-b border-gray-100 max-lg:overflow-x-scroll hiddenScrollbars max-lg:pr-3`}>
            {Object.keys(DocumentTypeTabOptions).map(option => {
                return (
                    <DocumentSelectionTab key={option} optionKey={option} isActive={option === selectedType} onSelection={onSelection} count={totalCounts[option]} small={small} />
                )
            })}
        </div>
    )
}
 
export default DocumentTypeTabs;