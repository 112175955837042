import { Tab } from '@headlessui/react';
import React from 'react';

const DocumentTab = ({title, count}) => {
    return (  
        <Tab className='flex items-center gap-1 py-3 ui-selected:border-b-2 ui-selected:border-b-cyan w-fit'>
            <h3 className='transition-colors text-blue ui-selected:text-cyan hover:text-cyan duration:300'>{title}</h3>
            <div className='px-[0.375rem] bg-gray-100 text-gray ui-selected:text-white ui-selected:bg-cyan rounded text-sm'>{count}</div>
        </Tab>
    );
}
 
export default DocumentTab;