import React, { useCallback, useState } from 'react';
import PrimaryButton from '../../components/elements/PrimaryButton';
import useModal from '../../hooks/useModal'
import Modal from '../../components/elements/Modal'
import PopupCard from '../../components/elements/PopupCard';
import TextInputField from '../../components/elements/TextInputField';
import SecondaryButton from '../../components/elements/SecondaryButton';
import { api } from '../../api/api';
import useLogout from '../../hooks/useLogout';
import { REQUEST_STATUS } from '../../store/types';
import RequestStatus from '../../components/elements/RequestStatus';
import {ReactComponent as IconSuccess} from '../../assets/icons/Icon_Circle-check.svg'

const DELETION_KEY = 'delete'
const SUCCESS_STATUS = 'SUCCESS'

const ProfileDeletionSection = () => {

    const {toggle, isShowing} = useModal()
    const [inputValue, setInputValue] = useState('')
    const isReadyToDelete = inputValue === DELETION_KEY
    const logout = useLogout()
    const [deleteRequestStatus, setDeleteRequestStatus] = useState(REQUEST_STATUS.IDLE);

    const closeModal = useCallback(() => {
        if(isShowing && deleteRequestStatus !== REQUEST_STATUS.PENDING) {
            setInputValue('')
            toggle()
        }
    }, [deleteRequestStatus, isShowing, toggle])

    const deleteAccount = useCallback(() => {
        setDeleteRequestStatus(REQUEST_STATUS.PENDING)
        api.deleteUser()
        .then(() => {
            setDeleteRequestStatus(SUCCESS_STATUS)
            setTimeout(logout, 3000)
        })
        .catch(err => {
            setDeleteRequestStatus(REQUEST_STATUS.ERROR)
            console.log(err)
        })
    }, [logout])

    return (
        <>
            <div className='flex justify-center'>
                <div>
                    <PrimaryButton color='red-600' title='Delete Account' onClick={toggle}/>
                </div>
            </div>
            <Modal isShowing={isShowing} close={closeModal}>
                <div className='mt-[15vh]'>
                    {deleteRequestStatus === REQUEST_STATUS.IDLE &&
                    <PopupCard>
                        <div className='flex flex-col items-center gap-4'>
                            <h2 className='text-2xl font-semibold text-blue'>Do you really want to permanently delete this account?</h2>
                            <p className='text-gray'>If you want to proceed with the deletion of this account, type <span className='italic'>"{DELETION_KEY}"</span> into the input field below.</p>
                            <div className='w-full'><TextInputField value={inputValue} onChange={(e) => setInputValue(e.target.value)} error={null} /></div>
                            <div className='flex gap-2'>
                                <PrimaryButton color='red-600' disabled={!isReadyToDelete} title='Delete permanently' className='whitespace-nowrap' onClick={deleteAccount} />
                                <SecondaryButton title='Cancel deletion' className='flex-grow whitespace-nowrap' onClick={closeModal}/>
                            </div>
                        </div>
                    </PopupCard>
                    }
                    {deleteRequestStatus !== REQUEST_STATUS.IDLE &&
                    <PopupCard
                        icon={deleteRequestStatus === SUCCESS_STATUS ? <IconSuccess className='w-16 h-16' /> : <RequestStatus status={deleteRequestStatus} size={64} />}
                        title={deleteRequestStatus === REQUEST_STATUS.PENDING ? 'Deleting your account' : deleteRequestStatus === SUCCESS_STATUS ? 'Your account was successfully deleted' : 'There was an error. Please try again.'}
                        subline={deleteRequestStatus === SUCCESS_STATUS ? 'You are getting logged out' : ''}
                    />
                    }
                </div>
            </Modal>
        </>
    )
}
 
export default ProfileDeletionSection;