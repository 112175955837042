import { useReducer } from "react";

function reducer (state, {field, value}) {
    return {...state, [field]: {...state[field], ...value}};
}

const useFormData = (initialState) => {
    const [state, dispatch] = useReducer(reducer, {...initialState});
    function onChange(e) {
        dispatch({field: e.target.name, value: {value: e.target.value, error: null}});
    }
    function setError(field, errorMessage) {
        dispatch({field: field, value: {error: errorMessage}})
    }

    return {
        state,
        dispatch,
        onChange,
        setError,
    }
}

export default useFormData;