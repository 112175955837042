import SaveUserDataConfirm from "../../customMessages/user/SaveUserDataConfirm";
import UserDataCheck from "../../customMessages/user/UserDataCheck";
import UserDataConfirm from "../../customMessages/user/UserDataConfirm";
import { requiredNotBlank, requiredEmail } from "../../validators/validators";

function getUserSteps(nextStep, prefix) {
    const firstStepId = prefix + 'user_check';
    const dataStepId = prefix + 'user';
    return {
        firstStepId: firstStepId,
        dataStepId: dataStepId,
        steps: [
            {
                id: firstStepId,
                component: <UserDataCheck triggerTrue={prefix + 'user'} triggerFalse={prefix + 'user_name_question'} />,
                waitAction: true,
                replace: true,
            },
            {
                id: dataStepId,
                component: <UserDataConfirm 
                    trigger={nextStep} 
                    triggerOnDataChanged={prefix + 'save_user'} 
                    triggerOnCancel={prefix + 'updating_user_question'}
                    nameInputStep={prefix + 'user_name_input'}
                    mailInputStep={prefix + 'user_email_input'}
                    phoneInputStep={prefix + 'user_telephone_input'}
                    organizationInputStep={prefix + 'user_organization_input'}
                    jobInputStep={prefix + 'user_job_input'} />,
                asMessage: true,
                waitAction: true,
            },
            {
                id: prefix + 'updating_user_question',
                message: 'What information do you want to change',
                trigger: prefix + 'updating_user_options',
            },
            {
                id: prefix + 'updating_user_options',
                options: [
                    {value: 'name', label: 'Name', trigger: prefix + 'update_user_name_question'},
                    {value: 'email', label: 'Email', trigger: prefix + 'update_user_email_question'},
                    {value: 'phone', label: 'Telephone number', trigger: prefix + 'update_user_telephone_question'},
                    {value: 'job', label: 'Job title', trigger: prefix + 'update_user_job_question'},
                    {value: 'organization', label: 'Organization', trigger: prefix + 'update_user_organization_question'},
                ]
            },
            {
                id: prefix + 'update_user_name_question',
                message: 'What is your full name?',
                trigger: prefix + 'update_user_name_input',
            },
            {
                id: prefix + 'update_user_name_input',
                update: prefix + 'user_name_input',
                trigger: dataStepId,
            },
            {
                id: prefix + 'update_user_email_question',
                message: 'What is your email address?',
                trigger: prefix + 'update_user_email_input',
            },
            {
                id: prefix + 'update_user_email_input',
                update: prefix + 'user_email_input',
                trigger: dataStepId,
            },
            {
                id: prefix + 'update_user_telephone_question',
                message: 'What is your telephone number?',
                trigger: prefix + 'update_user_telephone_input',
            },
            {
                id: prefix + 'update_user_telephone_input',
                update: prefix + 'user_telephone_input',
                trigger: dataStepId,
            },
            {
                id: prefix + 'update_user_organization_question',
                message: 'What is the name of the organization you\'re working for?',
                trigger: prefix + 'update_user_organization_input',
            },
            {
                id: prefix + 'update_user_organization_input',
                update: prefix + 'user_organization_input',
                trigger: dataStepId,
            },
            {
                id: prefix + 'update_user_job_question',
                message: 'What is your job title?',
                trigger: prefix + 'update_user_job_input',
            },
            {
                id: prefix + 'update_user_job_input',
                update: prefix + 'user_job_input',
                trigger: dataStepId,
            },
            {
                id: prefix + 'user_name_question',
                message: 'What is your full name?',
                trigger: prefix + 'user_name_input',
            },
            {
                id: prefix + 'user_name_input',
                user: true,
                trigger: prefix + 'user_email_question',
                validator: requiredNotBlank,
            },
            {
                id: prefix + 'user_email_question',
                message: 'What is your email address?',
                trigger: prefix + 'user_email_input',
            },
            {
                id: prefix + 'user_email_input',
                user: true,
                trigger: prefix + 'user_telephone_question',
                validator: requiredEmail,
            },
            {
                id: prefix + 'user_telephone_question',
                message: 'What is your telephone number?',
                trigger: prefix + 'user_telephone_input',
            },
            {
                id: prefix + 'user_telephone_input',
                user: true,
                trigger: prefix + 'user_organization_question',
                validator: requiredNotBlank,
            },
            {
                id: prefix + 'user_organization_question',
                message: 'What is the name of the organization you\'re working for?',
                trigger: prefix + 'user_organization_input',
            },
            {
                id: prefix + 'user_organization_input',
                user: true,
                trigger: prefix + 'user_job_question',
                validator: requiredNotBlank,
            },
            {
                id: prefix + 'user_job_question',
                message: 'What is your job title?',
                trigger: prefix + 'user_job_input',
            },
            {
                id: prefix + 'user_job_input',
                user: true,
                trigger: prefix + 'user',
                validator: requiredNotBlank,
            },
            {
                id: prefix + 'save_user',
                component: <SaveUserDataConfirm trigger={nextStep} userValueStep={prefix + 'user'} />,
                asMessage: true,
                waitAction: true,
            },
        ]
    }
}

export default getUserSteps;