import { useCallback, useState } from "react";
import { ORDER_DIRECTION } from "../types";

const useSortingDirection = () => {
  const [sortingDirection, setSortingDirection] = useState(ORDER_DIRECTION.ASC);

  const toggleSortingDirection = useCallback(() => {
    const newDirection = sortingDirection === ORDER_DIRECTION.ASC ? ORDER_DIRECTION.DESC : ORDER_DIRECTION.ASC
    setSortingDirection(newDirection)
  }, [sortingDirection])

  return [sortingDirection, setSortingDirection, toggleSortingDirection]

}


export default useSortingDirection;