import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/logos/Primary_horizontal_logo_one_colour_white.svg";
import ProfileStatusButton from "../components/profile/ProfileStatusButton";
import HeaderSearchBar from "../components/search/HeaderSearchBar";
import BasketHeaderIcon from "../containers/basket/BasketHeaderIcon";
import ROUTES from "../routes/routes";
import { useTwScreens } from "../utilities/TailwindScreens";
import MobileHeader from "../containers/navigation/MobileHeader";
import HeaderNavigationLinks from "../containers/navigation/HeaderNavigationLinks";

const Header = () => {
    const location = useLocation();
    const {min}  = useTwScreens()

    const showingSearchBar = useMemo(() => ((location.pathname !== ROUTES.searchResults.fullPath) && (location.pathname !== ROUTES.home.fullPath)), [location.pathname])

    if(min('lg')) {
        return (
            <header 
                className="sticky top-0 z-30 flex items-center justify-between gap-6 px-4 text-white bg-blue h-header"
                data-tour="step-header"
            >
                <div className="flex items-center gap-6">
                    <Link to="/"><Logo className=" w-28 lg:w-40"/></Link>
                </div>
                <div className="flex items-center justify-end flex-grow gap-6">
                    <div className="flex-grow max-w-lg min-w-fit">
                        {showingSearchBar && <HeaderSearchBar />}
                    </div>
                    <HeaderNavigationLinks />
                    <BasketHeaderIcon backlink={location} />
                    <ProfileStatusButton />
                </div>
            </header>
        )
    }
    else {
        return <MobileHeader isSearchbarLocation={showingSearchBar} />
    }
}

export default Header;