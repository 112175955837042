import React, { forwardRef, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as IconDownload } from '../../assets/icons/Icon_Download.svg';
import { ReactComponent as IconExpand } from '../../assets/icons/Icon_Expand.svg';
import SecondaryButton from '../elements/SecondaryButton';
import Checkbox from '../elements/Checkbox';
import Modal from '../elements/Modal';
import useModal from '../../hooks/useModal';
import DocumentPreview from '../elements/DocumentPreview';
import RequestStatus from '../elements/RequestStatus'
import { DocumentTypes, REQUEST_STATUS } from '../../store/types';
import usePreloadDocuments from '../../hooks/usePreloadDocuments';
import CollectDocumentButton from '../elements/CollectDocumentButton';
import { useCallback } from 'react';
import { useDocumentLeftBorderColor } from '../../hooks/useDocumentBorderColor';
import { useTwScreens } from '../../utilities/TailwindScreens';
import useSyncedScrollableContainer from '../../hooks/useSyncedScrollableContainer';
import ExtendbaleColumnContainer from '../elements/ExtendbaleColumnContainer';
import PreviewIcon from '../elements/PreviewIcon';

const MarketingListEntry = forwardRef((props, ref) => {

    const {min} = useTwScreens()
    const scrollContainerRef = useSyncedScrollableContainer(ref)

    const {downloadRequestStatus, provideMarketingMaterialDownload} = usePreloadDocuments();
    const [showingPreviewButton, setShowingPreviewButton] = useState(false);

    const {isShowing, toggle} = useModal();

    const borderColorClass = useDocumentLeftBorderColor(DocumentTypes.MARKETING)
    
    const firstColumn = useMemo(() => props.marketing.name, [props.marketing.name])
    const secondColumn = useMemo(() => props.marketing.markets?.map((market, index) => {
        return (
            <div key={index} className='mr-2'>
                {market}
            </div>
        )
    }), [props.marketing.markets])

    const preview = useCallback(() => {
        toggle()
    }, [toggle])
    
    function downloadDatasheet() {
        provideMarketingMaterialDownload(props.marketing.file,props.marketing.name)
    }

    return (
        <>
            <div 
                className={`marketingListEntry ${min('lg') ? 'marketingGridContainer' : ''} mb-[1px] text-gray bg-white text-sm border-l-2 ${borderColorClass}`}
                onMouseEnter={() => setShowingPreviewButton(true)}
                onMouseLeave={() => setShowingPreviewButton(false)}
            >
                {min('lg') ?
                <>
                    <div className='flex start-1'>
                        <div className='flex items-center px-4 py-3 mr-px bg-white'>
                            <Checkbox checked={props.selected} onChange={props.onSelection} args={{id: 'select'+props.marketing.id}}  topMargin="top-[6px]" />
                        </div>
                        <div className='flex items-center justify-between flex-grow px-4 py-3 mr-px bg-white'>
                            <p className='text-base font-medium text-blue'>{firstColumn}</p>
                            {showingPreviewButton &&
                            <button onClick={preview}>
                                <PreviewIcon />
                            </button>
                            }
                        </div>
                    </div>
                    
                    <div className='start-2 bg-white py-3 px-4 mr-[1px] background-gradient flex flex-col col-end-4' >
                        {secondColumn}
                    </div>           
                    <div className='flex flex-wrap items-center justify-end gap-2 p-2 bg-white start-4'>
                        {downloadRequestStatus !== REQUEST_STATUS.IDLE &&
                            <RequestStatus status={downloadRequestStatus} />
                        }
                        <CollectDocumentButton document={props.marketing} documentType={DocumentTypes.MARKETING} />
                        <SecondaryButton 
                            onClick={downloadDatasheet} 
                            cyan={true} 
                        >
                            <IconDownload className='w-4' />
                        </SecondaryButton>
                    </div>
                </>
                :
                <div className='flex w-full text-base'>
                    <div className='p-2'>
                        <CollectDocumentButton document={props.marketing} documentType={DocumentTypes.MARKETING} />
                    </div>
                    <ExtendbaleColumnContainer width={props.extendableColumnWidth} onClick={props.toggleExtendableColumn}>
                        {firstColumn}
                    </ExtendbaleColumnContainer>
                    <div className='overflow-x-scroll hiddenScrollbars' ref={scrollContainerRef} onScroll={props.onSyncedScroll}>
                        <div className='flex h-full w-sideScrollable'>
                            <div className='flex w-full h-full px-4 py-3 background-gradient'>
                                {secondColumn}
                            </div>
                        </div>
                    </div>
                </div> 
                }
            </div>
            <Modal isShowing={isShowing} close={toggle}>
                <DocumentPreview 
                    document={props.marketing} 
                    product={null} 
                    docType={DocumentTypes.MARKETING} 
                    onDownload={downloadDatasheet} 
                    downloadStatus={downloadRequestStatus} 
                    onClose={toggle} 
                />
            </Modal>
        </>
        
    );
})

MarketingListEntry.propTypes = {
    /**
     * Datasheet object for the entry to display
     */
    marketing: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    onSelection: PropTypes.func,
}

export default MarketingListEntry