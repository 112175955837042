import { createAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { DocumentTypes } from "../../types";
import {addDatasheetIdToProduct, removeDatasheetIdFromProduct, getBasketCollectionByType, createAddDocumentPayload, createRemoveDocumentPayload, createAddDocumentsPayload, createRemoveDocumentsPayload} from '../../helper-functions/documentCollectionHelpers'

export const addDocumentToSelection = createAction('basketSelection/addDocument', createAddDocumentPayload)
export const removeDocumentFromSelection = createAction('basketSelection/removeDocument', createRemoveDocumentPayload)
export const addDocumentsToSelection = createAction('basketSelection/addDocuments', createAddDocumentsPayload)
export const removeDocumentsFromSelection = createAction('basketSelection/removeDocuments', createRemoveDocumentsPayload)

const productsAdapter = createEntityAdapter()
const pdsAdapter = createEntityAdapter()
const sdsAdapter = createEntityAdapter()
const risAdapter = createEntityAdapter()
const eriAdapter = createEntityAdapter()
const marketingAdapter = createEntityAdapter()
const certificatesAdapter = createEntityAdapter()

export const basketSelectionSelectors = {
    products: productsAdapter.getSelectors(state => state.basketSelection.products),
    pds: pdsAdapter.getSelectors(state => state.basketSelection.pds),
    sds: sdsAdapter.getSelectors(state => state.basketSelection.sds),
    ris: risAdapter.getSelectors(state => state.basketSelection.ris),
    eri: eriAdapter.getSelectors(state => state.basketSelection.eri),
    marketing: marketingAdapter.getSelectors(state => state.basketSelection.marketingMaterials),
    certificates: certificatesAdapter.getSelectors(state => state.basketSelection.certificates),
}

function getAdapterByType(type) {
    switch (type) {
        case DocumentTypes.PDS:
            return pdsAdapter
        case DocumentTypes.SDS:
            return sdsAdapter
        case DocumentTypes.RIS:
            return risAdapter
        case DocumentTypes.ERI:
            return eriAdapter
        case DocumentTypes.MARKETING:
            return marketingAdapter
        case DocumentTypes.CERTIFICATES:
            return certificatesAdapter
        default:
            return null;
    }
}

const initialState = {
    products: productsAdapter.getInitialState(),
    pds: pdsAdapter.getInitialState(),
    sds: sdsAdapter.getInitialState(),
    ris: risAdapter.getInitialState(),
    eri: eriAdapter.getInitialState(),
    marketingMaterials: marketingAdapter.getInitialState(),
    certificates: certificatesAdapter.getInitialState(),
}

const basketSelectionSlice = createSlice({
    name: 'basketSelection',
    initialState: initialState,
    reducers: {
        removeProduct(state, action) {
            const id = action.payload
            if(state.products.entities[id]) {
                pdsAdapter.removeMany(state.pds, state.products.entities[id].pds) 
                sdsAdapter.removeMany(state.sds, state.products.entities[id].sds)
                risAdapter.removeMany(state.ris, state.products.entities[id].ris)
                eriAdapter.removeMany(state.eri, state.products.entities[id].eri)
                productsAdapter.removeOne(state.products, id)
            }
        },
        clear(state) {
            productsAdapter.removeAll(state.products)
            pdsAdapter.removeAll(state.pds)
            sdsAdapter.removeAll(state.sds)
            risAdapter.removeAll(state.ris)
            eriAdapter.removeAll(state.eri)
            marketingAdapter.removeAll(state.marketingMaterials)
            certificatesAdapter.removeAll(state.certificates)
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addDocumentToSelection, (state, action) => {
                const {product, type, data} = action.payload
                if(type === DocumentTypes.PDS || type === DocumentTypes.SDS || type === DocumentTypes.RIS || type === DocumentTypes.ERI) {
                    addDatasheetIdToProduct(data.id, type, product, state, productsAdapter)
                }
                getAdapterByType(type)?.addOne(getBasketCollectionByType(type, state), data)                
            })
            .addCase(removeDocumentFromSelection, (state, action) => {
                const {productId, type, id} = action.payload
                if(type === DocumentTypes.PDS || type === DocumentTypes.SDS || type === DocumentTypes.RIS || type === DocumentTypes.ERI) {
                    const product = state.products.entities[productId]
                    if(product) {
                        removeDatasheetIdFromProduct(id, type, product, state, productsAdapter)
                    }
                }
                getAdapterByType(type)?.removeOne(getBasketCollectionByType(type, state), id)
            })
            .addCase(addDocumentsToSelection, (state, action) => {
                const {product, pds, sds, ris, eri, marketing, certificates} = action.payload
                if(product) {
                    pds.forEach((entry) => addDatasheetIdToProduct(entry.id, DocumentTypes.PDS, product, state, productsAdapter))
                    sds.forEach((entry) => addDatasheetIdToProduct(entry.id, DocumentTypes.SDS, product, state, productsAdapter))
                    ris.forEach((entry) => addDatasheetIdToProduct(entry.id, DocumentTypes.RIS, product, state, productsAdapter))
                    eri.forEach((entry) => addDatasheetIdToProduct(entry.id, DocumentTypes.ERI, product, state, productsAdapter))

                    pdsAdapter.addMany(state.pds, pds)
                    sdsAdapter.addMany(state.sds, sds)
                    risAdapter.addMany(state.ris, ris)
                    eriAdapter.addMany(state.eri, eri)
                }
                marketingAdapter.addMany(state.marketingMaterials, marketing)
                certificatesAdapter.addMany(state.certificates, certificates)
            })
            .addCase(removeDocumentsFromSelection, (state, action) => {
                const {productCollections, marketing, certificates} = action.payload
                productCollections.forEach((productCollection) => {
                    const product = state.products.entities[productCollection.product.id]
                    productCollection.pds.forEach((entry) => removeDatasheetIdFromProduct(entry.id, DocumentTypes.PDS, product, state, productsAdapter))
                    productCollection.sds.forEach((entry) => removeDatasheetIdFromProduct(entry.id, DocumentTypes.SDS, product, state, productsAdapter))
                    productCollection.ris.forEach((entry) => removeDatasheetIdFromProduct(entry.id, DocumentTypes.RIS, product, state, productsAdapter))
                    productCollection.eri.forEach((entry) => removeDatasheetIdFromProduct(entry.id, DocumentTypes.ERI, product, state, productsAdapter))

                    pdsAdapter.removeMany(state.pds, productCollection.pds.map(d => d.id))
                    sdsAdapter.removeMany(state.sds, productCollection.sds.map(d => d.id))
                    risAdapter.removeMany(state.ris, productCollection.ris.map(d => d.id))
                    eriAdapter.removeMany(state.eri, productCollection.eri.map(d => d.id))
                })
                marketingAdapter.removeMany(state.marketingMaterials, marketing.map(d => d.id))
                certificatesAdapter.removeMany(state.certificates, certificates.map(d => d.id))
            })
    }
})

export const {removeProduct, clear} = basketSelectionSlice.actions

export default basketSelectionSlice.reducer