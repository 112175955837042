import RepresentativeCheck from "../../customMessages/sasol-representative/RepresentativeCheck";
import RepresentativeConfirm from "../../customMessages/sasol-representative/RepresentativeConfirm";
import { requiredSasolEmail } from "../../validators/validators";

function getSasolRepresentativeSteps(nextStep, prefix) {
    const firstStepId = prefix + 'rep_check';
    const dataStepId = prefix + 'rep';
    return {
        firstStepId: firstStepId,
        dataStepId: dataStepId,
        steps: [
            {
                id: firstStepId,
                component: <RepresentativeCheck triggerOnTrue={dataStepId} triggerOnFalse={prefix + 'rep_question'} />,
                waitAction: true,
                replace: true,
            },
            {
                id: dataStepId,
                component: <RepresentativeConfirm trigger={nextStep} triggerInput={prefix + 'rep_question'} repInputStep={prefix + 'rep_input'}/>,
                asMessage: true,
                waitAction: true,
            },
            {
                id: prefix + 'rep_question',
                message: 'What is your Sasol S&M representative\'s email?',
                trigger: prefix + 'rep_input',
            },
            {
                id: prefix + 'rep_input',
                user: true,
                trigger: dataStepId,
                validator: requiredSasolEmail,
            },
            
        ]
    }
}

export default getSasolRepresentativeSteps;