import React from 'react';

const ContactFormSummary = (props) => {
    const {steps} = props;
    return (
        <div>
            <div className='mb-1 font-semibold'>
                Your message:
            </div>
            <div className='mb-2'>
                {steps[props.messageStep].value}
            </div>
            <div className='mb-1 font-semibold'>
                Sasol sales representative email:
            </div>
            <div>
                {steps[props.repStep].value}
            </div>
        </div>
    )
}

export default ContactFormSummary;