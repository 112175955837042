import { useImperativeHandle, useRef } from "react"

const useSyncedScrollableContainer = (ref) => {
    const scrollContainerRef = useRef(null)
    useImperativeHandle(ref, () => {
        return {
            setScrollLeft(scrollLeft) {
                scrollContainerRef.current.scrollLeft = scrollLeft
            }
        }
    }, [])

    return scrollContainerRef
}

export default useSyncedScrollableContainer