import React from "react";
import {Outlet} from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import ContactChatbot from "../containers/chatbot/ContactChatbot";
/*
* Main layout including header and footer
* WIP
*/
export class MainLayout extends React.Component {
    render() {
        return (
            <div className="mainLayout min-h-screen flex flex-col justify-between">
                <Header />
                <div className="mainContainer text-blue grow"><Outlet /></div>
                <ContactChatbot />
                <Footer></Footer>
            </div>
        );
    }
} 