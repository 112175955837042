export function requiredNotBlank(value) {
    if(value && value.trim().length > 0) {
        return true;
    }
    else {
        return 'Input required'
    }
}

export function requiredEmail(value) {
    const isEmail = /\S+@\S+\.\S+/.test(value);
    if(value && value.trim().length > 0 && isEmail) {
        return true;
    }
    else {
        return 'Please input a valid email address'
    }
}
export function requiredSasolEmail(value) {
    const isEmail = /\S+@\S*sasol\.com/.test(value);
    if(value && value.trim().length > 0 && isEmail) {
        return true;
    }
    else {
        return 'Please input a Sasol email address'
    }
}