import { LogLevel } from "@azure/msal-browser";


const tenantName = window.__RUNTIME_CONFIG__.REACT_TENANT_NAME
const userFlow=  window.__RUNTIME_CONFIG__.REACT_USERFLOW_NAME
const clientId = window.__RUNTIME_CONFIG__.REACT_FRONTEND_CLIENT_ID
const scope = window.__RUNTIME_CONFIG__.REACT_BACKEND_SCOPE
const adScope = window.__RUNTIME_CONFIG__.REACT_AD_BACKEND_SCOPE
const adClientId = window.__RUNTIME_CONFIG__.REACT_AD_FRONTEND_CLIENT_ID
const adTenantId = window.__RUNTIME_CONFIG__.REACT_AD_TENANT_ID


export const b2cPolicies = {
    names: {
        signUpSignIn: userFlow,
    },
    authorities: {
        signUpSignIn: {
            authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${userFlow}`,
        },
    },
    authorityDomain: `${tenantName}.b2clogin.com`
};



/**
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = {
    auth: {
        clientId: clientId,
        authority: b2cPolicies.authorities.signUpSignIn.authority, 
        knownAuthorities: [b2cPolicies.authorityDomain], 
        postLogoutRedirectUri: "/login",
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {		
                    case LogLevel.Error:		
                        console.error(message);		
                        return;		
                    case LogLevel.Info:		
                        console.info(message);		
                        return;		
                    case LogLevel.Verbose:		
                        console.debug(message);		
                        return;		
                    case LogLevel.Warning:		
                        console.warn(message);		
                        return;
                    default:
                        return;
                }	
            }	
        }	
    }
};

/**
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: [
        scope,
    ],
};

export const msalADConfig = {
    auth: {
      clientId: adClientId,
      authority: `https://login.microsoftonline.com/${adTenantId}/`,
      postLogoutRedirectUri: "/login",
    },
    cache: {
      cacheLocation: "localStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {		
                    case LogLevel.Error:		
                        console.error(message);		
                        return;		
                    case LogLevel.Info:		
                        console.info(message);		
                        return;		
                    case LogLevel.Verbose:		
                        console.debug(message);		
                        return;		
                    case LogLevel.Warning:		
                        console.warn(message);		
                        return;
                    default:
                        return;
                }	
            }	
        }	
    },
 };
 export const loginADRequest = {
    scopes: [adScope]
}

