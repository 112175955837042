import { useEffect, useState } from "react";
import { api } from "../api/api";

const useProductSearchSuggestions = (searchInput, onSuggestionsFound, onNoSuggestionsFound) => {

    const [suggestions, setSuggestions] = useState([])
    const [suggestionsCount, setSuggestionsCount] = useState(0)

    useEffect(() => {
        const searchString = searchInput.trim();
        if(searchString.length > 0) {
            const response = api.getSuggestion(searchString)
            response.then(res => {
                if(res.data.results.length > 0){
                    setSuggestions(res.data.results);
                    setSuggestionsCount(res.data.count);
                    if(onSuggestionsFound){
                        onSuggestionsFound()
                    }
                    
                }
                else {
                    setSuggestions([]);
                    setSuggestionsCount(0);
                    if(onNoSuggestionsFound){
                        onNoSuggestionsFound()
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
        else {
            setSuggestions([])
            setSuggestionsCount(0)
            if(onNoSuggestionsFound){
                onNoSuggestionsFound()
            }
        }
    }, [searchInput])

    return {suggestions, suggestionsCount}
}
 
export default useProductSearchSuggestions;