import React from 'react';
import BackButton from '../../components/elements/BackButton';
import HeaderExtension from '../../components/elements/HeaderExtension';
import SecondaryButton from '../../components/elements/SecondaryButton';
import {ReactComponent as IconDownload} from '../../assets/icons/Icon_Download.svg'
import {ReactComponent as IconShare} from '../../assets/icons/Icon_Share.svg'
import {ReactComponent as IconTrash} from '../../assets/icons/Icon_Trash.svg'
import { useDispatch } from 'react-redux';
import { clear, removeDocumentFromBasket } from '../../store/slices/basket/basket';
import { clear as clearSelection } from '../../store/slices/basket-selection/basketSelection';
import useTotalBasketCount from '../../hooks/useTotalBasketCount'
import useCompleteBasketCollection from '../../hooks/useCompleteBasketCollection';
import useModal from '../../hooks/useModal';
import Modal from '../../components/elements/Modal';
import ShareForm from '../../components/products/document-selection/ShareForm';
import useGroupedBasketCollection from '../../hooks/useGroupedBasketCollection';
import { DocumentTypes } from '../../store/types';
import useDownloadDocuments from '../../hooks/useDownloadDocuments';

const BasketHeaderExtension = () => {

    const dispatch = useDispatch()
    const totalCount = useTotalBasketCount()
    const {isShowing, toggle} = useModal()

    const {loadingDocuments, downloadDocuments} = useDownloadDocuments()

    function onClear() {
        dispatch(clear())
        dispatch(clearSelection())
    }
    const groupedCollection = useGroupedBasketCollection()
    const basketCollection = useCompleteBasketCollection()

    function downloadAll() {
        downloadDocuments(basketCollection)
    }

    return ( 
    <HeaderExtension>
        <div>
            <BackButton />
        </div>
        {totalCount > 0 &&
            <>
                <div className='container flex items-center justify-between mt-4 mb-6'>
                    <h2 className='text-4xl font-bold '>Basket</h2>
                    <div className='flex gap-2'>
                        <SecondaryButton onClick={onClear}>
                            <div className='flex items-center gap-2 px-1 max-lg:py-2 lg:px-4'>
                                <IconTrash className='w-6 h-6' />
                                <span className='max-lg:hidden whitespace-nowrap'>Clear basket</span>
                            </div>
                        </SecondaryButton>
                        <SecondaryButton onClick={toggle}>
                            <div className='flex items-center gap-2 px-1 max-lg:py-2 lg:px-4'>
                                <IconShare className='w-6 h-6' />
                                <span className='max-lg:hidden whitespace-nowrap'>Share all via email</span>
                            </div>
                        </SecondaryButton>
                        {loadingDocuments ?
                        <SecondaryButton>Loading</SecondaryButton>
                        :
                        <SecondaryButton cyan solid onClick={downloadAll}>
                            <div className='flex items-center gap-2 px-1 max-lg:py-2 lg:px-4'>
                                <IconDownload className='w-6 h-6' />
                                <span className='max-lg:hidden'>Download all</span>
                            </div>
                        </SecondaryButton>}
                    </div>
                </div>
                <Modal isShowing={isShowing} close={toggle}>
                    <ShareForm
                        datasheets={groupedCollection.datasheets}
                        removePds={(doc, productId) => dispatch(removeDocumentFromBasket(productId, DocumentTypes.PDS, doc.id))}
                        removeSds={(doc, productId) => dispatch(removeDocumentFromBasket(productId, DocumentTypes.SDS, doc.id))}
                        removeRis={(doc, productId) => dispatch(removeDocumentFromBasket(productId, DocumentTypes.RIS, doc.id))}
                        removeEri={(doc, productId) => dispatch(removeDocumentFromBasket(productId, DocumentTypes.ERI, doc.id))}
                        marketing={groupedCollection.marketing}
                        removeMarketing={(doc) => dispatch(removeDocumentFromBasket(DocumentTypes.MARKETING, doc.id))}
                        certificates={groupedCollection.certificates}
                        removeCertificate={(doc) => dispatch(removeDocumentFromBasket(DocumentTypes.CERTIFICATES, doc.id))}
                        onClose={toggle}
                    />
                </Modal>
            </>
        }
    </HeaderExtension>
    );
}
 
export default BasketHeaderExtension;