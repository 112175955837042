import { CLOSE_CHATBOT, OPEN_CHATBOT, RESET_CHATBOT } from "../types";

const initialState = {
    key: 'chatbot_init',
    isOpen: false,
};

const chatbot = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {

        case(OPEN_CHATBOT): {
            return {
                ...state,
                isOpen: true,
            };
        }
        case(CLOSE_CHATBOT): {
            return {
                ...state,
                isOpen: false,
            };
        }
        case(RESET_CHATBOT): {
            return {
                ...state,
                key: 'chatbot_' + payload,
            };
        }
        default:
            return state;
    }
}

export default chatbot;