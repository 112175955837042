import React from 'react';
import {ReactComponent as IconBasket} from  '../../assets/icons/Icon_Basket.svg'
import {ReactComponent as IconAlert} from  '../../assets/icons/Icon_Alert.svg'
import { useNavigate } from 'react-router-dom'
import PrimaryButton from '../elements/PrimaryButton'

const EmptyBasketCard = ({title = 'Your basket is empty', subtitle = 'Search for products, marketing materials and certificates and add documents to your basket ', oneLine = false}) => {

    const navigate = useNavigate()

    return (  
        <div className='flex flex-col items-center w-full text-center'>
            {oneLine ?
                <div className='flex items-center gap-1'>
                    <IconAlert className='w-4 text-gray' />
                    <span>{title}</span>
                </div>
                :
                <>
                    <IconBasket className='w-10 h-10 mb-6 text-cyan' />
                    <p className='mb-2 text-4xl font-bold'>{title}</p>
                    <p className='mb-6 text-xl font-light text-center'>{subtitle}</p>
                    <div><PrimaryButton onClick={() => {navigate('/')}} title='Back to homepage'/></div>
                </>
            }
        </div>
    );
}
 
export default EmptyBasketCard;