import React from 'react'
import BackButton from '../components/elements/BackButton'
import HeaderExtension from '../components/elements/HeaderExtension'
import NewsSection from '../components/news/NewsSection'

const NewsPage = () => {
    return (
        <>
            <HeaderExtension><BackButton /></HeaderExtension>
            <h1 className='container mt-4 text-4xl font-bold'>What's new</h1>
            <div className='my-8'><NewsSection /></div>
        </>
    )
}
export default NewsPage