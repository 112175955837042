import React from 'react';
import { useState } from 'react';
import BackButton from '../components/elements/BackButton';
import HeaderExtension from '../components/elements/HeaderExtension';
import SearchBar from '../components/elements/SearchBar';
import FAQSection from '../components/help/FAQSection';

const FAQPage = () => {

    const [searchTerm, setSearchTerm] = useState(null);

    return (  
        <div className='mb-32'>
            <HeaderExtension>
                <BackButton />
            </HeaderExtension>
            <div className='container'>
                <h1 className='mt-6 mb-6 text-4xl font-bold'>FAQ</h1>
                <div className='mb-8 lg:w-2/5'>
                    <SearchBar value={searchTerm} onChange={setSearchTerm} placeholder='Search FAQs' />
                </div>
            </div>
            <FAQSection searchInput={searchTerm} />
        </div>
    );
}
 
export default FAQPage;