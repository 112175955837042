
import { Navigate} from "react-router-dom";
import {Outlet} from "react-router-dom";
import {useLocation} from "react-router";

const ProtectAgreementRoute = ({user}) => {
  const location = useLocation();
  if(user && !user.internal  && !user.accepted_terms) {
    return <Navigate to="/agreePrivacyPolicy" replace state={{redirect: location}}/>;
  }  
  return <Outlet />;
};

export default ProtectAgreementRoute;