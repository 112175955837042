import React from 'react';
import PropTypes from 'prop-types';

const PrimaryButton = ({title='Button', color='cyan', onClick, disabled= false, expanded = true, children, ...props}) => {
    const backgroundColor = "bg-" + color;

    return (
        <button 
            className={ `${backgroundColor} text-white rounded-md text-sm py-2 px-6 font-semibold ${expanded ? 'w-full': ''} ${props.className} ${disabled ? 'opacity-30' : ''}`} 
            type="button" 
            onClick={onClick}
            disabled={disabled}
        >
            {title}
            {children}
        </button>
    );
}

PrimaryButton.propTypes = {
    /**
     * Titel of the Button
     */
    title: PropTypes.string,

    /**
     * Background color of the button
     * use tailwind colors
     */
    color: PropTypes.string,

    
    /**
     * if true the  button has full width
     */
    expanded: PropTypes.bool,
    
    /**
     * if true the  button is disabled
     */
    disabled: PropTypes.bool,

    
    /**
     * Extra classes to be added to the button
     */
    className: PropTypes.string,


    /**
     * Callback function on button click 
     */
    onClick: PropTypes.func,
}

export default PrimaryButton;