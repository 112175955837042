import React from 'react';
import { useSelector } from 'react-redux';
import useCheckAndTrigger from '../../hooks/useCheckAndTrigger';

const RepresentativeCheck = (props) => {
    const representativeMail = useSelector((state) => state.auth.user?.sasol_sm_email);
    useCheckAndTrigger(representativeMail, props.triggerOnTrue, props.triggerOnFalse, props.triggerNextStep);
    
    return <span className='text-blue'>Checking for Sasol S&M representative</span>
}

export default RepresentativeCheck;