import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDatasheetSelector from '../../hooks/useDatasheetSelector';
import { DocumentTypes } from '../../store/types';
import MarketingListEntry from '../../components/marketing/MarketingListEntry'
import CertificatesListEntry from '../../components/certificates/CertificatesListEntry'
import { addDocumentToSelection, removeDocumentFromSelection } from '../../store/slices/basket-selection/basketSelection';
import EmptyBasketCard from '../../components/basket/EmptyBasketCard';
import ReducedDocumentBasketListEntry from '../../components/basket/ReducedDocumentBasketListEntry';
import { removeDocumentFromBasket } from '../../store/slices/basket/basket';
import BasketTableHeader from './BasketTableHeader';
import useScrollSync from '../../hooks/useScrollSync';
import useExtendableColumn from '../../hooks/useExtendableColumn';

const DocumentBasketList = ({documentType, reduced = false}) => {

    const documentSelector = useDatasheetSelector(documentType)
    const documents = useSelector(state => documentSelector.selectAll(state))

    const dispatch = useDispatch()
    const basketSelectionSelector = useDatasheetSelector(documentType, true)
    const basketSelection = useSelector(state => basketSelectionSelector.selectEntities(state))

    const {handleScroll, addElement} = useScrollSync()
    const [extendableColumnWidth, toggleExtendableColumn] = useExtendableColumn(documentType === DocumentTypes.MARKETING)
    
    function toggleSelection(selection) {
        if(basketSelection[selection.id]) {
            dispatch(removeDocumentFromSelection(null, documentType, selection.id))
        }
        else {
            dispatch(addDocumentToSelection(null, documentType, selection))
        }
    }

    return (  
        <div className={reduced ? 'flex flex-col gap-1' : ''}>
            {!reduced && documents.length > 0 && 
            <BasketTableHeader 
                documentIds={documents.map(doc => doc.id)} 
                docType={documentType} 
                ref={addElement(0)}  
                onSyncedScroll={handleScroll} 
                extendableColumnWidth={extendableColumnWidth}
                toggleExtendableColumn={toggleExtendableColumn} 
            />}
            {documents.map((document, index) => {
                if(documentType === DocumentTypes.MARKETING) {
                    return (reduced ? 
                        <ReducedDocumentBasketListEntry 
                            key={document.id} 
                            document={document} 
                            type={documentType} 
                            onRemove={() => dispatch(removeDocumentFromBasket(null, documentType, document.id))} 
                        /> 
                        : 
                        <MarketingListEntry 
                            key={document.id} 
                            marketing={document} 
                            onSelection={() => toggleSelection(document)} 
                            selected={!!basketSelection[document.id]} 
                            ref={addElement(index + 1)} 
                            onSyncedScroll={handleScroll} 
                            extendableColumnWidth={extendableColumnWidth}
                            toggleExtendableColumn={null} 
                        />
                    )
                }
                else if(documentType === DocumentTypes.CERTIFICATES) {
                    return (reduced ? 
                        <ReducedDocumentBasketListEntry 
                            key={document.id} 
                            document={document} 
                            type={documentType} 
                            onRemove={() => dispatch(removeDocumentFromBasket(null, documentType, document.id))} 
                        /> 
                        : 
                        <CertificatesListEntry 
                            key={document.id} 
                            certificate={document} 
                            onSelection={() => toggleSelection(document)} 
                            selected={!!basketSelection[document.id]} 
                            ref={addElement(index + 1)} 
                            onSyncedScroll={handleScroll} 
                            extendableColumnWidth={extendableColumnWidth}
                            toggleExtendableColumn={toggleExtendableColumn}
                        />
                    )
                }
                return null
            })}
            {(!documents || documents.length === 0) &&
                <EmptyBasketCard title={`There are no ${documentType} in your basket`} oneLine/>
            }
        </div>
    );
}
 
export default DocumentBasketList;