import React, { forwardRef, useMemo } from 'react';
import { useDocumentLeftBorderColor } from '../../hooks/useDocumentBorderColor';
import useSyncedScrollableContainer from '../../hooks/useSyncedScrollableContainer';
import { DocumentTypes } from '../../store/types';
import ExtendbaleColumnContainer from './ExtendbaleColumnContainer';

const MobileTableHeader = forwardRef((props, ref) => {

    const scrollContainerRef = useSyncedScrollableContainer(ref)
    const borderColor = useDocumentLeftBorderColor(props.documentType)

    const gridColsClass = useMemo(() => {
        if(props.documentType === DocumentTypes.CERTIFICATES){
            return 'grid-cols-2'
        }
        else return 'grid-cols-3'
    }, [props.documentType])

    return (
        <div className={`border-l-2 ${borderColor} flex w-full text-sm text-gray font-semibold items-center`}>
                <div className='px-6 mx-px'></div>
                <ExtendbaleColumnContainer width={props.extendableColumnWidth} hiddenBorders >
                    {props.firstLabel}
                </ExtendbaleColumnContainer>
                <div ref={scrollContainerRef} className='overflow-x-scroll hiddenScrollbars' onScroll={props.onSyncedScroll}>
                    <div className={`grid items-center w-sideScrollable ${gridColsClass}`}>
                        <div className='px-4 py-3'>
                            {props.secondLabel}
                        </div>
                        {props.thirdLabel &&
                        <div className='px-4 py-3'>
                            {props.thirdLabel}
                        </div>}
                        {props.fourthLabel &&
                        <div className='px-4 py-3'>
                            {props.fourthLabel}
                        </div>}
                    </div>
                </div>
            </div>
    )
})
 
export default MobileTableHeader;