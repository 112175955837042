import {
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL,
  USER_UPDATED,
  LOGIN_TYPES,
  LOGIN_TYPE_CHANGED,
  AUTH_ERROR_OCCURED,
  AUTH_ERROR_FIXED,
  REQUEST_STATUS,
  USER_LOADING,
} from "../types";

const initialState = {
  load_user_status: REQUEST_STATUS.IDLE,
  user: null,
  groups:null,
  authorization: {
    groups:null,
    is_superuser:false,
  },
  login_type: localStorage.getItem('login_type') ?? LOGIN_TYPES.DEFAULT,
  auth_error: null,
};



const auth = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADING:
      return {
        ...state,
        load_user_status: REQUEST_STATUS.PENDING,
      };
    case USER_LOADED_SUCCESS:
      return {
        ...state,
        load_user_status: REQUEST_STATUS.IDLE,
        user: payload,
        authorization: {
          groups: payload.groups,
          is_superuser: payload.is_superuser
        },
        groups: payload.groups,
      };
    case USER_LOADED_FAIL:
      return {
        ...state,
        load_user_status: REQUEST_STATUS.ERROR,
        user: null,
      };
    case USER_UPDATED: {
      return {
        ...state,
        user: payload,
      }
    }
    case LOGIN_TYPE_CHANGED: {
      return {
        ...state,
        login_type: payload,
      }
    }
    case AUTH_ERROR_OCCURED: {
      return {
        ...state,
        auth_error: payload,
      }
    }
    case AUTH_ERROR_FIXED: {
      return {
        ...state,
        auth_error: null,
      }
    }
    default:
      return state;
  }
};

export default auth;
