import React, { useEffect, useState } from "react";
import ProductSearchBar from "../../components/search/ProductSearchBar";
import ProductsList from "../../components/search/ProductsList";
import { useSearchParams, useLocation } from "react-router-dom";
import { api } from "../../api/api";
import HeaderExtension from "../../components/elements/HeaderExtension";
import BackButton from "../../components/elements/BackButton";

const DEFAULT_PAGE_SIZE = 25;

const SearchResultsPage = () => {

    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const [searchTerm, setSearchTerm] = useState(urlSearchParams.get('search') ?? '');
    const [page, setPage] = useState(1);
    const [productsList, setProductsList] = useState({results: []});
    const location = useLocation();
    const [bulkLoadProductIndex, setBulkLoadProductIndex] = useState(location.state?.productIndex);

    function handleInput(input) {
        setSearchTerm(input);
    }

    function search() {
        setBulkLoadProductIndex(null);
        setPage(1);
        setUrlSearchParams({search: searchTerm.trim()})
    }

    function addPage(page) {
        setBulkLoadProductIndex(null);
        const response = api.getProductList({search: searchTerm.trim(), page: page, page_size: DEFAULT_PAGE_SIZE});
        response.then((response) => {
            setPage(page);
            const list = [...productsList.results, ...response.data.results];
            const data = Object.assign(response.data, {results: list})
            setProductsList(data);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {    
        if(bulkLoadProductIndex) {
            const index = Number.parseInt(bulkLoadProductIndex) + 1;
            const bulkedPages = Math.floor(index / DEFAULT_PAGE_SIZE) + ((index % DEFAULT_PAGE_SIZE) > 0 ? 1 : 0);
            const bulkLoadNumber = bulkedPages * DEFAULT_PAGE_SIZE;

            const params = {search: urlSearchParams.get('search'), page_size: bulkLoadNumber }
            const response = api.getProductList(params);
            response.then((response) => {
                setProductsList(response.data);
                setPage(bulkedPages);
            })
            .catch((error) => {
                console.log(error);
            });

        }
        else {
            const response = api.getProductList({search: urlSearchParams.get('search'), page_size: DEFAULT_PAGE_SIZE});
            response.then((response) => {
                setProductsList(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        }           
    }, [urlSearchParams])

    useEffect(() => {
        window.scrollTo(0,0)
    }, [location.search]);

    return (
        <div className="searchResultsPage">
            <div className="sticky bg-white searchContainer top-header">
                <HeaderExtension><BackButton /></HeaderExtension>
                <div className="container py-6 lg:py-8">
                    <div className=" lg:max-w-[60%]">
                        <ProductSearchBar 
                            onSearchInput={handleInput} 
                            onSearchSubmit={search} 
                            searchTerm={searchTerm}
                        />
                    </div>
                </div>
                
            </div>
            <div className="resultsContainer py-10 bg-gray-50 min-h-[70vh]">
                <div className="container">
                    <div className="flex gap-1 mb-7">
                        <p className="text-sm font-medium">Results: </p>
                        <p className="text-sm font-normal text-gray-800">{productsList.count} products found</p>
                    </div>
                    <ProductsList list={productsList.results} 
                        more={(productsList.links?.next != null)} 
                        page={page} 
                        onLoadMore={addPage}
                        backLink={location}
                        focusedProductIndex={bulkLoadProductIndex}
                    />
                </div>
            </div>
            
        </div>
    )
}

export default SearchResultsPage;