import React, { useState, useEffect } from 'react';
import {ReactComponent as IconChevronDown} from "../../assets/icons/Icon_Chevron-Down.svg";
import Checkbox from '../elements/Checkbox';
import PropTypes from 'prop-types';


const CheckFilter = (props) => {
    const [isOpen, setIsOpen] = useState(false); 

    function close() {
        setTimeout(setIsOpen, 100, false);
    }
    useEffect(() => {
        if(isOpen) {
            setTimeout(window.addEventListener,100, 'click', close);
        } 
        else {
            window.removeEventListener('click', close);
        }
        return () => {
            window.removeEventListener('click', close)
        }
    }, [isOpen])


    return (
        <div className={`datasheetFilterDropdown ${isOpen ? 'text-blue' : 'text-gray'} text-sm font-semibold`}>
            <div className='flex items-center'>
                <label 
                    onClick={() => {setIsOpen(!isOpen)}}
                    className={`bg-white rounded border px-2 py-[2px] cursor-pointer flex items-center
                        ${isOpen ? 'border-blue' : 'hover:border-gray border-gray-600'}`}
                >
                    {props.title}
                    <IconChevronDown className={`w-3 ${isOpen ? 'rotate-180' : ''}`}/>
                </label>
            </div>
            {isOpen &&
            <ul className='dropdownOptions bg-white absolute rounded border border-gray-200 p-2 columns-2 lg:columns-3 mr-1 mt-[2px] shadow-card font-normal z-10'>
                {props.options?.map((option) => {
                    return (
                        <li className={`filterOption 
                                flex items-center px-2 py-1 
                                hover:bg-gray-50 
                                `} 
                            key={option.value}
                            onClick={(e) => {e.stopPropagation()}}>
                            <span className="mr-1">
                                <Checkbox 
                                    checked={props.selectedOptions.some(entry => entry.value === option.value)} 
                                    onChange={() => {props.onSelection(option)}}
                                    args={{id: option.value}} 
                                    topMargin='top-px'
                                />
                            </span>

                            <label htmlFor={option.value}
                                >
                                {option.label}
                            </label>
                        </li>
                    )
                })}
            </ul>}
        </div>
        
    )
}

CheckFilter.propTypes = {
    title: PropTypes.string,
    options: PropTypes.array,
    highlightedOptions: PropTypes.array,
    selectedOptions: PropTypes.array,
    onSelection: PropTypes.func,
    onSelectionClear: PropTypes.func,
}

export default CheckFilter;