import React from 'react';

const ExtendbaleColumnContainer = ({width, onClick, hiddenBorders = false, children}) => {
    return (
        <div className={`px-4 py-3 border-x ${hiddenBorders ? 'border-x-transparent' : 'border-x-gray-100'} ${width} overflow-x-hidden flex-grow`} onClick={onClick}>
            {children}
        </div>
    )
}
 
export default ExtendbaleColumnContainer;