import { MainLayout } from "./layouts/MainLayout";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./routes/ProtectedRoute";
import { useDispatch, useSelector } from "react-redux";
import SearchPage from "./containers/search/SearchPage";
import SearchResultsPage from "./containers/search/SearchResultsPage";
import ProductPage from "./containers/products/ProductPage";
import LoginPage from "./pages/LoginPage";
import AgreementForm from "./containers/auth/AgreementForm";
import { useEffect } from "react";
import { load_user, viewed_tour } from "./store/actions/auth";
import ProtectAgreementRoute from "./routes/ProtectAgreementRoute";
import ContactPage from "./containers/contact/ContactPage";
import ScrollToTop from "./routes/ScrollToTop";
import ErrorPage from "./pages/ErrorPage";
import ProfilePage from "./containers/profile/ProfilePage";
import ProtectCompleteProfileRoute from "./routes/ProtectCompleteProfileRoute";
import {useTour} from "@reactour/tour";
import {useIsAuthenticated, useMsal} from '@azure/msal-react';
import { LOGIN_TYPES } from "./store/types";
import { loginADRequest, loginRequest as loginB2CRequest } from "./authConfig";
import CertificatesPage from "./containers/certificates/CertificatesPage";
import MarketingPage from "./containers/marketing/MarketingPage";
import BasketPage from "./components/basket/BasketPage";
import FAQPage from "./pages/FAQPage";
import BasketSidebar from "./containers/basket/BasketSidebar";
import NewsPage from "./pages/NewsPage";
import CustomerViewOverlay from "./containers/customer-view/CustomerViewOverlay";
import ROUTES from "./routes/routes";

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const userLoadingStatus = useSelector((state) => state.auth.load_user_status);
  const authError = useSelector((state) => state.auth.auth_error);
  const login_type = useSelector((state) => state.auth.login_type);
  const isAuthenticated = useIsAuthenticated()
  const {setIsOpen} = useTour();
  const {instance, accounts, inProgress} = useMsal();

  useEffect(() => {
    window.gtag('set', 'user_properties', {'internal': user?.internal})
  }, [user]);

  useEffect(() => {
    if(user === null && isAuthenticated) {
      dispatch(load_user())
    }
  }, [isAuthenticated])

  useEffect(() => {
    if(authError) {
      const loginRequest = login_type === LOGIN_TYPES.B2C ? loginB2CRequest : loginADRequest;
      instance.acquireTokenSilent({...loginRequest, account: accounts[0]}).catch(e => {console.log('Error on Login',e); instance.logoutRedirect()});
    }
  }, [authError])

  useEffect(() => {

    if(user && !user.viewed_tour && user.accepted_terms && (user.completed_profile || user.internal)  ) {
      setIsOpen(true);
      dispatch(viewed_tour());
    }
  }, [user])

  
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path={ROUTES.login.fullPath} element={<LoginPage />}></Route>
          <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} inProgress={inProgress} user={user} userLoadingStatus={userLoadingStatus} />}>
            <Route path="/" element={<MainLayout />}>
              <Route path={ROUTES.contact.path} element={<ContactPage />}></Route>
              <Route path={ROUTES.agreePrivacy.path} element={<AgreementForm redirectAfterAgreed/>}></Route>
              <Route element={<ProtectAgreementRoute user={user} /> }>
                <Route path={ROUTES.completeProfile.path} element={<ProfilePage completeDataPrompt/>}></Route>
                <Route element={<ProtectCompleteProfileRoute user={user}/>}>
                  <Route path={ROUTES.searchResults.path} element={<SearchResultsPage />}></Route>
                  <Route path="products">
                    <Route path=":slug" element={<ProductPage />}></Route>
                  </Route>
                  <Route path={ROUTES.certificates.path} element={<CertificatesPage />}></Route>
                  <Route path={ROUTES.marketing.path} element={<MarketingPage />}></Route>
                  <Route path={ROUTES.basket.path} element={<BasketPage />}></Route>
                  <Route path={ROUTES.news.path} element={<NewsPage />}></Route>
                  <Route path={ROUTES.faq.path} element={<FAQPage />}></Route>
                  <Route path={ROUTES.profile.path} element={<ProfilePage />}></Route>
                  <Route path={ROUTES.privacy.path} element={<AgreementForm />}></Route>
                  <Route index element={<SearchPage />}></Route>
                </Route>
              </Route>
            </Route>
            <Route path="*" element={<ErrorPage error={{code: '404', message: 'The page you were looking for couldn\'t be found.'}} />}></Route>
          </Route>
        </Routes>
      </ScrollToTop>
      <BasketSidebar />
      <CustomerViewOverlay />
    </Router>
  );
}

export default App;
