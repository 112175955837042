import React from 'react';
import PropTypes from 'prop-types';

const SecondaryButton = ({ cyan, title, solid,  size = 'sm', expanded = false, onClick, children, disabled,...props}) => {
    const colorClasses = cyan ? `${solid ? 'bg-cyan text-white hover:bg-cyan-200 border-cyan hover:border-cyan-200' : 'bg-cyan-50 border-cyan text-cyan hover:bg-cyan-100'}` : 'bg-white border-gray-400 text-gray hover:bg-gray-50';
    const paddingClasses = size === 'md' ? 'py-2 px-6 font-semibold' : 'py-1 px-2'
    
    return (
        <button 
            className={`${colorClasses} transition-colors duration-300 rounded-md border text-sm ${paddingClasses} flex items-center ${props.expanded ? 'w-full' :''} ${props.className} ${disabled ? 'opacity-30' : ''}`} 
            type="button" 
            onClick={onClick}
            disabled={disabled}
        >
            {title}
            {children}

        </button>
    );
}

SecondaryButton.propTypes = {
    /**
     * If true cyan version of secondary button
     */
    cyan: PropTypes.bool,

    
    /**
     * If true fills button with solid background color
     * Only works in combination with cyan
     */
    solid: PropTypes.bool,


    /**
     * if true the the button has full width
     */
    expanded: PropTypes.bool,


    
    /**
     * Extra classes to be added to the button
     */
    className: PropTypes.string,


    /**
     * md if the button is in the login page
     */
    size: PropTypes.string,

    /**
     * Callback function on button click 
     */
    onClick: PropTypes.func,
}

export default SecondaryButton;