import { useCallback } from "react"
import { DocumentTypes } from "../store/types"

const useDocumentName = () => {
    const documentNameCreator = useCallback((documentType, productName, document) => {
        if(documentType === DocumentTypes.PDS || documentType === DocumentTypes.SDS || documentType === DocumentTypes.RIS || documentType === DocumentTypes.ERI) {
            const typeKey = Object.keys(DocumentTypes).find(key => DocumentTypes[key] === documentType)
            const lastField = documentType === DocumentTypes.RIS ? 'All' : document.country ?? document.source
            return `${typeKey}_${productName}_${document.language}_${lastField}`
        }
        return document.name
    }, [])

    return documentNameCreator
}

export default useDocumentName