import React, { useState, useEffect, useMemo, useCallback } from 'react';
import DatasheetListEntry from './DatasheetListEntry';
import PropType from "prop-types";
import { api } from "../../../api/api";
import {ReactComponent as IconAttention} from "../../../assets/icons/Icon_Attation.svg";
import Checkbox from '../../elements/Checkbox';
import DatasheetFilterDropdown from './DatasheetFilterDropdown';
import useDatasheetFilterSelection from '../../../hooks/useDatasheetFilterSelection';
import {Link, useLocation} from "react-router-dom";
import { DocumentTypes } from '../../../store/types';
import { useDocumentLeftBorderColor } from '../../../hooks/useDocumentBorderColor';
import useScrollSync from '../../../hooks/useScrollSync';
import useExtendableColumn from '../../../hooks/useExtendableColumn';
import { useTwScreens } from '../../../utilities/TailwindScreens';
import MobileTableHeader from '../../elements/MobileTableHeader';

const DatasheetList = (props) => {

    const {min} = useTwScreens()
    const location = useLocation();

    const [loadingDatasheets, setLoadingDatasheets] = useState(false);

    const [datasheets, setDatasheets] = useState([]);
    const [availableCountries, setAvailableCountries] = useState([]);
    const [selectedCountries, selectCountry, clearSelectedCountries] = useDatasheetFilterSelection();
    const [selectedLanguages, selectLanguage, clearSelectedLanguages] = useDatasheetFilterSelection();
    const [availableMaterialNumbers, setAvailableMaterialNumbers] = useState([]);
    const [selectedMaterialNumbers, selectMaterialNumber, clearSelectedMaterialNumbers] = useDatasheetFilterSelection();

    const {handleScroll, addElement} = useScrollSync() 
    const [extendableColumnWidth, toggleExtendableColumn] = useExtendableColumn()

    const clearSelections = useCallback(() => {
        clearSelectedCountries()
        clearSelectedLanguages()
        clearSelectedMaterialNumbers()
    }, [clearSelectedCountries, clearSelectedLanguages, clearSelectedMaterialNumbers])

    const clearOptions = useCallback(() => {
        setAvailableCountries([])
        setAvailableMaterialNumbers([])
    }, [clearSelectedCountries, clearSelectedLanguages, clearSelectedMaterialNumbers])

    useEffect(() => {
        clearSelections()
        clearOptions()
    }, [props.slug]);

    useEffect(() => {
        const params = {};
        if(selectedLanguages.length > 0) {
            params.languages =  selectedLanguages.map(label => {return props.availableLanguages.find(lang => lang.label === label).value}).join();
        }
        if(selectedCountries.length > 0) {
            params.countries = selectedCountries.join();
        }
        if(selectedMaterialNumbers.length > 0) {
            params.materials = selectedMaterialNumbers.join();
        }
        setLoadingDatasheets(true);
        const response = api.getDatasheets(props.slug, Object.keys(DocumentTypes).find(key => DocumentTypes[key] === props.type).toLowerCase(), params);
        response.then((response) => {
            setDatasheets(response.data);
            if(availableCountries.length === 0) {
                const countries = [...new Set(response.data.map(datasheet => {return datasheet.country}))];
                countries.sort();
                setAvailableCountries(countries);
            }
            if(availableMaterialNumbers.length === 0) {
                const materials = [...new Set(response.data.map(datasheet =>  datasheet.materials).flat())];
                materials.sort()
                setAvailableMaterialNumbers(materials)
            }
            props.setDatasheetSelection([])
            setLoadingDatasheets(false);
        })
        .catch((error) => {
            console.log(error);
            setLoadingDatasheets(false);
        });
    }, [props.slug, selectedCountries, selectedLanguages, selectedMaterialNumbers]);
    
    const selectAll = () => {
        const selection = [...datasheets]
        props.setDatasheetSelection(selection);
        
    }

    const list = datasheets.map((datasheet, index) => {
        return (
            <DatasheetListEntry 
                key={datasheet.id} 
                datasheet={datasheet} 
                type={props.type} 
                selected={props.selectedDatasheets.some(ds => ds.id === datasheet.id)} 
                onSelection={() => {toggleSelection(datasheet)}} 
                product={props.product}
                onSyncedScroll={handleScroll}
                ref={addElement(index + 1)}
                extendableColumnWidth={extendableColumnWidth}
                toggleExtendableColumn={toggleExtendableColumn}
                />
        )
    });

    const allSelected = (datasheets.length === props.selectedDatasheets.length) && datasheets.length > 0;

    const toggleSelectAll = () => {
        if(allSelected) {
            props.setDatasheetSelection([])
        }
        else {
            selectAll();
        }
    }

    const toggleSelection = (selection) => {
        const index = props.selectedDatasheets.findIndex(ds => ds.id === selection.id);
        if(index < 0) {
            props.setDatasheetSelection([...props.selectedDatasheets, selection]);
        }
        else {
            const copy = [...props.selectedDatasheets];
            copy.splice(index, 1);
            props.setDatasheetSelection(copy);
        }
    }
    const availableLanguagesLabels = props.availableLanguages?.map(lang => lang.label)

    let borderColorClass = useDocumentLeftBorderColor(props.type)

    const firstHeaderCol = useMemo(() => {
        if(props.type === DocumentTypes.SDS) {
           return (
            <div>   
                <DatasheetFilterDropdown 
                    title={'Country'}
                    options={availableCountries} 
                    selectedOptions={selectedCountries}
                    highlightedOptions={[...new Set(datasheets.map(datasheet => datasheet.country))]}
                    onSelection={selectCountry}
                    onSelectionClear={clearSelectedCountries}
                />
            </div>
           )
        }
        else {
            return (
                <div className=''>
                    <label className='flex items-center h-6'>
                        {props.type === DocumentTypes.PDS ? 'Sasol Source' : 'Country'}
                    </label>
                </div>
            )
        }
    }, [availableCountries, clearSelectedCountries, datasheets, props.type, selectCountry, selectedCountries])

    const secondHeaderCol = useMemo(() => {
        return props.type === DocumentTypes.SDS ? 
            (<div>
                <DatasheetFilterDropdown 
                    title={'Language'}
                    options={availableLanguagesLabels} 
                    selectedOptions={selectedLanguages}
                    highlightedOptions={[...new Set(datasheets.map(datasheet => datasheet.language))]}
                    onSelection={selectLanguage}
                    onSelectionClear={clearSelectedLanguages}
                />
            </div>)
            :
            <label className='flex items-center h-6'>Language</label>
    }, [availableLanguagesLabels, clearSelectedLanguages, datasheets, props.type, selectLanguage, selectedLanguages])

    const thirdHeaderCol = useMemo(() => {
        return (
            <DatasheetFilterDropdown 
                title={'Sasol Material Number'}
                options={availableMaterialNumbers} 
                selectedOptions={selectedMaterialNumbers}
                highlightedOptions={[...new Set(datasheets.map(datasheet => datasheet.materials).flat())]}
                onSelection={selectMaterialNumber}
                onSelectionClear={clearSelectedMaterialNumbers}
            />
        )
    }, [availableMaterialNumbers, clearSelectedMaterialNumbers, datasheets, selectMaterialNumber, selectedMaterialNumbers])

    const fourthHeaderCol = useMemo(() => <label className='flex items-center h-6'>Date of upload*</label>, [])

    if((availableCountries.length === 0 && datasheets.length === 0)) {
        return (
            <div className={`bg-white border-l-2 rounded ${borderColorClass} px-4 py-3 flex gap-1 items-center text-sm`}>
                <div className='text-gray'><IconAttention className="h-4" /></div>
                <p>
                    There are no {props.type}s available at the moment. 
                    Please reach out to your Sasol sales representative via our&nbsp; 
                    <Link to="/contact" state={{backLink: location}} className='underline'>contact form</Link>.
                </p>
            </div>
        )
    }

    return (
        <div className='w-full datasheetList'>
            {min('lg') ?
            <div className='text-sm font-semibold datasheetListHeader datasheetTableGrid text-gray'>
                <div className='flex items-center start-1'>
                    <div className={`bg-gray-100 px-4 py-3 rounded-tl border-l-2 ${borderColorClass}`}>
                        <Checkbox checked={allSelected} onChange={toggleSelectAll} args={{id: "selectAll"+props.type}} topMargin='top-px' />
                    </div>
                    <div className='px-4'>
                        {firstHeaderCol}
                    </div>
                    
                </div>
                <div className='flex items-center px-4 start-2'>
                    {secondHeaderCol}
                </div>
                <div className='flex items-center px-4 start-3'>
                    {thirdHeaderCol}
                </div>
                <div className='flex items-center px-2 start-4'>
                    {fourthHeaderCol}
                </div>
            </div>
            :
            <MobileTableHeader
                    documentType={props.type}
                    ref={addElement(0)}
                    extendableColumnWidth={extendableColumnWidth}
                    onSyncedScroll={handleScroll}
                    firstLabel={firstHeaderCol}
                    secondLabel={secondHeaderCol}
                    thirdLabel={thirdHeaderCol}
                    fourthLabel={fourthHeaderCol}
                />
            }
            <div className='datasheetListBody max-h-[50vh] overflow-y-scroll rounded-bl customScrollbars'>
                {loadingDatasheets ? 
                    <div className={`bg-white border-l-2 rounded-b ${borderColorClass} px-4 py-3 flex gap-1 items-center text-sm`}>
                        Loading
                    </div>
                :
                    <>
                        {(list.length > 0) ? 
                            list :
                            <div className={`bg-white border-l-2 rounded-b ${borderColorClass} px-4 py-3 flex gap-1 items-center text-sm`}>
                                No {props.type}s found. Please change filter settings.
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    );
}

DatasheetList.propTypes = {
    slug: PropType.string,
    type: PropType.oneOf(Object.values(DocumentTypes)),
    selectedLanguages: PropType.array,
    setDatasheetSelection: PropType.func,
    product: PropType.object,
}

export default DatasheetList;