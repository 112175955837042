import GHS01 from "../../../assets/ghs-symbols/GHS01.png";
import GHS02 from "../../../assets/ghs-symbols/GHS02.png";
import GHS03 from "../../../assets/ghs-symbols/GHS03.png";
import GHS04 from "../../../assets/ghs-symbols/GHS04.png";
import GHS05 from "../../../assets/ghs-symbols/GHS05.png";
import GHS06 from "../../../assets/ghs-symbols/GHS06.png";
import GHS07 from "../../../assets/ghs-symbols/GHS07.png";
import GHS08 from "../../../assets/ghs-symbols/GHS08.png";
import GHS09 from "../../../assets/ghs-symbols/GHS09.png";


export const GHSPictograms = (props) => {
  const pictogramsMap = {
    GHS01: {
      src: GHS01,
      alt: "Explosive",
    },
    GHS02: {
      src: GHS02,
      alt: "Flammable",
    },
    GHS03: {
      src: GHS03,
      alt: "Oxidizing",
    },
    GHS04: {
      src: GHS04,
      alt: "Compressed Gas",
    },
    GHS05: {
      src: GHS05,
      alt: "Corrosive",
    },
    GHS06: {
      src: GHS06,
      alt: "Toxic",
    },
    GHS07: {
      src: GHS07,
      alt: "Harmful",
    },
    GHS08: {
      src: GHS08,
      alt: "Health Hazard",
    },
    GHS09: {
      src: GHS09,
      alt: "Environmental Hazard",
    },
  };
  return (
    <div className="flex justify-right">
      {props.pictograms.map((pictogram, index) => {
        return pictogramsMap[pictogram] ? (
          <div className="mr-5 w-16 flex flex-col items-center" key={index}>
            <div className={"text-center text-xs"}>{pictogram}</div>
            {pictogramsMap[pictogram] ? <img
              src={pictogramsMap[pictogram]?.src}
              className="text-center"
              alt={pictogram}
              width={50}
              height={50}
            />: undefined}
            <div className="text-center text-xs">
              {pictogramsMap[pictogram]?.alt}
            </div>
          </div>
        ) : <p className="text-sm font-normal">{pictogram}</p>;
      })}
    </div>
  );
};
