import React from 'react';
import { createPortal } from 'react-dom';
import useCustomerViewActive from '../../hooks/useCustomerViewActive';
import CloseCustomerViewButton from './CloseCustomerViewButton';

const CustomerViewOverlay = () => {

    const isOpen = useCustomerViewActive()
    
    return (
        createPortal(
            <>
                {isOpen &&
                <div className='w-full h-full fixed inset-0 z-[200] border-4 border-cyan pointer-events-none'>
                    <div className='fixed inset-0 w-full h-full border-4 pointer-events-none rounded-xl border-cyan'>
                        <CloseCustomerViewButton />
                    </div>
                </div>
                }
            </>
        , document.body)
    )
}
 
export default CustomerViewOverlay;