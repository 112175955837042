import React from 'react';
import PropTypes from 'prop-types';
import DefaultDocumentTypeEntry from './DefaultDocumentTypeEntry';

const DefaultRecourcesCard = (props) => {
       
    return (
        <div className="p-4 bg-gray-100 rounded defaultRecources">
            <h3 className='mb-2 font-normal text-gray'>Quick Links</h3>
            <div className="mb-4 list">
                <DefaultDocumentTypeEntry type="Product Data Sheet" documents={props.pds} productName={props.productName} />
                <DefaultDocumentTypeEntry type="Safety Data Sheet" documents={props.sds} productName={props.productName}/>
                <DefaultDocumentTypeEntry type="Regulatory Information Sheet" documents={props.ris} productName={props.productName}/>
            </div>
            <div className='px-6 py-2 text-center text-white rounded-md  bg-cyan'>
                <a href={'#' + props.linkId}>View All</a>
            </div>
        </div>
    );
}

DefaultRecourcesCard.propTypes = {
    /**
     * Array of default pds documents
     */
    pds: PropTypes.array,
    /**
     * Array of default sds documents
     */
    sds: PropTypes.array,
    /**
     * Array of default ris documents
     */
    ris: PropTypes.array,

    productName: PropTypes.string
}

export default DefaultRecourcesCard;