import React, { useState } from 'react';

const ContactSafetySummary = (props) => {
    const {steps} = props;
    const [product] = useState(steps[props.productStep].value);
    const [material] = useState(steps[props.materialStep].value);
    const [rep] = useState(steps[props.repStep].value);
    const [message] = useState(steps[props.messageStep].value);
    const [category] = useState(steps[props.categoryStep].value);
    return (
        <div>
            <div className='mb-2'>
                <span className='font-semibold'>Subject: </span> 
                <span>{category} - Question on {product}{material ? `, material ${material}` : null}</span>
            </div>
            <div className='mb-2'>
                <span className='font-semibold'>CC: </span> 
                <span>{rep}</span>
            </div>        
            <p className='font-semibold mb1'>
                Message:
            </p>
            <p>{message}</p>
        </div>
    )
}

export default ContactSafetySummary;