import React, { useState } from 'react'
import { useMemo } from 'react';

const MaterialNumbersList = ({list, highlighted, maxElements}) => {

    const [isExtended, setIsExtended] = useState(false);
    const [materialList, setMaterialList] = useState(list);

    useMemo(() => {
        setMaterialList(getListElements(list, highlighted));
    }, [list, highlighted]);

    
    function getListElements(materialNumbers, highlighted) {

        // The element that contains highlighted elements are shown first
        const highlightedElement = materialNumbers.find(element => element.includes(highlighted));
        const otherElements = materialNumbers.filter(element => !element.includes(highlighted));
        const elements = [highlightedElement, ...otherElements].filter(element => element !== undefined);


        return elements.map((materialNumber) => {
            return (
                <span 
                    key={materialNumber} 
                    className={`text-xs border ${materialNumber === highlighted ? 'border-cyan text-cyan bg-cyan-50' : 'border-gray-400 text-gray'} px-1 rounded`}
                >
                    {materialNumber}
                </span>
            )
        })
    }


    return (
        <div className='flex gap-1 items-center flex-wrap'>
            {materialList.slice(0,maxElements)}
            {isExtended &&
                materialList.slice(maxElements)
            }
            {list?.length > materialList.slice(0,maxElements)?.length && highlighted === undefined &&
                <button 
                    className='text-blue text-sm underline'
                    onClick={() => setIsExtended(!isExtended)}
                >
                    {isExtended ? 'show less' :`${list.length-materialList.slice(0,maxElements).length} more`}
                </button>
            }
        </div>
    )
}

export default MaterialNumbersList;