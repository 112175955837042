import React, { useState } from 'react';

const MessageHeader = (props) => {
    const [product] = useState(props.steps[props.productValueStep].value)
    const [materialNumber] = useState(props.steps[props.materialValueStep].value)
    const [category] = useState(props.steps[props.categoryValueStep].value)
    return (
        <div>
            <p>Please type your message.</p>
            <p className='font-semibold'>Subject:</p>
            <p className='italic'>{category} - Question on {product}{materialNumber && <span>, material {materialNumber}</span>}</p>
        </div>
    )
}
export default MessageHeader;