import { useCallback, useRef } from "react"

const useScrollSync = () => {
    const scrollRefs = useRef([])

    const applyScroll = useCallback((scrollLeft) => {
        scrollRefs.current.filter(Boolean).forEach(element => {
            element.setScrollLeft(scrollLeft)
        })
    }, [])

    const handleScroll = useCallback((e) => {
        applyScroll(e.target.scrollLeft)
    }, [applyScroll])

    const addElement = index => element => {
        scrollRefs.current[index] = element
    }

    return {handleScroll, addElement}
}

export default useScrollSync