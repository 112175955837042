import React from 'react';
import PropTypes from 'prop-types';
import TextInputField from '../elements/TextInputField';

const ProfileInfoForm = ({representativeMail, name, email, phone, organization, jobTitle, onChange, checkSasolMail, readOnly}) => {
    return (
        <>
            {representativeMail &&
            <div className='mb-4 lg:w-1/3' 
                onBlur={checkSasolMail}
            >
                <TextInputField
                    label='Sasol sales representative email'
                    id='recipient_field'
                    name={representativeMail.fieldName}
                    value={representativeMail.value}
                    onChange={onChange}
                    error={representativeMail.error}
                    disabled={readOnly}
                />
            </div>}
            <div className='mt-6'>
                <h3 className='w-full pb-1 mb-4 uppercase border-b border-gray-400 text-gray'>Your contact details</h3>
                <div className='grid grid-cols-1 gap-3 mb-3 lg:grid-cols-3'>
                    {name &&
                    <div>
                        <TextInputField
                            label='Full name'
                            id='customer_name_field'
                            name={name.fieldName}
                            value={name.value}
                            onChange={onChange}
                            error={name.error}
                            disabled={readOnly}
                        />
                    </div>}
                    {email &&
                    <div className='col-span-1 lg:col-start-2'>
                        <TextInputField
                            label='Email'
                            id='customer_email_field'
                            name={email.fieldName}
                            value={email.value}
                            onChange={onChange}
                            error={email.error}
                            disabled={readOnly}
                        />
                    </div>}
                </div>
                <div className='grid grid-cols-1 gap-3 mb-2 lg:grid-cols-3'>
                    {phone &&
                    <div>
                        <TextInputField
                            label='Telephone'
                            id='customer_phone_field'
                            name={phone.fieldName}
                            value={phone.value}
                            onChange={onChange}
                            error={phone.error}
                            disabled={readOnly}
                        />
                    </div>}
                    {organization &&
                    <div>
                        <TextInputField
                            label='Organization'
                            id='customer_organization_field'
                            name={organization.fieldName}
                            value={organization.value}
                            onChange={onChange}
                            error={organization.error}
                            disabled={readOnly}
                        />
                    </div>}
                    {jobTitle &&
                    <div>
                        <TextInputField
                            label='Job title'
                            id='customer_job_title_field'
                            name={jobTitle.fieldName}
                            value={jobTitle.value}
                            onChange={onChange}
                            error={jobTitle.error}
                            disabled={readOnly}
                        />
                    </div>}
                </div>
            </div>
        </>
    )
}

ProfileInfoForm.propTypes = {
    representativeMail: PropTypes.exact({fieldName: PropTypes.string, value: PropTypes.string, error: PropTypes.string}), 
    name: PropTypes.exact({fieldName: PropTypes.string, value: PropTypes.string, error: PropTypes.string}), 
    email: PropTypes.exact({fieldName: PropTypes.string, value: PropTypes.string, error: PropTypes.string}), 
    phone: PropTypes.exact({fieldName: PropTypes.string, value: PropTypes.string, error: PropTypes.string}), 
    organization: PropTypes.exact({fieldName: PropTypes.string, value: PropTypes.string, error: PropTypes.string}), 
    jobTitle: PropTypes.exact({fieldName: PropTypes.string, value: PropTypes.string, error: PropTypes.string}), 
    onChange: PropTypes.func.isRequired, 
    checkSasolMail: PropTypes.func,
}

export default ProfileInfoForm;