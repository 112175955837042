import { useMsal } from "@azure/msal-react";
import { useSelector } from "react-redux"
import { loginADRequest, loginRequest as loginB2CRequest} from "../authConfig";
import { LOGIN_TYPES } from "../store/types";

const useAccessToken = () => {
    const login_type = useSelector((state) => state.auth.login_type);
    const {instance, accounts} = useMsal();

    function getAccessToken(){
        const loginRequest = login_type === LOGIN_TYPES.B2C ? loginB2CRequest : loginADRequest;
        return instance.acquireTokenSilent({...loginRequest, account: accounts[0]})
    }

    return getAccessToken;
}

export default useAccessToken