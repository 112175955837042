import React from 'react';
import { useState } from 'react';
import { useDocumentLeftBorderColor } from '../../../hooks/useDocumentBorderColor';
import {ReactComponent as IconClose} from "../../../assets/icons/Icon_Close.svg"
import { DocumentTypes } from '../../../store/types';
import { Tab } from '@headlessui/react';
import DocumentTab from '../../elements/DocumentTab';
import { useMemo } from 'react';
import useDocumentName from '../../../hooks/useDocumentName';


const SharedDatasheetsList = ({docList, type, maxElements = 5, onRemove, product}) => {
    const [showingMore, setShowingMore] = useState(false);
    const tagList = docList.length > maxElements ? docList.slice(0,maxElements) : docList;
    let rest = null
    if(docList.length > maxElements) {
        rest = docList.slice(maxElements, docList.length);
    }
    const borderColorClass = useDocumentLeftBorderColor(type)
    const documentNameCreator = useDocumentName()

    return (
        <div className={`flex items-center text-sm bg-white border-l-2 rounded-sm ${borderColorClass} px-4 py-[0.375rem]`}>
            <div className='w-full'>
                <div className={`flex gap-2 flex-wrap`}>
                    {tagList.map(doc => {
                        return (
                            <div key={doc.id} className='bg-gray-100 rounded-sm text-sm py-[0.125rem] px-2 flex items-center gap-1'>
                                <p>{documentNameCreator(type, product?.name, doc)}</p>
                                <IconClose className='w-3 cursor-pointer' onClick={() => {onRemove(doc, product?.id)}}/>
                            </div>
                        )
                    })}
                    {showingMore &&
                    rest.map(doc => {
                        return (
                            <div key={doc.id} className='bg-gray-100 rounded-sm text-sm py-[0.125rem] px-2 flex items-center gap-1'>
                                <p>{documentNameCreator(type, product?.name, doc)}</p>
                                <IconClose className='w-3 cursor-pointer' onClick={() => {onRemove(doc, product?.id)}}/>
                            </div>
                        )
                    })
                    }
                    {rest &&
                    <button className='underline underline-offset-4 text-cyan' onClick={() => {setShowingMore(!showingMore)}}>show {rest.length} {showingMore ? 'less' : 'more'}</button>
                    }
                </div>
            </div>
            
        </div>
    )
}

const SharedDocumentsList = ({datasheets = [], removePds, removeSds, removeRis, removeEri, marketing = [], removeMarketing, certificates = [], removeCertificate}) => {

    const datasheetsCount = useMemo(() => {
        let count = 0
        datasheets.forEach(product => {count += ((product.pds?.length ?? 0) + (product.sds?.length ?? 0) + (product.ris.length ?? 0) + (product.eri.length ?? 0))})
        return count
    }, [datasheets])

    return (  
        <>
            <p className='mb-2 text-sm font-semibold'>Selected documents*</p>
            <div className='p-2 pl-3 bg-gray-25 shadow-card'>
                <Tab.Group>
                    <Tab.List as='div' className='flex gap-8 py-2'>
                        <DocumentTab title='Datasheets' count={datasheetsCount}/>
                        <DocumentTab title='Marketing  Materials' count={marketing.length ?? 0}/>
                        <DocumentTab title='Certificates' count={certificates.length ?? 0}/>
                    </Tab.List>
                    <Tab.Panels as='div' className='overflow-y-auto customScrollbars max-h-[20vh] py-3'>
                        <Tab.Panel as='div' className='flex flex-col gap-4'>
                        {datasheets.map(collection => 
                            <div key={collection.product.id}>
                                <h5 className='text-sm text-blue'>{collection.product.name}</h5>
                                <div className='flex flex-col gap-px'>
                                    {collection.pds.length > 0 && <SharedDatasheetsList type={DocumentTypes.PDS} docList={collection.pds} onRemove={removePds} product={collection.product} />}
                                    {collection.sds.length > 0 && <SharedDatasheetsList type={DocumentTypes.SDS} docList={collection.sds} onRemove={removeSds} product={collection.product} />}
                                    {collection.ris.length > 0 && <SharedDatasheetsList type={DocumentTypes.RIS} docList={collection.ris} onRemove={removeRis} product={collection.product} />}
                                    {collection.eri.length > 0 && <SharedDatasheetsList type={DocumentTypes.ERI} docList={collection.eri} onRemove={removeEri} product={collection.product} />}
                                </div>
                            </div>
                        )}
                        </Tab.Panel>
                        <Tab.Panel>
                            {marketing.length > 0 &&
                                <SharedDatasheetsList type={DocumentTypes.MARKETING} docList={marketing} onRemove={removeMarketing} />
                            }
                        </Tab.Panel>
                        <Tab.Panel>
                            {certificates.length > 0 &&
                                <SharedDatasheetsList type={DocumentTypes.CERTIFICATES} docList={certificates} onRemove={removeCertificate} />
                            }
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </>
    );
}
 
export default SharedDocumentsList;