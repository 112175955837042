import React, { useMemo, useState } from "react";
import DatasheetList from "../../components/products/datasheets/DatasheetList";
import PropTypes from "prop-types";
import {ReactComponent as IconDocument} from "../../assets/icons/Icon_Document.svg";
import DocumentSelectionModal from "../../components/products/document-selection/DocumentSelectionModal";
import { DocumentTypes } from "../../store/types";

const DatasheetsOverview = (props) => {

    const [selectedPds, setSelectedPds] = useState([]);
    const [selectedSds, setSelectedSds] = useState([]);
    const [selectedRis, setSelectedRis] = useState([]);
    const [selectedEri, setSelectedEri] = useState([]);

    const selectionTotalCount = selectedPds.length + selectedSds.length + selectedRis.length + selectedEri.length;

    const removeDocumentFromSelection = (collection, collectionSetter) => selection => {
        const index = collection.findIndex(ds => ds.id === selection.id);
        if(index >= 0) {
            const copy = [...collection];
            copy.splice(index, 1);
            collectionSetter(copy);
        }
    }

    const getFormatedDatasheetsSelection = useMemo(() => {
        return [{
            product: props.product,
            pds: selectedPds,
            sds: selectedSds,
            ris: selectedRis,
            eri: selectedEri,
        }]
    }, [props.product, selectedPds, selectedSds, selectedRis, selectedEri])

    return (
        <div className="datasheetOverview">
            <div className="mb-8">
                <div className="flex items-center mb-4">
                    <span className="mr-1 text-product"><IconDocument /></span>
                    <h3 className="text-sm font-bold">Product Data Sheets</h3>
                </div>
                <DatasheetList 
                    slug={props.slug}
                    type={DocumentTypes.PDS} 
                    selectedDatasheets={selectedPds} 
                    setDatasheetSelection={setSelectedPds}
                    product={props.product}
                />
            </div>
            <div className="mb-8">
                <div className="flex items-center mb-4">
                    <span className="mr-1 text-safety"><IconDocument /></span>
                    <h3 className="text-sm font-bold">Safety Data Sheets</h3>
                </div>
                <DatasheetList 
                    slug={props.slug}
                    type={DocumentTypes.SDS} 
                    selectedDatasheets={selectedSds} 
                    setDatasheetSelection={setSelectedSds}
                    availableLanguages={props.availableSdsLanguages}
                    product={props.product}
                />
            </div>
            <div className="mb-8">
                <div className="flex items-center mb-4">
                    <span className="mr-1 text-regulatory"><IconDocument /></span>
                    <h3 className="text-sm font-bold">Regulatory Information Sheets</h3>
                </div>
                <DatasheetList 
                    slug={props.slug}
                    type={DocumentTypes.RIS} 
                    selectedDatasheets={selectedRis} 
                    setDatasheetSelection={setSelectedRis} 
                    product={props.product}
                />
            </div>
            <div className="mb-8">
                <div className="flex items-center mb-4">
                    <span className="mr-1 text-yellow-500"><IconDocument /></span>
                    <h3 className="text-sm font-bold">Emergency Response Information</h3>
                </div>
                <DatasheetList 
                    slug={props.slug}
                    type={DocumentTypes.ERI} 
                    selectedDatasheets={selectedEri} 
                    setDatasheetSelection={setSelectedEri} 
                    product={props.product}
                />
            </div>
            <p className="text-sm font-semibold text-gray">
                * The displayed date does not refer to the creation date of the document but to the upload date of the document in the database.
            </p>
            { selectionTotalCount > 0 &&
                <DocumentSelectionModal 
                    slug={props.slug}
                    count={selectionTotalCount} 
                    datasheets={getFormatedDatasheetsSelection}
                    removePds={removeDocumentFromSelection(selectedPds, setSelectedPds)}
                    removeSds={removeDocumentFromSelection(selectedSds, setSelectedSds)}
                    removeRis={removeDocumentFromSelection(selectedRis, setSelectedRis)}
                    removeEri={removeDocumentFromSelection(selectedEri, setSelectedEri)}
                    onClose={() => {setSelectedPds([]); setSelectedSds([]); setSelectedRis([]); setSelectedEri([])}}
                />
            }
        </div>
    );
}

DatasheetsOverview.propTypes = {
    /**
     * Slug of the featured product
     */
    slug: PropTypes.string,
    /**
     * Only displays datasheets with selected languages
     * empty array to display all languages
     */
    //selectedLanguages: PropTypes.array,
    availableSdsLanguages: PropTypes.array,

    product: PropTypes.object,
}

 export default DatasheetsOverview;