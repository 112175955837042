import { useMemo } from "react"
import { useSelector } from "react-redux"
import { basketSelectionSelectors } from "../store/slices/basket-selection/basketSelection"

const useTotalBasketSelectionCount = () => {
    const pdsCount = useSelector(state => basketSelectionSelectors.pds.selectTotal(state))
    const sdsCount = useSelector(state => basketSelectionSelectors.sds.selectTotal(state))
    const risCount = useSelector(state => basketSelectionSelectors.ris.selectTotal(state))
    const eriCount = useSelector(state => basketSelectionSelectors.eri.selectTotal(state))
    const marketingCount = useSelector(state => basketSelectionSelectors.marketing.selectTotal(state))
    const certificatesCount = useSelector(state => basketSelectionSelectors.certificates.selectTotal(state))

    const total = useMemo(() => (pdsCount + sdsCount + risCount + eriCount + marketingCount + certificatesCount), [certificatesCount, eriCount, marketingCount, pdsCount, risCount, sdsCount])

    return total
}

export default useTotalBasketSelectionCount