import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { api } from "../../api/api";
import { useSelector } from "react-redux";
import { MdHelp } from "react-icons/md";

//import LanguageFilterDopdown from "../../components/products/LanguageFilterDropdown";
import DefaultRecourcesCard from "../../components/products/default-documents/DefaultRecourcesCard";
import RegulatoryInfosCard from "../../components/products/general-infos/RegulatoryInfosCard";
import DatasheetsOverview from "./DatasheetsOverview";
import background from "../../assets/SASOL_Header_Illustration_.jpg";
import ErrorPage from "../../pages/ErrorPage";
import HeaderExtension from "../../components/elements/HeaderExtension";
import MarketingMaterialList from "../../components/products/marketing-material/MarketingMaterialList";
import { useMemo } from "react";
import BackButton from "../../components/elements/BackButton";
import useCustomerViewActive from '../../hooks/useCustomerViewActive'

const getSubsetOfProduct = ({id, slug, name, available_countries, available_languages}) => {
    if(id, slug, name, available_countries, available_languages) {
        return {id, slug, name, available_countries, available_languages}
    }
    else {
        return null
    }
}

const ProductPage = () => {
    const { slug } = useParams();
    const [product, setProduct] = useState({});
    const user = useSelector((state) => state.auth.user);
    const customerViewIsActive = useCustomerViewActive()
    const location = useLocation()

    useEffect(() => {
        const response = api.getProductDetails(slug);
        response
            .then((response) => {
                response.data.available_languages.sort((lang1, lang2) => {
                    return lang1.label.localeCompare(lang2.label);
                });
                setProduct(response.data);
            })
            .catch((error) => {
                console.log(error);
                setProduct({ error: error });
            });
    }, [setProduct, slug]);

    const productSubset = useMemo(() => getSubsetOfProduct(product), [product])

    if (product.error) {
        return (
            <ErrorPage
                error={{
                    code: product.error.response.status,
                    message: product.error.message,
                }}
            />
        );
    }

    return (
        <div className="flex flex-col productDetailsPage">
            <HeaderExtension>
                <BackButton productIndex={location.state?.productIndex} />
            </HeaderExtension>
            <div
                className="py-6 bg-cover lg:py-10"
                style={{ backgroundImage: `url(${background})` }}
            >
                <div className="container">
                    <h1 className="text-2xl font-bold lg:text-5xl ">
                        <span className="relative">
                            {product.name}
                            {user?.internal && !customerViewIsActive && product.name && (
                                <a
                                    className="absolute -right-7 -top-2 text-lg inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-400 text-blue rounded-full"
                                    href={`${
                                        window.__RUNTIME_CONFIG__
                                            .REACT_INTERNAL_PDB
                                    }/administration/external/?search=${encodeURI(
                                        product.name
                                    )}&exact=true`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <MdHelp />
                                </a>
                            )}
                        </span>
                    </h1>
                </div>
            </div>
            <div className="detailsHeaderContainer ">
                <div className="container flex flex-col-reverse gap-12 py-10 lg:flex-row lg:flex-nowrap">
                    <div className="lg:w-7/12">
                        <h2 className="mb-4 text-2xl font-semibold">
                            General Information
                        </h2>
                        <RegulatoryInfosCard product={product} />
                    </div>
                    <div className="lg:w-5/12">
                        <DefaultRecourcesCard
                            pds={product.default_pds}
                            sds={product.default_sds}
                            ris={product.default_ris}
                            linkId={"allRecources"}
                            productName={product.name}
                        />
                    </div>
                </div>
            </div>
            <div
                id="allRecources"
                className="py-16 datasheets bg-gray-50 scroll-my-header"
            >
                <div className="container">
                    <h2 className="mb-4 text-2xl font-semibold text-darkblue">
                        All Data Sheets
                    </h2>
                    <DatasheetsOverview
                        slug={slug}
                        availableSdsLanguages={product.available_languages}
                        product={productSubset}
                    />
                </div>
            </div>
            <div className="py-16 ">
                <div className="container">
                    <h2 className="mb-4 text-2xl font-semibold text-darkblue">
                        Marketing Material
                    </h2>
                    <MarketingMaterialList slug={slug} />
                </div>
            </div>
        </div>
    );
};

export default ProductPage;
