import { CLOSE_CHATBOT, OPEN_CHATBOT, RESET_CHATBOT } from "../types"

export const toggleChatbot = (isOpen) => dispatch => {
    if(isOpen) {
        dispatch({type: CLOSE_CHATBOT});
    }
    else {
        dispatch({type: OPEN_CHATBOT});
    }
}

export const openChatbot = () => dispatch => {
    dispatch({type: OPEN_CHATBOT});
}

export const closeChatbot = () => dispatch => {
    dispatch({type: CLOSE_CHATBOT});
}

export const resetChatbot = () => dispatch => {
    dispatch({
        type: RESET_CHATBOT,
        payload: Math.floor(Math.random() * 100),
    });
}

