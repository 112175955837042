
import { configureStore } from '@reduxjs/toolkit';
import auth from './reducers/auth';
import chatbot from './reducers/chatbot';
import basket from './slices/basket/basket';
import basketSelection from './slices/basket-selection/basketSelection';


let store = configureStore({
    
    reducer: {
        auth,
        chatbot,
        basket,
        basketSelection,
    }
}
);

export default store;
