import React from 'react';

const PopupCard = ({icon, title, subline, children}) => {
    return (
        <div className='flex flex-col items-center gap-6 p-8 text-center bg-white rounded-lg shadow-card'>
            {icon && <div className='text-cyan'>{icon}</div>}
            {(title || subline) && 
            <div className='flex flex-col items-center gap-1'>
                {title && <h2 className='text-2xl font-semibold text-blue'>{title}</h2>}
                {subline && <p className='text-gray'>{subline}</p>}
            </div>
            }
            {children}
        </div>
    )
}
 
export default PopupCard;