import ContactFormSummary from "../customMessages/contact-form/ContactFormSummary";
import SubmitContactForm from "../customMessages/contact-form/SubmitContactForm";
import { requiredNotBlank } from "../validators/validators";
import getSasolRepresentativeSteps from "./reusable-steps/sasol-representativ-data";
import getUserSteps from "./reusable-steps/user-data";

function getContactFormSteps(stepAfterFinish) {
    const prefix = 'cf_';
    const repSteps = getSasolRepresentativeSteps('cf_message_question', prefix);
    const userSteps = getUserSteps('cf_summary', prefix);
    const baseSteps = [
        {
          id: 'cf_message_question',
          message: 'Alright. Please type your message',
          trigger: 'cf_message',
        },
        {
            id: 'cf_message',
            user: true,
            trigger: userSteps.firstStepId,
            validator: requiredNotBlank,
        },
        {
            id: 'cf_summary',
            component: <ContactFormSummary repStep={repSteps.dataStepId} messageStep={'cf_message'} />,
            asMessage: true,
            trigger: 'cf_submit',
        },
        {
            id: 'cf_submit',
            component: <SubmitContactForm repStep={repSteps.dataStepId} userStep={userSteps.dataStepId} messageStep={'cf_message'} onErrorStep={'cf_submit_error'} onCancelStep={stepAfterFinish} onSuccessStep={stepAfterFinish} />,
            waitAction: true,
            replace: true,
        },
        {
            id: 'cf_submit_error',
            message: 'Message could\'t be send. Please try again',
            trigger: 'cf_submit',
        },
        {
            id: 'cf_end',
            message: 'Your message was sent',
            trigger: stepAfterFinish,
        },
    ]
    const steps = baseSteps.concat(repSteps.steps).concat(userSteps.steps);
    return {
        firstStepId: repSteps.firstStepId,
        steps: steps,
    }
} 
export default getContactFormSteps;
