import ContactDataOverview from "../customMessages/user/ContactDataOverview";
import SaveContactData from "../customMessages/user/SaveContactData";
import SaveUserDataConfirm from "../customMessages/user/SaveUserDataConfirm";
import UserDataCheck from "../customMessages/user/UserDataCheck";
import { requiredNotBlank } from "../validators/validators";

export default function getUpdateUserSteps(stepAfterFinish) {
    const prefix = 'uu_';
    const firstStepId = prefix + 'check_user'
    const steps = [
        {
            id: firstStepId,
            component: <UserDataCheck triggerTrue={prefix + 'user'} triggerFalse={prefix + 'no_user'} />,
            waitAction: true,
            replace: true,
        },
        {
            id: prefix + 'user',
            component: <ContactDataOverview 
                trigger={prefix + 'updating_question'} 
                phoneInputStep={prefix + 'user_telephone_input'}
                organizationInputStep={prefix + 'user_organization_input'}
                jobInputStep={prefix + 'user_job_input'} 
            />,
            asMessage: true,
            waitAction: true,
        },
        {
            id: prefix + 'updating_question',
            message: 'Which information do you want to update?',
            trigger: prefix + 'updating_options', 
        },
        {
            id: prefix + 'updating_options',
            options: [
                {value: 'phone', label: 'Telephone number', trigger: prefix + 'user_telephone_question'},
                {value: 'job', label: 'Job title', trigger: prefix + 'user_job_question'},
                {value: 'organization', label: 'Organization', trigger: prefix + 'user_organization_question'},
                {value: 'cancel', label: 'Cancel', trigger: stepAfterFinish},
            ]
        },
        {
            id: prefix + 'saving_error',
            message: 'The data could not be saved! Please try again.',
            trigger: prefix + 'updating_options', 
        },
        {
            id: prefix + 'saving_success',
            message: 'The data was successfully saved!',
            trigger: prefix + 'user', 
        },
        {
            id: prefix + 'user_telephone_question',
            message: 'What is your telephone number?',
            trigger: prefix + 'user_telephone_input',
        },
        {
            id: prefix + 'user_telephone_input',
            user: true,
            trigger: prefix + 'save_telephone',
            validator: requiredNotBlank,
        },
        {
            id: prefix + 'save_telephone',
            component: <SaveContactData trigger={prefix + 'saving_success'} phoneStep={prefix + 'user_telephone_input'} triggerOnError={prefix + 'saving_error'} />,
            waitAction: true,
            replace: true,
        },
        {
            id: prefix + 'user_organization_question',
            message: 'What is the name of the organization you\'re working for?',
            trigger: prefix + 'user_organization_input',
        },
        {
            id: prefix + 'user_organization_input',
            user: true,
            trigger: prefix + 'save_organization',
            validator: requiredNotBlank,
        },
        {
            id: prefix + 'save_organization',
            component: <SaveContactData trigger={prefix + 'saving_success'} organizationStep={prefix + 'user_organization_input'} triggerOnError={prefix + 'saving_error'} />,
            waitAction: true,
            replace: true,
        },
        {
            id: prefix + 'user_job_question',
            message: 'What is your job title?',
            trigger: prefix + 'user_job_input',
        },
        {
            id: prefix + 'user_job_input',
            user: true,
            trigger: prefix + 'save_job',
            validator: requiredNotBlank,
        },
        {
            id: prefix + 'save_job',
            component: <SaveContactData trigger={prefix + 'saving_success'} jobStep={prefix + 'user_job_input'} triggerOnError={prefix + 'saving_error'} />,
            waitAction: true,
            replace: true,
        },
        {
            id: prefix + 'no_user',
            message: 'I could not find your user profile. Please log in and try again',
            trigger: stepAfterFinish,
        }
    ];

    return {
        firstStepId: firstStepId,
        steps: steps,
    } 
}