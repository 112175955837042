import React from 'react';
import background from "../assets/SASOL_Header_Illustration_.jpg";
import PrimaryButton from '../components/elements/PrimaryButton';
import {useNavigate} from 'react-router-dom'


const ErrorPage = ({error}) => {
    const navigate = useNavigate();

    return (
        <div className='errorPage bg-cover min-h-[90vh] flex items-center' style={{backgroundImage: `url(${background})`}}>
            <div className='container flex gap-16 items-center'>
                <div className='text-gray-400 font-bold text-error'>
                    {error.code}
                </div>
                <div className='flex flex-col justify-center gap-4'>
                    <div>
                        <h2 className='text-darkblue font-bold text-5xl mb-8'>
                            {error.message}
                        </h2>
                        <p className='text-gray text-xl font-light'>
                            You can navigate through our menu or use this Button:
                        </p>
                    </div>
                    <div>
                        <PrimaryButton color='blue' onClick={() => {navigate('/')}} title="Go to Homepage" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage;