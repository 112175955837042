import { Disclosure } from '@headlessui/react';
import React from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {ReactComponent as IconDown} from '../../assets/icons/Icon_Chevron-Down.svg'
import './FAQQuestion.scss'

const FAQQuestion = ({question, answer, defaultOpen}) => {

    const [isHovered, setIsHovered] = useState(false)
    const [, setUrlSearchParams] = useSearchParams();

    return (  
        <Disclosure as={'div'} className='w-full py-4 border-b border-b-gray-400' defaultOpen={defaultOpen}>
            {({open}) => (
            <>
                <Disclosure.Button 
                    className='flex justify-between w-full gap-2 text-lg font-semibold text-left ui-open:text-cyan hover:text-cyan'
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={() => {setUrlSearchParams(open ? {} : {question: question})}}
                >
                    <p className={isHovered ? 'text-cyan' : ''}>{question}</p>
                    <div className={`p-1 border rounded ui-open:rotate-180 ui-open:border-cyan ui-open:bg-cyan-50 ${isHovered ? 'text-cyan border-cyan bg-cyan-50' : 'border-gray-400 bg-gray-50'}`}>
                        <IconDown className='w-6' />
                    </div>
                </Disclosure.Button>
                <Disclosure.Panel className='mt-4 showListStyles showStyledLinks faqAnswer' dangerouslySetInnerHTML={{__html: answer}} />
            </>
            )}
        </Disclosure>
    )
}
 
export default FAQQuestion;