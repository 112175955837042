
import { Navigate} from "react-router-dom";
import {Outlet} from "react-router-dom";
import {useLocation} from "react-router";
// import { InteractionStatus } from "@azure/msal-browser";
// import RequestStatus from "../components/elements/RequestStatus";
// import { REQUEST_STATUS, sessionStorageRedirectKey } from "../store/types";

const ProtectedRoute = ({isAuthenticated, inProgress, user, userLoadingStatus}) => {
  const location = useLocation();
  if (!isAuthenticated) {
    return <Navigate to="/login" replace state={{redirect: location}}/>;
  }
  // else if(inProgress !== InteractionStatus.None || user === null) {
  //   return (
  //     <div className="w-full min-h-[50vh] flex items-center justify-center">
  //       <RequestStatus status={userLoadingStatus} size={40}>
  //       {userLoadingStatus === REQUEST_STATUS.ERROR &&
  //         <div className="mt-2 text-center text-red-600 text-md">Authentication Error.<br/> Unable to retrieve userdata.<br/> Please logout and try again.</div>
  //       }
  //       </RequestStatus>
  //     </div>
  //   )
  // }
  return <Outlet />;
};

export default ProtectedRoute;