import { useEffect, useState } from "react"


const useCheckAndTrigger = (item, triggerOnTrue, triggerOnFalse, triggerNextStep) => {
    const [itemState] = useState(item);
    useEffect(() => {
        if(!itemState) {
            setTimeout(() => triggerNextStep({value: itemState, trigger: triggerOnFalse}), 1000);
        }
        else {
            setTimeout(() => triggerNextStep({value: itemState, trigger: triggerOnTrue}), 1000);
        }
    // run this only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}

export default useCheckAndTrigger;