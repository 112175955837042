import React from 'react';
import { useState } from 'react';
import DocumentTypeTabs, { DocumentTypeTabOptions } from '../../containers/basket/DocumentTypeTabs';
import BasketHeaderExtension from '../../containers/basket/BasketHeaderExtension';
import DatasheetsBasketList from '../../containers/basket/DatasheetsBasketList';
import EmptyBasketCard from './EmptyBasketCard';
import useTotalBasketCount from '../../hooks/useTotalBasketCount';
import BasketSelectionModal from '../../containers/basket/BasketSelectionModal';
import DocumentBasketList from '../../containers/basket/DocumentBasketList';
import { DocumentTypes } from '../../store/types';

const BasketPage = () => {

    const [selectedDocumentType, setSelectedDocumentType] = useState(Object.keys(DocumentTypeTabOptions)[0]);
    const totalCount = useTotalBasketCount()

    return ( 
    <>
        <BasketHeaderExtension />

        {totalCount <= 0 ?
            <div className='py-16'>
                <EmptyBasketCard />
            </div>
            :
            <>
                <div className='bg-gray-50'>
                    <div className='min-h-[75vh] pb-20'>
                        <div className='mb-6 max-lg:pl-3 lg:container'>    
                            <DocumentTypeTabs selectedType={selectedDocumentType} onSelection={setSelectedDocumentType} />
                        </div>
                        <div className='container'>
                        {selectedDocumentType === Object.keys(DocumentTypeTabOptions).find(key => DocumentTypeTabOptions[key] === DocumentTypeTabOptions.datasheets) &&
                            <DatasheetsBasketList />
                        }
                        {selectedDocumentType === Object.keys(DocumentTypeTabOptions).find(key => DocumentTypeTabOptions[key] === DocumentTypeTabOptions.marketing) &&
                            <DocumentBasketList documentType={DocumentTypes.MARKETING} />
                        }
                        {selectedDocumentType === Object.keys(DocumentTypeTabOptions).find(key => DocumentTypeTabOptions[key] === DocumentTypeTabOptions.certificates) &&
                            <DocumentBasketList documentType={DocumentTypes.CERTIFICATES} />
                        }
                        </div>
                    </div>
                </div>
                <BasketSelectionModal />
            </>
        }
    </> 
    );
}
 
export default BasketPage;