import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./store/store";
import {TourProvider} from "@reactour/tour";
import {defaultSteps} from "./product-tour/steps";
import MsalPropviderWrapper from './containers/auth/MsalProviderWrapper';
import { TwScreenProvider } from './utilities/TailwindScreens';
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from './tailwind.config';

const tailwindScreens = resolveConfig(tailwindConfig).theme.screens

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MsalPropviderWrapper>
        <TwScreenProvider screens={tailwindScreens}>
          <TourProvider steps={defaultSteps} styles={{popover: (base) => {return Object.assign({...base}, {"--reactour-accent": "#00AEEF", maxWidth: '90vw'})}}}>
            <App />
          </TourProvider>
        </TwScreenProvider>
      </MsalPropviderWrapper>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
