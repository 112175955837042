import React from 'react';
import {AiOutlineLoading} from "react-icons/ai";
import {FiAlertTriangle} from 'react-icons/fi';
import { REQUEST_STATUS } from '../../store/types';


const RequestStatus = ({status, size = 20, children}) => {
    const classesLoading = 'animate-spin';
    const classesError = 'text-red-600';
    return (
        <div>            
            <div className="w-full flex items-center justify-center">
                {status === REQUEST_STATUS.PENDING &&
                <AiOutlineLoading size={size} className={classesLoading} />
                }
                {status === REQUEST_STATUS.ERROR &&
                <FiAlertTriangle size={size} className={classesError} />
                }
            </div>
            {children}
        </div>
    )
}

export default RequestStatus;