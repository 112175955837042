import React from "react";
import PropTypes from "prop-types";
const LoadMore = ({ onClick, loading }) => {
    return (
        <div className="flex mt-5 justify-center">
            <button
                className="bg-gray-100 hover:bg-gray-200 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
                onClick={onClick}
            >
                {loading ? "Loading..." : "Load More"}
            </button>
        </div>
    );
};

LoadMore.propTypes = {
    /**
     * Load more button 
     * @param {function} onClick - function to be called when the button is clicked
     * @param {boolean} loading - boolean to indicate if the button is loading
     * @returns {JSX.Element}
        */
    onClick: PropTypes.func,
    loading: PropTypes.bool,
};

export default LoadMore;