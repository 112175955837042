import { Listbox } from '@headlessui/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { api } from '../../api/api'
import RequestStatus from '../../components/elements/RequestStatus'
import { REQUEST_STATUS } from '../../store/types'
import {ReactComponent as IconChevronDown} from '../../assets/icons/Icon_Chevron-Down.svg'
import { sasolDefaultSelectionValue } from '../../types' 

const SelectSdsPreferences = (
    {
        langSelection, 
        countrySelection, 
        langSelectionInput, 
        onLangSelectionInput, 
        countrySelectionInput, 
        onCountrySelectionInput, 
        selectionStatus, 
        isShowingDisclaimer,
        langOptions,
        countryOptions,
        optionsAreLoading,
        defaultSelection = sasolDefaultSelectionValue,
    }
) => 
{
    const [highlightedLangs, setHighlightedLangs] = useState([])
    const [highlightedCountries, setHighlightedCountries] = useState([])
    
    const mappedLangSelection = useMemo(() => {
        if(langSelection){
            const index = langOptions.findIndex(entry => entry.value === langSelection)
            if(index >= 0){
                return langOptions[index]
            }
            else {
                return defaultSelection
            }    
        }
        else {
            return defaultSelection
        }
    }, [defaultSelection, langOptions, langSelection])

    const mappedCountrySelection = useMemo(() => {
        if(countrySelection && countryOptions.length > 0){
            const index = countryOptions.findIndex(entry => entry.value === countrySelection)
            if(index >= 0){
                return countryOptions[index]
            }  
            else {
                return defaultSelection
            }   
        }
        else {
            return defaultSelection
        }
    }, [countryOptions, countrySelection, defaultSelection])

    const displayedLangSelection = useMemo(() => {
        return langSelectionInput ? langSelectionInput : mappedLangSelection
    }, [langSelectionInput, mappedLangSelection])

    const displayedCountrySelection = useMemo(() => {
        return countrySelectionInput ? countrySelectionInput : mappedCountrySelection
    }, [countrySelectionInput, mappedCountrySelection])

    const getHighlightedCountries = useCallback(() => {
        if(displayedLangSelection) {
            api.getSDSCountryOptions(displayedLangSelection.value)
            .then(response => {
                setHighlightedCountries(response.data.map(entry => entry.value))
            })
            .catch(error => {
                console.log(error)
            })
        }
    }, [displayedLangSelection])

    const getHighlightedLanguages = useCallback(() => {
        if(displayedCountrySelection) {
            api.getSDSLanguageOptions(displayedCountrySelection.value)
            .then(response => {
                setHighlightedLangs(response.data.map(entry => entry.value))
            })
            .catch(error => {
                console.log(error)
            })
        }
    }, [displayedCountrySelection])

    useEffect(() => {
        getHighlightedCountries()
    }, [getHighlightedCountries])

    useEffect(() => {
        getHighlightedLanguages()
    }, [getHighlightedLanguages])

    return (
        <div className='h-full mb-2'>
            {isShowingDisclaimer && <p className='text-sm font-semibold text-red-600'>Please select a valid combination of language and country</p>}
            <div className='flex flex-col gap-4 mb-2 lg:flex-row'>
                <div className='lg:basis-1/2'>
                    <div className='relative w-full'>
                        <Listbox value={displayedLangSelection} onChange={onLangSelectionInput} disabled={optionsAreLoading || selectionStatus !== REQUEST_STATUS.IDLE}>
                            <Listbox.Label className='mb-1 text-sm font-semibold'>Language for Safety Data Sheet</Listbox.Label>
                            <Listbox.Button 
                                className={`flex justify-between w-full p-2 border ${isShowingDisclaimer ? 'border-red-600' : 'border-gray-400 hover:border-cyan'} rounded text-start`}
                            >
                                <span>{displayedLangSelection?.label ?? 'Selection not found'}</span>
                                {optionsAreLoading ? 
                                    <RequestStatus status={REQUEST_STATUS.PENDING}/>
                                :
                                    <IconChevronDown className='transition-transform ui-open:rotate-180' />
                                }
                            </Listbox.Button>
                            <Listbox.Options className='absolute z-10 w-full py-2 mt-1 overflow-auto bg-white rounded max-h-40 shadow-card customScrollbarsLight'>
                            <Listbox.Option 
                                key={'no_selection'} 
                                value={defaultSelection} 
                                className='px-2 py-1 cursor-pointer text-gray hover:bg-gray-50'
                            >
                                {defaultSelection.label}
                            </Listbox.Option>
                                {langOptions.map(option => (
                                    <Listbox.Option 
                                        key={option.value} 
                                        value={option} 
                                        className={`px-2 py-1 cursor-pointer ${highlightedLangs && highlightedLangs.includes(option.value) ? 'text-blue font-semibold' : 'text-gray'} hover:bg-gray-50`}
                                    >
                                        {option.label}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Listbox>
                    </div>
                </div> 
                <div className='lg:basis-1/2'>
                    <div className='relative w-full'>
                        <Listbox value={displayedCountrySelection} onChange={onCountrySelectionInput} disabled={optionsAreLoading || selectionStatus !== REQUEST_STATUS.IDLE}>
                            <Listbox.Label className='mb-1 text-sm font-semibold'>Country for Safety Data Sheet</Listbox.Label>
                            <Listbox.Button 
                                className={`flex justify-between w-full p-2 border ${isShowingDisclaimer ? 'border-red-600' : 'border-gray-400 hover:border-cyan'} rounded text-start`}
                            >
                                <span>{displayedCountrySelection?.label ?? 'Selection not found'}</span>
                                {optionsAreLoading ? 
                                    <RequestStatus status={REQUEST_STATUS.PENDING}/>
                                :
                                    <IconChevronDown className='transition-transform ui-open:rotate-180' />
                                }
                            </Listbox.Button>
                            <Listbox.Options className='absolute z-10 w-full py-2 mt-1 overflow-auto bg-white rounded max-h-40 shadow-card customScrollbarsLight'>
                                <Listbox.Option 
                                    key={'no_selection'} 
                                    value={defaultSelection} 
                                    className='px-2 py-1 cursor-pointer text-gray hover:bg-gray-50'
                                >
                                    {defaultSelection.label}
                                </Listbox.Option>
                                {countryOptions.map(option => (
                                    <Listbox.Option 
                                        key={option.value} 
                                        value={option} 
                                        className={`px-2 py-1 cursor-pointer ${highlightedCountries && highlightedCountries.includes(option.value) ? 'text-blue font-semibold' : 'text-gray'} hover:bg-gray-50`}
                                    >
                                        {option.label}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Listbox>
                    </div>
                </div> 
            </div>
        </div>
    )
}

export default SelectSdsPreferences