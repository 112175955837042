import React from 'react';

const HeaderExtension = (props) => {
    return (
        <div className="sticky z-20 px-3 py-3 bg-white lg:px-6 backLink top-header shadow-card">
            {props.children}
        </div>
    )
}

export default HeaderExtension;