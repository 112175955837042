function createDocumentParams(pdsCount, sdsCount, risCount, eriCount, marketingCount, certificatesCount) {
    const total = pdsCount + sdsCount + risCount + eriCount + marketingCount + certificatesCount
    return {
        pds: pdsCount,
        sds: sdsCount,
        ris: risCount,
        eri: eriCount,
        marketing: marketingCount,
        certificates: certificatesCount,
        total: total,
    }
}

function downloadEvent(pdsCount, sdsCount, risCount, eriCount, marketingCount, certificatesCount) {
    window.gtag('event', 'document_download', createDocumentParams(pdsCount, sdsCount, risCount, eriCount, marketingCount, certificatesCount))
}

function shareEvent(pdsCount, sdsCount, risCount, eriCount, marketingCount, certificatesCount) {
    window.gtag('event', 'document_share', createDocumentParams(pdsCount, sdsCount, risCount, eriCount, marketingCount, certificatesCount))
}

const GA4Helper = {downloadEvent, shareEvent}

export default GA4Helper