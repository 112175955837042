import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as IconDownload } from '../../../assets/icons/Icon_Download.svg';
import { ReactComponent as IconShare } from '../../../assets/icons/Icon_Share.svg';
import { ReactComponent as IconBasket } from '../../../assets/icons/Icon_Basket.svg';
import { ReactComponent as IconTrash } from '../../../assets/icons/Icon_Trash.svg';
import SecondaryButton from '../../elements/SecondaryButton';
import useModal from '../../../hooks/useModal';
import Modal from '../../elements/Modal';
import ShareForm from './ShareForm';
import {useDispatch} from 'react-redux';
import CloseButton from '../../elements/CloseButton';
import { addDocumentsToBasket, openSidebar } from '../../../store/slices/basket/basket';
import { useMemo } from 'react';
import useDownloadDocuments from '../../../hooks/useDownloadDocuments';

const DocumentSelectionModal = (props) => {   
    const {loadingDocuments, downloadDocuments} = useDownloadDocuments()
    const {isShowing, toggle} = useModal();
    const dispatch = useDispatch()
    const [pds, sds, ris, eri] = useMemo(() => {
        const pds = []
        const sds = []
        const ris = []
        const eri = []
        props.datasheets?.forEach(product => {
            pds.push(...product.pds)
            sds.push(...product.sds)
            ris.push(...product.ris)
            eri.push(...product.eri)
        })
        return [pds, sds, ris, eri]
    }, [props.datasheets])
    
    function downloadDocs() {
        const docName = (!props.datasheets || props.datasheets.length > 1 || props.datasheets.length === 0) ? null : props.datasheets[0].product.name
        downloadDocuments({pds: pds.map(d => d.id), sds: sds.map(d => d.id), ris: ris.map(d => d.id), eri: eri.map(d => d.id), marketing: props.marketing?.map(d => d.id) ?? [], certificates: props.certificates?.map(d => d.id) ?? []}, docName)
    }

    const collectDocumentsInBasket = useCallback(() => {
        dispatch(addDocumentsToBasket((props.datasheets && props.datasheets.length > 0) ? props.datasheets[0].product : null, pds, sds, ris, eri, props.marketing, props.certificates))
        dispatch(openSidebar())
    }, [dispatch, eri, pds, props.certificates, props.datasheets, props.marketing, ris, sds])

    return (
        <div className="fixed right-0 z-20 w-full pointer-events-none documentSelectionModal bottom-8">
            <div className='flex items-stretch justify-center gap-px mx-auto rounded shadow-lg pointer-events-auto max-w-fit bg-gray-50'>
                <div className='flex items-center self-stretch p-3 font-semibold text-white rounded-l bg-blue'>{props.count}</div>
                <div className='flex items-center px-4 py-3 font-medium bg-white'>Item{props.count > 1 && 's'} selected</div>
                <div className='flex items-center gap-2 px-4 py-2 bg-white'>
                    {!!props.onRemoveFromBasket &&
                    <SecondaryButton onClick={props.onRemoveFromBasket}>
                        <div className='flex items-center gap-1 flex-nowrap'>
                            <IconTrash className='w-4' />
                            <span className='withespace-nowrap'>Remove</span>
                        </div>
                    </SecondaryButton>
                    }
                    <SecondaryButton onClick={toggle}><IconShare className='w-4 mr-1'/>Share via email</SecondaryButton>
                    {!props.onRemoveFromBasket &&
                    <SecondaryButton cyan onClick={collectDocumentsInBasket}>
                        <div className='flex items-center gap-1 flex-nowrap'>
                            <IconBasket className='w-4' />
                            <span className='withespace-nowrap'>Collect selected</span>
                        </div>
                    </SecondaryButton>}
                    {loadingDocuments ? 
                        <SecondaryButton>Loading</SecondaryButton> : 
                        <SecondaryButton cyan onClick={downloadDocs} solid={!!props.onRemoveFromBasket} >
                            <div className='flex items-center gap-1 flex-nowrap'>
                                <IconDownload className='w-4'/>
                                {!!props.onRemoveFromBasket && <span className='withespace-nowrap'>Download</span>}
                            </div>
                        </SecondaryButton>}
                </div>
                <div className='flex items-center self-stretch px-4 py-4 font-bold bg-white rounded-r'>
                    <CloseButton onClose={props.onClose} />
                </div>
            </div>
            <Modal isShowing={isShowing} close={toggle}>                    
                    <ShareForm 
                        datasheets={props.datasheets}
                        removePds={props.removePds}
                        removeSds={props.removeSds}
                        removeRis={props.removeRis}
                        removeEri={props.removeEri}
                        marketing={props.marketing}
                        removeMarketing={props.removeMarketing}
                        certificates={props.certificates}
                        removeCertificate={props.removeCertificate}
                        onClose={toggle}
                    />
            </Modal>
        </div>
    )
}

DocumentSelectionModal.propTypes = {
    datasheets: PropTypes.arrayOf(PropTypes.object),
    removePds: PropTypes.func,
    removeSds: PropTypes.func,
    removeRis: PropTypes.func,
    marketing: PropTypes.arrayOf(PropTypes.object),
    removeMarketing: PropTypes.func,
    certificates: PropTypes.arrayOf(PropTypes.object),
    removeCertificate: PropTypes.func,
    count: PropTypes.number,
    onClose: PropTypes.func,
    onRemoveFromBasket: PropTypes.func,
}

export default DocumentSelectionModal;