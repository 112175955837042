import React from 'react';
import { useSelector } from 'react-redux';

const NamedGreeting = () => {
    const userName = useSelector((state) => state.auth.user?.name);
    return (
        <span>
            Hello{userName && ' ' + userName}, my name is Anna. I'm your friendly Sasol representative. How can I help you?
        </span>
    )
}

export default NamedGreeting;