import React from 'react';
import {DocumentTypes} from '../../store/types'

const NewsFilterTabs = ({selectedTab, onChange}) => {

    const docTypeKeys = Object.values(DocumentTypes).slice(0,5)

    return (
        <div className='h-full py-2 rounded max-lg:pl-3 bg-gray-25'>
            <div className='flex max-lg:gap-6 max-lg:pr-3 lg:py-2 lg:flex-col lg:items-stretch max-lg:border-b max-lg:border-b-gray-200 lg:border-r lg:border-r-gray-400 max-lg:overflow-x-scroll hiddenScrollbars'>
                <button onClick={() => onChange(null)} className={`lg:px-6 py-2 font-medium ${selectedTab === null ? 'lg:border-r-2 lg:border-r-cyan text-cyan max-lg:border-b-2 max-lg:border-b-cyan' : ''} text-start hover:text-cyan whitespace-nowrap`}>All updates</button>
                {docTypeKeys.map(type => <button onClick={() => onChange(type)} key={type} className={`lg:px-6 py-2 font-medium ${selectedTab === type ? 'lg:border-r-2 lg:border-r-cyan text-cyan max-lg:border-b-2 max-lg:border-b-cyan' : ''} text-start hover:text-cyan whitespace-nowrap`}>{type}</button>)}
            </div>
        </div>
    );
}
 
export default NewsFilterTabs;