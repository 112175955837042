import React from 'react';
import {Link} from 'react-router-dom';

const Footer = () => {
    return (
        <div className='footer bg-blue'>
            <div className='container flex justify-around py-6 text-white'>
                <Link to="/privacyPolicy" >Confidentiality Statement & Privacy Policy</Link>
            </div>
            <div className='px-3 py-2 text-sm text-center text-gray-800 bg-darkblue'>
                Copyright 2023 © SASOL. All rights not expressly granted are reserved.
            </div>
        </div>
    )
}

export default Footer;