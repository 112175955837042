const ROUTES = {
    home: {path: '/', fullPath: '/'},
    login: {path: 'login', fullPath: '/login'},
    searchResults: {path: 'search', fullPath: '/search'},
    contact: {path: 'contact', fullPath: '/contact'},
    privacy: {path: 'privacyPolicy', fullPath: '/privacyPolicy'},
    agreePrivacy: {path: 'agreePrivacyPolicy', fullPath: '/agreePrivacyPolicy'},
    profile: {path: 'profile', fullPath: '/profile'},
    completeProfile: {path: 'completeProfile', fullPath: '/completeProfile'},
    certificates: {path: 'certificates', fullPath: '/certificates'},
    marketing: {path: 'marketing', fullPath: '/marketing'},
    basket: {path: 'basket', fullPath: '/basket'},
    news: {path: 'news', fullPath: '/news'},
    faq: {path: 'help', fullPath: '/help'},
}

export default ROUTES