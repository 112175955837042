import React, { useState } from 'react';
import { api } from '../../../../api/api';
import PrimaryButton from '../../../../components/elements/PrimaryButton';
import SecondaryButton from '../../../../components/elements/SecondaryButton';

const SubmitContactForm = (props) => {

    const [requestPending, setRequestPending] = useState(false);

    function sendForm() {
        setRequestPending(true);
        const user = props.steps[props.userStep].value
        const data = {
            recipient: props.steps[props.repStep].value,
            ...user,
            message: props.steps[props.messageStep].value,
        };
        api.sendContactForm(data)
            .then(response => {
                console.log(response);
                setRequestPending(false);
                props.triggerNextStep({trigger: props.onSuccessStep})
            })
            .catch(error => {
                setRequestPending(false);
                console.log(error);
                props.triggerNextStep({trigger: props.onErrorStep})
            })
    }
    function cancel() {
        props.triggerNextStep({trigger: props.onCancelStep})
    }
    return (
        <div className='text-blue'>
            {requestPending ?
                <span>Sending</span>
            :
                <div className='flex gap-2'>
                    <SecondaryButton onClick={cancel}>Cancel</SecondaryButton>
                    <PrimaryButton title='Send' onClick={sendForm}/>
                </div>
            }
        </div>
    )
}

export default SubmitContactForm;