import React from 'react'
import { useMemo } from 'react';
import {ReactComponent as IconTrash} from '../../assets/icons/Icon_Trash.svg'
import { DocumentTypes } from '../../store/types';

const ReducedDatasheetBasketListEntry = ({datasheet, type, onRemove, subListItem = false}) => {

    const slot1 = useMemo(() => {
        if(type === DocumentTypes.PDS){
            return datasheet.source
        }
        else if(type === DocumentTypes.SDS || type === DocumentTypes.ERI) {
            return datasheet.country
        }
        else if(type === DocumentTypes.RIS) {
            return 'All'
        }
        else return null
    }, [datasheet, type])

    const slot2 = useMemo(() => {
        return datasheet.language
    }, [datasheet.language])

    if(subListItem) {
        return ( 
            <div className='py-2 border-l border-gray-400 subListItem text-gray'>
                <div className='flex items-center justify-between gap-2'>
                    <div className='w-3 border-b border-gray-400' />
                    <div className='flex-grow text-sm'>
                        <p>{type === DocumentTypes.PDS ? 'Sasol Source' : 'Country'}: {slot1}</p>
                        <p>Language: {slot2}</p>
                    </div>
                    <button onClick={onRemove}><IconTrash className='w-6' /></button>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className='flex items-center gap-1 text-gray'>
                <div className='flex flex-col flex-grow'>
                    <p>{type}</p>
                    <p className='text-sm'>
                        {type === DocumentTypes.PDS ? 'Sasol Source' : 'Country'}: {slot1} | Language: {slot2}
                    </p>
                </div>
                <button onClick={onRemove}><IconTrash className='w-6' /></button>
            </div>
        )
    }
}
 
export default ReducedDatasheetBasketListEntry;