import { Disclosure } from '@headlessui/react';
import React from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as IConChevronDown} from '../../assets/icons/Icon_Chevron-Down.svg'
import { ReactComponent as IconDocument} from '../../assets/icons/Icon_Document.svg'
import BasketTableHeader from '../../containers/basket/BasketTableHeader';
import ReducedDatasheetBasketListEntry from '../../containers/basket/ReducedDatasheetBasketListEntry';
import useDatasheetSelector from '../../hooks/useDatasheetSelector';
import { useDocumentColor } from '../../hooks/useDocumentBorderColor';
import useExtendableColumn from '../../hooks/useExtendableColumn';
import useScrollSync from '../../hooks/useScrollSync';
import { removeDocumentFromBasket } from '../../store/slices/basket/basket';
import DatasheetBasketListEntry from './DatasheetBasketListEntry';
import './DatasheetDisclosure.scss'

const ReducedSingleDatasheetBasketEntry = ({datasheetId, type, product, borderColor}) => {

    const datasheetSelector = useDatasheetSelector(type)
    const datasheet = useSelector(state => datasheetSelector.selectById(state, datasheetId))
    const dispatch = useDispatch()

    return (
        <div className={`shadow-card w-full rounded `}>
            <div className={`bg-white border-l-2 border-l-${borderColor} pl-4 py-2 pr-2`}>
                <ReducedDatasheetBasketListEntry datasheet={datasheet} type={type} onRemove={() => dispatch(removeDocumentFromBasket(product.id, type, datasheetId))} />
            </div>
        </div>
    )
}

const DatasheetDisclosure = ({datasheetIds, product, reduced, datasheetType}) => {
    const count = useMemo(() => datasheetIds.length, [datasheetIds])
    const color = useDocumentColor()(datasheetType)  
    const {handleScroll, addElement} = useScrollSync()
    const [extendableColumnWidth, toggleExtendableColumn] = useExtendableColumn()

    const list = datasheetIds.map((datasheetId, index) => {
        return (
            <DatasheetBasketListEntry 
                key={datasheetId} 
                datasheetId={datasheetId}
                type={datasheetType} 
                product={product}
                reduced={reduced}
                ref={addElement(index + 1)}
                onSyncedScroll={handleScroll}
                extendableColumnWidth={extendableColumnWidth}
                toggleExtendableColumn={toggleExtendableColumn}
            />
        )
    });

    if(reduced && count === 1) {
        return (
            <ReducedSingleDatasheetBasketEntry datasheetId={datasheetIds[0]} type={datasheetType} product={product} borderColor={color} />
        )
    }
    else {
        return (  
            <Disclosure as={'div'} className={`shadow-card w-full rounded datasheetDisclosure`} >
                {({open}) => (
                <>
                    <Disclosure.Button className={`flex justify-between w-full bg-white ${open && !reduced ? 'bg-gray-25' : ''} border-l-2 border-l-${color} ${reduced ? 'text-gray py-2 pl-4 pr-2' : 'py-4 pl-3 pr-4 gap-1'} items-center`}>
                        <div className={`flex ${reduced ? 'flex-col items-start' : 'gap-1 items-center'}`}>
                            {!reduced && <IconDocument className={`w-6 text-${color}`}/>}
                            <span className={reduced ? 'font-medium' : ' text-start w-fit whitespace-pre-line'}>{datasheetType}</span>
                            {!reduced && <span className='px-1.5 text-gray-600 bg-gray-100 rounded text-sm font-medium h-fit'>{count}</span>}
                            {reduced && <span className='text-sm'>{count} x</span>}
                        </div>
                        <IConChevronDown className={`w-6 ${open ? 'rotate-180' : ''} text-gray`}/>
                    </Disclosure.Button>
                    <Disclosure.Panel className={reduced ? `bg-white border-l-2 border-l-${color} pl-4 pr-2` : 'bg-gray-25'}>
                        {!reduced &&
                            <BasketTableHeader 
                                documentIds={datasheetIds} 
                                product={product} 
                                docType={datasheetType} 
                                ref={addElement(0)}
                                onSyncedScroll={handleScroll}
                                extendableColumnWidth={extendableColumnWidth}
                                toggleExtendableColumn={toggleExtendableColumn}
                            />
                        }
                        {list}
                    </Disclosure.Panel>
                </>)}
            </Disclosure>
        )
    }
}
 
export default DatasheetDisclosure;